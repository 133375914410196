import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';

import { Voltar, Carregando, Confirmacao } from '../../components';
import { Funci } from '../../services';
import { alertar } from '../../utils';
import { modelExameMedico } from '../../models';

export default () => {
  const { id } = useParams();
  const [atualizar, setAtualizar] = useState(0);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [carregando, setCarregando] = useState(true);
  const [exame, setExame] = useState(null);

  const statusColors = {
    ATIVO: 'text-primary',
    VENCIDO: 'text-danger',
    'NÃO VALIDADO': 'text-warning',
    INATIVO: 'text-warning',
    CANCELADO: 'text-danger',
  };
  const disableBotoes = {
    ATIVO: { val: true, reval: true, cancel: false },
    VENCIDO: { val: true, reval: false, cancel: true },
    'NÃO VALIDADO': { val: false, reval: true, cancel: false },
    INATIVO: { val: true, reval: true, cancel: true },
    CANCELADO: { val: true, reval: true, cancel: true },
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const validarExame = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.validarExameMedico({ id });
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const revalidarExame = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.revalidarExameMedico({ id });
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const cancelarExame = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.cancelarExameMedico({ id });
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const consulta = await Funci.consultarExameMedico(id);
        if (consulta.erro) alertar('danger', consulta.erro);
        if (consulta.id) {
          setExame(modelExameMedico(consulta));
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, atualizar]);

  return (
    <>
      <Col as="div" className="rolagem">
        <Container fluid>
          <Carregando show={carregando} />
          <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />

          <br />
          <Row className="justify-content-between">
            <Voltar to="/exames" />
          </Row>

          <Card>
            <Card.Header as="h6">Detalhe Exame Médico</Card.Header>
            <Card.Body>
              {exame && <Card.Text>{`Tipo: ${exame.tipoPessoa}`}</Card.Text>}
              {/* TITULAR OU DEPENDENTE */}
              {exame && (exame.tipoPessoa === 'Titular' || exame.tipoPessoa === 'Dependente') && (
                <Row>
                  <Col>
                    <Card.Text>
                      {`Nome: ${exame.nome} ${exame.nomeRdzd ? '(' + exame.nomeRdzd + ')' : ''}`}
                      <br />
                      {`Título: ${exame.titulo}${exame.codDependente ? ' / ' + exame.codDependente : ''}`}
                    </Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>
                      {' '}
                      <br />
                      {`Categoria: ${exame.categoria}`}
                    </Card.Text>
                  </Col>
                </Row>
              )}
              {/* VISITANTE */}
              {exame && exame.tipoPessoa === 'Visitante' && (
                <Row>
                  <Col>
                    <Card.Text>{`CPF: ${exame.cpf}`}</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text> </Card.Text>
                  </Col>
                </Row>
              )}
              <br />
              <Card.Text>Dados do exame</Card.Text>
              {exame && (
                <>
                  <Row>
                    <Col>
                      <Card.Text>
                        {`Local de realização: ${exame.localLabel}`}
                        <br />
                        {`Valido até: ${exame.dataValidade}`}
                        <br />
                        {`Número de revalidações: ${exame.nrRevalidacoes || 0}`}
                        <br />
                        {`Responsável pelo cadastro: ${exame.nomeResponsavel || ''}`}
                      </Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>
                        {`Data de emissão: ${exame.dataEmissao}`}
                        <br />
                        {`Data de validação: ${exame.dataValidacao || ''}`}
                        <br />
                        {`Status: `} <span className={statusColors[exame.statusExameLabel]}>{`${exame.statusExameLabel}`}</span>
                        <br />
                        {`Responsável pela validação: ${exame.nomeValidador || ''}`}
                      </Card.Text>
                    </Col>
                  </Row>
                  <br />
                  <Card.Text>
                    {`Observações:`}
                    <br />
                    {`${exame.observacoes || ''}`}
                  </Card.Text>
                  <br />
                  <Row>
                    <Col className="d-flex justify-content-center">
                      <Button
                        className="mx-2"
                        variant="primary"
                        disabled={disableBotoes[exame.statusExameLabel] ? disableBotoes[exame.statusExameLabel].val : true}
                        onClick={() => confirmar('Confirma a validação do exame médico?', validarExame)}
                      >
                        Validar
                      </Button>
                      <Button
                        className="mx-2"
                        variant="success"
                        disabled={disableBotoes[exame.statusExameLabel] ? disableBotoes[exame.statusExameLabel].reval : true}
                        onClick={() => confirmar('Confirma a revalidação do exame médico?', revalidarExame)}
                      >
                        Revalidar
                      </Button>
                      <Button
                        className="mx-2"
                        variant="danger"
                        disabled={disableBotoes[exame.statusExameLabel] ? disableBotoes[exame.statusExameLabel].cancel : true}
                        onClick={() => confirmar('Tem certeza que deseja cancelar/revogar o exame médico?', cancelarExame)}
                      >
                        Cancelar
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Col>
    </>
  );
};
