import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { ListarPessoas } from '.';

export default () => {
  return (
    <Container fluid>
      <br />
      <Tabs defaultActiveKey="cadastros" id="uncontrolled-tab-example">
        {/** ************************************************************************************************************************************* */}
        {/** * CADASTRO                                                                                                                            */}
        {/** ************************************************************************************************************************************* */}
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="cadastros" title="Cadastros">
          <ListarPessoas />
        </Tab>
      </Tabs>
    </Container>
  );
};
