import React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

export default withRouter(props => {
  const { history } = props;

  return (
    <Container fluid>
      <br />
      <Card>
        <Card.Header as="h6">{`Relatórios`}</Card.Header>
        <Card.Body className="pb-4">
          <Card.Subtitle>FINANCEIROS</Card.Subtitle>
          <br />
          <Button onClick={() => history.push('/relatorios/bb-boletos-pagos-data')}>BB - Liquidação por data</Button>
        </Card.Body>
      </Card>
    </Container>
  );
});
