import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Button, Col, Row, Table, Form, Container, Tabs, Tab, Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { Carregando, Confirmacao } from '../../components';
import { Funci } from '../../services';
import { alertar } from '../../utils';
import { LinkContainer } from 'react-router-bootstrap';
import { modelRestricao } from '../../models';

export default withRouter(() => {
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [atualizar, setAtualizar] = useState(0);
  const { handleSubmit, register, errors, getValues, setValue } = useForm();
  const [restricoes, setRestricoes] = useState([]);
  const [records, setRecords] = useState('');

  const [pesquisa, setPesquisa] = useState({
    criterio: '',
    dataInicial: '',
    dataFinal: '',
  });
  const [pagina] = useState(1);
  const [qtdePagina] = useState(99999);
  const [campoOrdenacao] = useState('0');
  const [sentidoOrdenacao] = useState('0');

  const funci = new Funci();

  const statusColors = {
    ATIVA: 'text-primary',
    VENCIDA: 'text-danger',
    INATIVA: 'text-warning',
    CANCELADA: 'text-danger',
  };

  // Itens de query da requisição
  // const criterio = query.get('criterio') || '0';
  // const pagina = parseInt(query.get('pagina'), 10) || 1;
  // const qtdePagina = parseInt(query.get('qtdePagina'), 10) || 99999;
  // const campoOrdenacao = query.get('campoOrdenacao') || '0';
  // const sentidoOrdenacao = query.get('sentidoOrdenacao') || '0';

  const onSubmitForm = async values => {
    const { dataInicial, dataFinal, paramCriterio } = values;
    // Validar quaisquer campos necessários se houver
    const dIni = moment(dataInicial, 'YYYY-MM-DD');
    const dFim = moment(dataFinal, 'YYYY-MM-DD');
    if (dIni > dFim) {
      alertar('danger', 'Data final deve ser igual ou superior à data inicial.');
      return null;
    }

    // Comandar a navagação paginada
    setPesquisa({ ...pesquisa, criterio: paramCriterio, dataInicial, dataFinal });
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  // const navegar = (crit, pag, qtde, campo, sentido) => {
  //   setCarregando(true);
  //   try {
  //     let url = '/exames';
  //     url = `${url}?criterio=${crit.toUpperCase() || '0'}`;
  //     url = `${url}&pagina=${pag}`;
  //     url = `${url}&qtdePagina=${qtde}`;
  //     url = `${url}&campoOrdenacao=${campo}`;
  //     url = `${url}&sentidoOrdenacao=${sentido}`;
  //     history.push(url);
  //   } catch (e) {
  //     alertar('error', e.msg || e);
  //   } finally {
  //     setCarregando(false);
  //   }
  // };

  // const ordenar = (campo, sentido) => {
  //   navegar(criterio, pagina, qtdePagina, campo, sentido);
  // };

  // const paginar = pag => {
  //   navegar(criterio, pag, qtdePagina, campoOrdenacao, sentidoOrdenacao);
  // };

  const baixarRestricao = async r => {
    setCarregando(true);
    try {
      fecharConfirmacao();
      const consulta = await Funci.alterarRestricao({ ...r, acao: 'B' });

      if (consulta.erro) {
        alertar('danger', consulta.erro);
      } else if (consulta === true) {
        alertar('success', 'Restrição baixada com sucesso.');
        setAtualizar(atualizar + 1);
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        if (!getValues('dataInicial')) setValue('dataInicial', moment().subtract(365, 'days').format('YYYY-MM-DD'));
        if (!getValues('dataFinal')) setValue('dataFinal', moment().format('YYYY-MM-DD'));

        if (pesquisa.criterio) {
          const consulta = await Funci.buscarRestricoesPorCriterio(
            `${pesquisa.criterio || '0'}`,
            pesquisa.dataInicial,
            pesquisa.dataFinal,
            pagina,
            qtdePagina,
            campoOrdenacao || '0',
            sentidoOrdenacao || '0',
          );
          if (consulta.erro) {
            setRestricoes([]);
            alertar('danger', consulta.erro);
          } else {
            if (consulta.length === 0) {
              setRestricoes([]);
              alertar('warning', 'Nenhuma restrição encontrada.');
            }
            if (consulta.length > 0) {
              setRecords(consulta[0].records);
              setRestricoes(consulta.map(c => modelRestricao(c)));
            }
          }
        }
      } catch (e) {
        setRestricoes([]);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [pesquisa, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao, atualizar]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <br />
      <Tabs defaultActiveKey="restricoes" id="uncontrolled-tab-example">
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="restricoes" title="Restrições">
          <Row className="px-2">
            {funci.hasPerfil(['ADMIN', 'SECRETARIA']) && (
              <Col xs={3}>
                <LinkContainer to="/restricoes/nova">
                  <Button variant="success" className="mt-2">
                    Incluir Restrição
                  </Button>
                </LinkContainer>
              </Col>
            )}
            {restricoes.length > 0 ? (
              <>
                <Col sm="auto" className="pb-0 mt-2 mx-2 d-flex justify-content-center align-items-center">
                  <Row className="justify-content-center">
                    <Card.Text className="pb-0 ml-1">{`Registros encontrados: ${records}`}</Card.Text>
                  </Row>
                </Col>
              </>
            ) : null}
            <Col className="justify-content-end">
              <Form onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
                <Row className="justify-content-end">
                  <Form.Group as={Col} xs={2} controlId="paramDataInicial" className="align-middle mb-0">
                    <Form.Control
                      name="dataInicial"
                      type="date"
                      ref={register({
                        required: { value: true, message: 'Informe um data inicial para a consulta.' },
                        validate: value => moment(value).isValid() || 'Data inválida.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.dataInicial && errors.dataInicial.message}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} xs={2} controlId="paramDataFinal" className="align-middle mb-0">
                    <Form.Control
                      name="dataFinal"
                      type="date"
                      ref={register({
                        required: { value: true, message: 'Informe um data final para a consulta.' },
                        validate: value => moment(value).isValid() || 'Data inválida.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.dataFinal && errors.dataFinal.message}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} xs={4} controlId="paramCriterio" className="align-middle mb-0">
                    <Form.Control
                      name="paramCriterio"
                      type="text"
                      maxLength={200}
                      placeholder="Digite um título, nome ou cpf"
                      ref={register({
                        required: 'Informe um título, nome ou CPF.',
                        minLength: { value: 3, message: 'A pesquisa deve conter no mínimo 3 caracteres.' },
                        maxLength: { value: 200, message: 'A pesquisa deve conter no máximo 200 caracteres.' },
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.paramCriterio && errors.paramCriterio.message}
                    </Col>
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit">Buscar</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="text-center align-middle">#</th>
                <th className="align-middle">Referência</th>
                <th className="align-middle text-center">Tipo</th>
                <th className="align-middle text-center">Identificador</th>
                <th className="text-center align-middle">
                  Tipo
                  <br />
                  Identificador
                </th>
                <th className="align-middle">Nome</th>
                <th className="align-middle text-center">Data Fim</th>
                <th className="align-middle text-center">Status</th>
                <th className="text-center align-middle">
                  Responsável
                  <br />
                  pelo registro
                </th>
                <th className="text-center align-middle">
                  Responsável
                  <br />
                  pela baixa
                </th>
                <th className="align-middle text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              {restricoes.length > 0
                ? restricoes.map((r, index) => (
                    <tr className="" key={`${index}-${r.id}`}>
                      <td className="align-middle text-center">{`${index + 1}`}</td>
                      <td className="align-middle">{`${r.dataRef}`}</td>
                      <td className="align-middle text-center">{r.tipoLabel}</td>
                      <td className="align-middle text-center">{r.identificador}</td>
                      <td className="align-middle text-center">{r.tipoIdentificadorLabel}</td>
                      <td className="align-middle">{r.nome}</td>
                      <td className="align-middle text-center">{r.dataFim}</td>
                      <td className={`${statusColors[r.statusLabel]} align-middle text-center`}>{r.statusLabel}</td>
                      <td className="align-middle text-center">{r.nomeResponsavel}</td>
                      <td className="align-middle text-center">{r.nomeBaixa}</td>
                      <td className="align-middle text-center">
                        {r.status === 'A' && (
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => confirmar('Tem certeza que deseja baixar a restrição?', () => baixarRestricao(r))}
                          >
                            Baixar
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Container>
  );
});
