import moment from 'moment';
import { campoObrigatorio, campoOpcional } from '../utils';

/**
 * @method modelBoleto
 * @description Modelo padrão de objeto Boleto
 * @param {object} boleto - Vide código da model. Lista omitida com o objetivo de diminuir o tamanho do arquivo.
 * @returns {object} Objeto de Convite formatado
 */
export default ({
  id,
  idLote,
  codigoLote,
  nrBoletoBanco,
  nrDocumento,
  nossoNumero,
  linhaDigitavel,
  codigoBarras,
  idConvenio,
  cdIf,
  ifNome,
  inSocio,
  idPessoa,
  titulo,
  tpInscricao,
  cpfCnpj,
  nomeRazao,
  enderecoLogradouro,
  enderecoComplemento,
  enderecoCidade,
  enderecoUf,
  enderecoCep,
  emailPagador,
  dtEmissao,
  dtRegistroBoleto,
  dtVcto,
  dtRecebimento,
  dtCreditoLiquidacao,
  vlOriginal,
  vlAbatimento,
  vlDesconto,
  vlJuros,
  vlMulta,
  vlPago,
  vlCreditoCedente,
  vlPagamentoParcial,
  vlImposto,
  txObs,
  txInstrucoes,
  inAceite,
  inPix,
  diasProtesto,
  diasNegativacao,
  orgaoNegativador,
  inAceiteTituloVencido,
  nrDiasLimRecebimento,
  tpTitulo,
  dsTipoTitulo,
  inPermRecebParcial,
  descTp,
  descDtExp,
  descPorcent,
  descValor,
  desc2DtExp,
  desc2Porcent,
  desc2Valor,
  desc3DtExp,
  desc3Porcent,
  desc3Valor,
  jurosMoraTp,
  jurosMoraPorcent,
  jurosMoraValor,
  multaTp,
  multaData,
  multaPorcent,
  multaValor,
  multaDt,
  cdTipoBaixa,
  cdNaturezaRecebimento,
  cdTipoLiquidacao,
  cdCanalPagto,
  cdOcorrenciaCartorio,
  motivoBaixa,
  uidRegistro,
  uidBaixa,
  nomeResponsavelRegistro,
  nomeResponsavelBaixa,
  statusRegistro,
  statusBoleto,
  timestamp,
}) => {
  const normalize = d => moment(d).format('DD/MM/YYYY');
  const getStatus = () => {
    if (statusRegistro === 'P') return 'PENDENTE';
    if (statusRegistro === 'PR') return 'PROCESSANDO';
    if (statusRegistro === 'C') return 'CANCELADO';
    if (statusRegistro === 'R') {
      if (statusBoleto >= 1 && statusBoleto <= 5) return 'REGISTRADO';
      if (statusBoleto === 6) return 'PAGO';
      if (statusBoleto === 7) return 'BAIXADO';
      if (statusBoleto >= 8 && statusBoleto <= 9) return 'REGISTRADO';
      if (statusBoleto === 10) return 'BAIXADO';
      if (statusBoleto >= 11 && statusBoleto <= 12) return 'PAGO';
      if (statusBoleto === 13) return 'PROTESTO';
      if (statusBoleto >= 14 && statusBoleto <= 17) return 'EM LIQUIDAÇÃO';
      if (statusBoleto >= 18 && statusBoleto <= 19) return 'PAGO PARCIAL';
      if (statusBoleto === 21) return 'EM LIQUIDAÇÃO';
      if (statusBoleto >= 22) return 'REGISTRADO';
      if (!statusBoleto) return 'REGISTRADO';
      return 'DESCONHECIDO';
    }
  };

  return {
    id: campoOpcional(id, 'id', ['number']),
    idLote: campoOpcional(idLote, 'idLote', ['number']),
    codigoLote,
    nrBoletoBanco: campoOpcional(nrBoletoBanco, 'nrBoletoBanco', ['string', 'number']),
    nrDocumento: campoOpcional(nrDocumento, 'nrDocumento', ['string', 'number']),
    nossoNumero: campoOpcional(nossoNumero, 'nossoNumero', ['string', 'number'], 20, 20),
    linhaDigitavel: campoOpcional(linhaDigitavel, 'linhaDigitavel', ['string'], 40, 70),
    codigoBarras: campoOpcional(codigoBarras, 'codigoBarras', ['string'], 40, 70),
    idConvenio: campoObrigatorio(idConvenio, 'idConvenio', ['number']),
    cdIf,
    ifNome,
    inSocio: campoObrigatorio(inSocio, 'inSocio', ['string'], 1, 1, ['S', 'N']),
    idPessoa: campoOpcional(idPessoa, 'idPessoa', ['number']),
    titulo: campoOpcional(titulo, 'titulo', ['string']),
    tpInscricao: campoObrigatorio(tpInscricao, 'tpInscricao', ['number'], 1, 1, [1, 2]),
    cpfCnpj: campoObrigatorio(cpfCnpj, 'cpfCnpj', ['string'], 11, 18),
    nomeRazao: campoObrigatorio(nomeRazao, 'nomeRazao', ['string'], 3, 150),
    enderecoLogradouro: campoObrigatorio(enderecoLogradouro, 'enderecoLogradouro', ['string'], 3, 50),
    enderecoComplemento: campoOpcional(enderecoComplemento, 'enderecoComplemento', ['string'], 0, 40),
    enderecoCidade: campoObrigatorio(enderecoCidade, 'enderecoCidade', ['string'], 2, 40),
    enderecoUf: campoObrigatorio(enderecoUf, 'enderecoUf', ['string'], 2, 2),
    enderecoCep: campoObrigatorio(enderecoCep, 'enderecoCep', ['string'], 8, 10),
    emailPagador: campoOpcional(emailPagador, 'emailPagador', ['string'], 0, 150),
    dtEmissao: normalize(campoObrigatorio(dtEmissao, 'dtEmissao', ['string'])),
    dtRegistroBoleto: dtRegistroBoleto ? normalize(dtRegistroBoleto) : null,
    dtVcto: normalize(campoObrigatorio(dtVcto, 'dtVcto', ['string'])),
    dtRecebimento: dtRecebimento ? normalize(dtRecebimento) : null,
    dtCreditoLiquidacao: dtCreditoLiquidacao ? normalize(dtCreditoLiquidacao) : null,
    vlOriginal: campoObrigatorio(vlOriginal, 'vlOriginal', ['number']),
    vlAbatimento: campoOpcional(vlAbatimento, 'vlAbatimento', ['number']),
    vlDesconto: campoOpcional(vlDesconto, 'vlDesconto', ['number']),
    vlJuros: campoOpcional(vlJuros, 'vlJuros', ['number']),
    vlMulta: campoOpcional(vlMulta, 'vlMulta', ['number']),
    vlPago: campoOpcional(vlPago, 'vlPago', ['number']),
    vlCreditoCedente: campoOpcional(vlCreditoCedente, 'vlCreditoCedente', ['number']),
    vlPagamentoParcial: campoOpcional(vlPagamentoParcial, 'vlPagamentoParcial', ['number']),
    vlImposto: campoOpcional(vlImposto, 'vlImposto', ['number']),
    txObs: campoOpcional(txObs, 'txObs', ['string'], 0, 2000),
    txInstrucoes: campoOpcional(txInstrucoes, 'txInstrucoes', ['string'], 0, 1000),
    inPix: campoOpcional(inPix, 'inPix', ['string'], 1, 1, ['S', 'N']),
    inAceite: campoObrigatorio(inAceite, 'inAceite', ['string'], 1, 1, ['A', 'N']),
    diasProtesto: campoOpcional(diasProtesto, 'diasProtesto', ['number']),
    diasNegativacao: campoOpcional(diasNegativacao, 'diasNegativacao', ['number']),
    orgaoNegativador: campoOpcional(orgaoNegativador, 'orgaoNegativador', ['string'], 0, 10),
    inAceiteTituloVencido: campoObrigatorio(inAceiteTituloVencido, 'inAceiteTituloVencido', ['string'], 1, 1, ['S', 'N']),
    nrDiasLimRecebimento: campoObrigatorio(nrDiasLimRecebimento, 'nrDiasLimRecebimento', ['number']),
    tpTitulo: campoObrigatorio(tpTitulo, 'tpTitulo', ['number']),
    dsTipoTitulo: campoObrigatorio(dsTipoTitulo, 'dsTipoTitulo', ['string'], 0, 20),
    inPermRecebParcial: campoObrigatorio(inPermRecebParcial, 'inPermRecebParcial', ['string'], 1, 1, ['S', 'N']),
    descTp: campoOpcional(descTp, 'descTp', ['number'], 0, 3, [0, 1, 2, 3]),
    descDtExp: descDtExp ? normalize(descDtExp) : null,
    descPorcent: campoOpcional(descPorcent, 'descPorcent', ['number']),
    descValor: campoOpcional(descValor, 'descValor', ['number']),
    desc2DtExp: desc2DtExp ? normalize(desc2DtExp) : null,
    desc2Porcent: campoOpcional(desc2Porcent, 'desc2Porcent', ['number']),
    desc2Valor: campoOpcional(desc2Valor, 'desc2Valor', ['number']),
    desc3DtExp: desc3DtExp ? normalize(desc3DtExp) : null,
    desc3Porcent: campoOpcional(desc3Porcent, 'desc3Porcent', ['number']),
    desc3Valor: campoOpcional(desc3Valor, 'desc3Valor', ['number']),
    jurosMoraTp: campoOpcional(jurosMoraTp, 'jurosMoraTp', ['number'], 0, 3, [0, 1, 2, 3]),
    jurosMoraPorcent: campoOpcional(jurosMoraPorcent, 'jurosMoraPorcent', ['number']),
    jurosMoraValor: campoOpcional(jurosMoraValor, 'jurosMoraValor', ['number']),
    multaTp: campoOpcional(multaTp, 'multaTp', ['number'], 0, 3, [0, 1, 2, 3]),
    multaData: multaData ? normalize(multaData) : null,
    multaPorcent: campoOpcional(multaPorcent, 'multaPorcent', ['number']),
    multaValor: campoOpcional(multaValor, 'multaValor', ['number']),
    multaDt: multaDt ? normalize(multaDt) : null,
    cdTipoBaixa: campoOpcional(cdTipoBaixa, 'cdTipoBaixa', ['number']),
    cdNaturezaRecebimento: campoOpcional(cdNaturezaRecebimento, 'cdNaturezaRecebimento', ['number']),
    cdTipoLiquidacao: campoOpcional(cdTipoLiquidacao, 'cdTipoLiquidacao', ['number']),
    cdCanalPagto: campoOpcional(cdCanalPagto, 'cdCanalPagto', ['number']),
    cdOcorrenciaCartorio: campoOpcional(cdOcorrenciaCartorio, 'cdOcorrenciaCartorio', ['number']),
    motivoBaixa: campoOpcional(motivoBaixa, 'motivoBaixa', ['string']),
    statusRegistro: campoOpcional(statusRegistro, 'statusRegistro', ['string'], 1, 3, ['P', 'PR', 'R', 'C']),
    statusBoleto: campoOpcional(statusBoleto, 'statusBoleto', ['number']),
    uidRegistro: campoOpcional(uidRegistro, 'uidRegistro', ['string'], 1, 200),
    uidBaixa: campoOpcional(uidBaixa, 'uidBaixa', ['string'], 1, 200),
    nomeResponsavelRegistro: campoOpcional(nomeResponsavelRegistro, 'nomeResponsavelRegistro', ['string'], 1, 200),
    nomeResponsavelBaixa: campoOpcional(nomeResponsavelBaixa, 'nomeResponsavelBaixa', ['string'], 1, 200),
    statusLabel: getStatus(),
    timestamp: timestamp ? moment(timestamp) : null,
  };
};
