import moment from 'moment';
import modelLocalAcesso from './modelLocalAcesso';

/**
 * @method modelAcessoFisico
 * @description Modelo padrão de objetoAcesso
 * @param {string} tipo - tipo de Acesso
 * @param {string} tipoLabel - label tipo de Acesso. dervivado de cálculo.
 * @param {object} local - local do Acesso
 * @param {string} identificador - identificador da pessoa
 * @param {string} nome - nome da pessoa
 * @param {string} uidResponsavel - uidResponsavel pelo registro
 * @param {string} nomeResponsavel - nomeResponsavel pelo registro
 * @param {string} timestamp - data de criação do Acesso.
 * @param {string} destino - local de destino permitido.
 * @param {string} inBloqueio - indicador de bloqueio.
 * @param {string} codMotivoBloqueio - código do motívo de bloqueio.
 * @param {string} descMotivoBloqueio - descrição do motivo de bloqueio.
 * @returns {object} Objeto de Acesso formatado
 */
export default ({
  tipo,
  codigoLocal,
  nomeLocal,
  identificador,
  nome,
  uidResponsavel,
  nomeResponsavel,
  apelidoResponsavel,
  timestamp,
  destino,
  inBloqueio,
  codMotivoBloqueio,
  descMotivoBloqueio,
}) => {
  const tipoLabels = {
    A: 'SÓCIO',
    C: 'CONVITE',
    E: 'EVENTO',
    V: 'VISITANTE',
  };
  const tipoLabel = tipoLabels[tipo];
  return {
    tipo,
    tipoLabel,
    local: modelLocalAcesso({ codigo: codigoLocal, nome: nomeLocal }),
    identificador,
    nome: `${nome}`,
    uidResponsavel,
    nomeResponsavel: apelidoResponsavel || nomeResponsavel,
    timestamp: timestamp ? `${moment(timestamp).format('DD/MM/YYYY HH:mm:ss')}` : null,
    destino,
    inBloqueio: inBloqueio === 'S' || false,
    codMotivoBloqueio,
    descMotivoBloqueio,
  };
};
