import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Col, Row, Table, Card, Container, Image } from 'react-bootstrap';
import { Alerta, Carregando } from '../../components';
import { withRouter } from 'react-router-dom';
import { Funci } from '../../services';
import modelReserva from '../../models/modelReserva';
import { withOrdenar, validarTipo } from '../../utils';
import { modelChurrasqueira, modelTurno } from '../../models';

const logo = require('../../assets/logo/mbtc-logo.png');

export default withRouter(() => {
  const data = moment().format('DD/MM/YYYY');
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [listaDoDia, setListaDoDia] = useState([]);
  const [reservas, setReservas] = useState([]);

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      fecharAlerta();
      setReservas([]);
      setCarregando(true);
      try {
        setTimeout(async () => {
          if (!data || !validarTipo(data, 'string') || !moment(data, 'DD/MM/YYYY', true).isValid()) return alertar('danger', 'Data inválida.');

          // Obter infomações dos turnos vigentes
          const consultaTurnos = await Funci.infoTurnos();
          if (consultaTurnos.erro) return alertar('danger', consultaTurnos.erro);
          const turnos = withOrdenar(consultaTurnos.map(t => modelTurno(t))).ordenar('nome', 'A');

          // Obter lista de churrasqueiras totais
          const consultaChs = await Funci.listarChurrasqueiras();
          if (consultaChs.erro) return alertar('danger', consultaChs.erro);
          const churrasqueiras = withOrdenar(consultaChs.map(c => modelChurrasqueira(c))).ordenar('nome', 'A');

          // Preparação do formato de data para a pesquisa
          const dataForm = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
          // Obter reservas do dia
          const consultaReservas = await Funci.listarReservas(`${dataForm}`, `${dataForm}`, '0'); // Garantia que os termos serão passados por string
          if (consultaReservas.erro) return alertar('danger', consultaReservas.erro);
          const reservasDoDia = withOrdenar(consultaReservas.filter(r => r.status !== 'C').map(r => modelReserva(r))).ordenar('idObjeto', 'A');

          // Montando a lista do dia com os turnos e churrasqueiras existentes
          const listaPrevia = turnos.map(t => ({
            ...t,
            churrasqueiras: churrasqueiras.map(c => ({
              ...c,
              reserva: reservasDoDia.filter(r => r.idObjeto.toString() === c.idChurrasqueira.toString() && r.turno === t.id)[0],
            })),
          }));

          setListaDoDia(listaPrevia);
          setReservas(reservasDoDia);
        }, 1000);
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [data]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      {alerta.show ? (
        <Row className="justify-content-between">
          <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} />
        </Row>
      ) : null}

      {/* RELATÓRIO DE RESERVAS DO DIA POR TURNO */}
      {listaDoDia.map(l => (
        <Card border className="print" key={l.id.toString()}>
          <Card.Header>
            <Row className="justify-content-between">
              <Col className="d-flex flex-row">
                <Image fluid src={logo} className="mx-2" style={{ height: 35 }} />
                <Card.Title className="fs-6">MINAS BRASÍLIA TÊNIS CLUBE</Card.Title>
              </Col>
              <Col xs="auto">
                <Card.Title className="fs-6">{`${data}`}</Card.Title>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Card.Title className="fs-6">RELATÓRIO DE RESERVAS DE CHURRASQUEIRAS</Card.Title>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Card.Title className="text-center fs-6">{`TURNO: ${l.nome}`}</Card.Title>
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>
                  <th>Churrasqueira</th>
                  <th className="text-center">Título</th>
                  <th>Sócio</th>
                  <th className="text-center">Período</th>
                  <th className="text-center">Status</th>
                </tr>
              </thead>
              <tbody className="fs-6">
                {l.churrasqueiras.map(c => (
                  <tr key={`${c.idChurrasqueira}`} className="py-0 my-0">
                    <td className="py-0 my-0">{c.nome}</td>
                    <td className="text-center py-0 my-0">{c.reserva ? c.reserva.titulo : ''}</td>
                    <td className="py-0 my-0 ">{c.reserva ? c.reserva.nome : ''}</td>
                    <td className="text-center py-0 my-0">{`${l.inicioLabel} às ${l.fimLabel}`}</td>
                    <td className={`${c.reserva ? 'text-primary' : 'text-success'} text-center py-0 my-0`}>
                      {c.reserva ? c.reserva.statusLabel : 'LIVRE'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ))}

      {/* AVISO INDIVIUAL DE CHURRASQUEIRA */}
      {reservas.map(r => (
        <Card border="dark" key={r.idReserva.toString()} className="print mt-4 h-100 flex-grow-1 page-full text-center" style={{ borderWidth: 5 }}>
          <Card.Body>
            <br />
            <br />
            <br />
            <br />
            <Card.Text as="h1" style={{ fontSize: 90 }}>
              RESERVA
            </Card.Text>
            <br />
            <br />
            <br />
            <br />
            <Card.Text as="h1" style={{ fontSize: 60 }}>
              {`${r.objeto.nome}`}
              <br />
              {`(${r.objeto.capacidade} pessoas)`}
            </Card.Text>
            <br />
            <br />
            <br />
            <br />
            <Card.Text as="h1" style={{ fontSize: 60 }}>
              {r.dataInicial}
            </Card.Text>
            <br />
            <br />
            <br />
            <br />
            <Card.Text as="h1" style={{ fontSize: 60 }}>
              Código: {r.titulo}
            </Card.Text>
            <br />
            <br />
            <br />
            <br />{' '}
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
});
