import React, { Fragment, useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Container, Col, Tabs, Tab, Form, Table, Badge, Row } from 'react-bootstrap';
import { Funci } from '../../services';
import { Carregando, Alerta } from '../../components';
import { withOrdenar } from '../../utils';
import { modelUsuarioSistema } from '../../models';

export default withRouter(() => {
  const { paramTitulo } = useParams();
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedTitulo, setValidatedTitulo] = useState(false);
  const [check, setCheck] = useState({ ativo: true, inativo: false });
  const [titulo, setTitulo] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  const [usuariosTotais, setUsuariosTotais] = useState([]);

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      filtrar();
    }
    setValidatedTitulo(false);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      fecharAlerta();
      try {
        const consulta = await Funci.listarUsuariosSistema(paramTitulo);
        if (consulta.erro) {
          setUsuarios([]);
          setUsuariosTotais([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) alertar('warning', 'Nenhum usuário encontrado.');
          if (consulta.length > 0) {
            const itens = withOrdenar(consulta.map(u => modelUsuarioSistema(u)))
              .ordenar('statusLabel', 'A')
              .ordenar('superUsuario', 'D');
            setUsuarios(itens);
            setUsuariosTotais(itens);
          }
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [paramTitulo]);

  const filtrar = () => {
    if (usuariosTotais.length > 0 && titulo) {
      setUsuarios(usuariosTotais.filter(u => u.titulo === titulo));
    } else {
      setUsuarios(usuariosTotais);
    }
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      {alerta.show ? (
        <Row className="justify-content-between">
          <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} />
        </Row>
      ) : null}

      <Tabs defaultActiveKey="usuarios" id="uncontrolled-tab-example">
        {/** ************************************************************************************************************************************* */}
        {/** * Usuários                                                                                                                            */}
        {/** ************************************************************************************************************************************* */}
        <Tab mountOnEnter unmountOnExit={false} variant="pills" eventKey="usuarios" title="Gestão de usuários do sistema">
          <Row className="px-2">
            <Col className="justify-content-end">
              <Form noValidate validated={validatedTitulo} onSubmit={handleSubmit} className="mt-2" action={`/usuarios/${titulo || 0}`} method="get">
                <Row className="justify-content-end">
                  <Form.Group as={Col} xs={4} controlId="titulo" className="align-middle mb-0">
                    <Form.Control type="text" placeholder="Título do usuário" value={titulo} onChange={event => setTitulo(event.target.value)} />
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit">Filtrar</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <Form inline="true" className="justify-content-center">
            <Form.Group controlId="status">
              <Form.Check
                inline="true"
                type="checkbox"
                id="A"
                label="Ativo"
                checked={check.ativo}
                onChange={event => setCheck({ ...check, ativo: event.target.checked })}
              />
              <Form.Check
                inline="true"
                type="checkbox"
                id="I"
                label="Inativo"
                checked={check.inativo}
                onChange={event => setCheck({ ...check, inativo: event.target.checked })}
              />
            </Form.Group>
          </Form>
          <br />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th>Título</th>
                <th>Email</th>
                <th>Nome</th>
                <th>Super Usuário</th>
                <th>Perfis</th>
                <th>Status</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map(u => (
                <tr key={`${u.uid}`}>
                  <td className="align-middle">{u.titulo}</td>
                  <td className="align-middle">{u.email}</td>
                  <td className="align-middle">{u.nome}</td>
                  <td className={`${u.superUsuario ? 'text-primary' : 'text-danger'} align-middle`}>{u.superUsuario ? 'SIM' : 'NÃO'}</td>
                  <td className="align-middle">
                    {u.perfis.map(p => (
                      <Fragment key={p.descricao}>
                        {`${p.descricao}`}
                        <br />
                      </Fragment>
                    ))}
                  </td>
                  <td className={`${{ A: 'text-primary', I: 'text-danger' }[u.status]} align-middle`}>{u.statusLabel}</td>
                  <td className="align-middle">
                    <LinkContainer to={`/detalhe-usuario/${u.uid}`}>
                      <Badge variant="secondary">Clique aqui</Badge>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Container>
  );
});
