import React, { useEffect, useState } from 'react';
import 'moment/locale/pt-br';
import { useParams, withRouter } from 'react-router-dom';
import { Container, Row, Tab, Tabs } from 'react-bootstrap';

import { Carregando, Voltar } from '../../components';
import DetalhePessoaCadastro from './DetalhePessoaCadastro';
import DetalhePessoaProdutos from './DetalhePessoaProdutos';
import { Funci } from '../../services';
import { alertar } from '../../utils';
import { modelPessoa } from '../../models';

export default withRouter(props => {
  const { id } = useParams();
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const [pessoa, setPessoa] = useState({});

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.consultarPessoa(id);
        if (consulta.erro) {
          setPessoa({});
          alertar('danger', consulta.erro);
        } else {
          setPessoa(consulta);
          // setPessoa(modelPessoa(consulta));
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, atualizar]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      {pessoa.id && (
        <Tabs defaultActiveKey="cadastro" id="uncontrolled-tab-example">
          <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="cadastro" title="Cadastro">
            <DetalhePessoaCadastro pessoa={pessoa} />
          </Tab>
          <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="produtos" title="Produtos">
            <DetalhePessoaProdutos pessoa={pessoa} />
          </Tab>
        </Tabs>
      )}
    </Container>
  );
});
