import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Badge, Table, Form, Card, Container, Tabs, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Campo, Carregando, Confirmacao, Paginacao } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarValor, parseValor, withOrdenar, zeroPad } from '../../utils';
import { useForm } from 'react-hook-form';

export default withRouter(props => {
  const { history, location } = props;
  const { criterio, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao } = {
    criterio: '',
    pagina: 1,
    qtdePagina: 25,
    campoOrdenacao: '0',
    sentidoOrdenacao: '0',
    ...location.state,
  };
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [atualizar, setAtualizar] = useState(0);
  const [produtos, setProdutos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [selecao, setSelecao] = useState({});
  const [selecaoTodos, setSelecaoTodos] = useState(true);
  const { handleSubmit, register, getValues, setValue, errors } = useForm();

  setValue('paramCriterio', criterio);

  const onSubmitForm = async values => {
    const { idProduto, idCategoria, idFormaPagto, mesReferencia, anoReferencia } = values;
    // Validar quaisquer campos necessários se houver

    try {
      setCarregando(true);
      setPessoas([]);
      const pessoas = await Funci.buscarPessoasPassiveisLancamento(
        idProduto,
        idCategoria,
        idFormaPagto,
        mesReferencia,
        anoReferencia,
        1,
        999999,
        'nome',
        'ASC',
      );

      if (pessoas.erro) alertar('danger', pessoas.erro);
      if (pessoas && pessoas.length > 0) {
        const listagem = pessoas.map(i => ({ ...i, id: `${i.idPessoa}-${i.idProduto}-${i.ordem}` }));
        setSelecao(listagem.reduce((o, key) => ({ ...o, [key.id]: true }), {}));
        setPessoas(listagem);
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const selecionarTodos = value => {
    setSelecaoTodos(value);
    setSelecao(pessoas.reduce((o, key) => ({ ...o, [key.id]: value }), {}));
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const registrar = async () => {
    setCarregando(true);
    fecharConfirmacao();

    try {
      const selecionados = [];
      pessoas.forEach(p =>
        selecao[p.id]
          ? selecionados.push({
              idPessoa: p.idPessoa,
              idProduto: p.idProduto,
              ordemProduto: p.ordem,
              dtLancamento: moment().format('YYYY-MM-DD'),
              dtVencimento: moment(`${p.anoReferencia}-${zeroPad(p.mesReferencia, 2)}-05`, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              anoReferencia: p.anoReferencia,
              mesReferencia: p.mesReferencia,
              vlUnitario: parseFloat(p.vlProduto),
              qtde: 1,
              vlTotal: parseFloat(p.vlProduto),
              vlDesconto: parseFloat(p.desconto),
              vlTotalFinal: parseFloat(p.vlProduto) - parseFloat(p.desconto),
              dtUltCalculo: moment().format('YYYY-MM-DD'),
              descricao: `${p.nomeProduto}${p.textoComplemento ? ' - ' + p.textoComplemento : ''} - Ref.: ${zeroPad(p.mesReferencia, 2)}/${
                p.anoReferencia
              }`,
              email: p.email,
            })
          : null,
      );
      if (selecionados.length > 0) {
        const inclusao = await Funci.incluirLancamentos(selecionados);
        if (inclusao === true) {
          alertar('success', 'Lançamentos incluídos com sucesso.');
          setPessoas([]);
        }
        if (inclusao.erro) alertar('danger', inclusao.erro);
      } else {
        alertar('warning', 'Nenhum sócio selecionado para lançamento.');
      }
    } catch (error) {
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const prod = await Funci.buscarProdutosPorCriterio('', 1, 999999, 'nome', 'ASC');
        const categorias = await Funci.listarCategoriasAssociacao();
        if (prod.erro) alertar('danger', prod.erro);
        if (categorias.erro) alertar('danger', categorias.erro);
        if (prod && prod.length > 0) {
          setProdutos(prod.filter(p => p.status === 'A'));
        }
        if (categorias && categorias.length > 0) {
          setCategorias(categorias.sort((a, b) => a.descricao > b.descricao));
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, []);

  const statusColors = {
    ATIVO: 'text-primary',
    INATIVO: 'text-secondary',
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <br />
      <Tabs defaultActiveKey="lancamentos-lote" id="uncontrolled-tab-example">
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="lancamentos-lote" title="Lançamentos em lote">
          <br />
          <Card>
            <Card.Header as="h6">{'Preparar lançamentos'}</Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
                <Row>
                  <Col xs="6">
                    <Form.Group controlId="idProduto" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Produto <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select aria-label="Produto" name="idProduto" ref={register({ required: 'É necessário informar um produto.' })}>
                        <>
                          <option value="">{''}</option>
                          {produtos.map(u => (
                            <option key={`${u.id}`} value={`${u.id}`}>{`${u.nome}`}</option>
                          ))}
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.idProduto && errors.idProduto.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-between">
                  <Col xs="3">
                    <Form.Group controlId="idCategoria" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Categoria <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select aria-label="Categoria" name="idCategoria" ref={register({ required: 'É necessário informar um categoria.' })}>
                        <>
                          <option value="">{''}</option>
                          {categorias.map(c => (
                            <option key={`${c.id}`} value={c.id}>
                              {c.descricao || ''}
                            </option>
                          ))}
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.idCategoria && errors.idCategoria.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="3">
                    <Form.Group controlId="idFormaPagto" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Forma de pagamento <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select
                        aria-label="Forma de pagamento"
                        name="idFormaPagto"
                        ref={register({ required: 'É necessário informar uma forma de pagamento.' })}
                      >
                        <>
                          <option value="">{''}</option>
                          <option value={11}>{'BOLETO BANCÁRIO'}</option>
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.idFormaPagto && errors.idFormaPagto.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="3">
                    <Row>
                      <Col>
                        <Form.Group controlId="mesReferencia" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">
                            Mês de referência <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                          </Form.Label>
                          <Form.Select
                            aria-label="Mês de referência"
                            name="mesReferencia"
                            ref={register({ required: 'É necessário informar um mês de referência.' })}
                          >
                            <>
                              <option value="">{''}</option>
                              <option value={1}>{'JAN'}</option>
                              <option value={2}>{'FEV'}</option>
                              <option value={3}>{'MAR'}</option>
                              <option value={4}>{'ABR'}</option>
                              <option value={5}>{'MAI'}</option>
                              <option value={6}>{'JUN'}</option>
                              <option value={7}>{'JUL'}</option>
                              <option value={8}>{'AGO'}</option>
                              <option value={9}>{'SET'}</option>
                              <option value={10}>{'OUT'}</option>
                              <option value={11}>{'NOV'}</option>
                              <option value={12}>{'DEZ'}</option>
                            </>
                          </Form.Select>
                          <Col as={Card.Text} className="text-danger">
                            {errors.mesReferencia && errors.mesReferencia.message}
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="anoReferencia" className="d-flex flex-column align-items-start">
                          <Form.Label className="mt-2 mb-0">
                            Ano de referência <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                          </Form.Label>
                          <Form.Select
                            aria-label="Ano de referência"
                            name="anoReferencia"
                            ref={register({ required: 'É necessário informar um ano de referência.' })}
                          >
                            <>
                              <option value="">{''}</option>
                              {[-1, 0, 1, 2].map(i => (
                                <option key={`${i}`} value={moment().add(i, 'years').format('yyyy')}>
                                  {moment().add(i, 'years').format('yyyy')}
                                </option>
                              ))}
                            </>
                          </Form.Select>
                          <Col as={Card.Text} className="text-danger">
                            {errors.anoReferencia && errors.anoReferencia.message}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <br />
                <Row className="d-flex justify-content-between">
                  <Col className="d-flex justify-content-end">
                    <Button type="submit">Buscar</Button>
                  </Col>
                </Row>
                <hr />
                <Row className="d-flex justify-content-between">
                  <Col>
                    <Campo label="Qtde. de sócios:" valor={pessoas && pessoas.length > 0 ? pessoas.length : '0'} />
                  </Col>
                  <Col>
                    <Campo
                      label="Valor total:"
                      valor={
                        pessoas && pessoas.length > 0
                          ? `R$ ${formatarValor(
                              pessoas.reduce((acc, i) => (acc += i.vlProduto), 0) - pessoas.reduce((acc, i) => (acc += i.desconto), 0),
                            )}`
                          : 'R$ 0,00'
                      }
                    />
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <Button
                      disabled={!pessoas || pessoas.length <= 0}
                      variant="success"
                      onClick={() => confirmar(`Confirma o registro de lançamentos para os sócios selecionados?`, registrar)}
                    >
                      Registrar
                    </Button>
                  </Col>
                </Row>
              </Form>
              <br />
              <hr />
              {pessoas && pessoas.length > 0 && (
                <>
                  <Form className="justify-content-center">
                    <Form.Group controlId="selecao">
                      <Table striped bordered hover responsive size="sm">
                        <thead>
                          <tr>
                            <th className="text-center">
                              <Form.Check
                                type="checkbox"
                                id="selecionarTodos"
                                defaultChecked={true}
                                checked={selecaoTodos}
                                onChange={event => selecionarTodos(event.target.checked)}
                              />
                            </th>
                            <th>Nome</th>
                            <th className="text-center">Título</th>
                            <th className="text-center">Categoria</th>
                            <th className="text-center">Valor</th>
                            <th className="text-center">Desconto</th>
                            <th className="text-center">Valor final</th>
                            <th className="text-center">Vencimento</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pessoas.map(p => (
                            <tr key={`${p.id}`}>
                              <td className="text-center">
                                <Form.Check
                                  type="checkbox"
                                  id="selecao"
                                  checked={selecao[p.id]}
                                  onChange={event => {
                                    setSelecao({ ...selecao, [p.id]: event.target.checked });
                                    if (!event.target.checked) setSelecaoTodos(false);
                                    if (event.target.checked)
                                      setSelecaoTodos(pessoas.reduce((acc, i) => (acc = acc && (selecao[i.id] || i.id === p.id)), true));
                                  }}
                                />
                              </td>
                              <td>{p.nome || ''}</td>
                              <td className="text-center">{p.titulo}</td>
                              <td className="text-center">{p.categoria}</td>
                              <td className="text-center">{p.vlProduto ? `R$ ${formatarValor(p.vlProduto)}` : ''}</td>
                              <td className="text-center">{p.desconto ? `R$ ${formatarValor(p.desconto)}` : ''}</td>
                              <td className="text-center">{p.vlProduto ? `R$ ${formatarValor(p.vlProduto - p.desconto)}` : ''}</td>
                              <td className="text-center">
                                {moment(`${getValues('anoReferencia')}-${zeroPad(getValues('mesReferencia'), 2)}-05`, 'YYYY-MM-DD').format(
                                  'DD/MM/YYYY',
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Form>
                </>
              )}
              {!pessoas ||
                (pessoas.length <= 0 && (
                  <Table striped bordered hover responsive size="sm">
                    <thead>
                      <tr>
                        <th className="text-center"></th>
                        <th>Nome</th>
                        <th className="text-center">Título</th>
                        <th className="text-center">Categoria</th>
                        <th className="text-center">Valor</th>
                        <th className="text-center">Desconto</th>
                        <th className="text-center">Valor final</th>
                        <th className="text-center">Vencimento</th>
                      </tr>
                    </thead>
                    <tbody></tbody>
                  </Table>
                ))}
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
    </Container>
  );
});
