import { useState } from 'react';
import { Link } from 'react-router-dom';

import './SidebarMenu.scss';

import { MENUS } from '../constants';
import { Funci } from '../services';

const SidebarMenu = () => {
  const [minimized, setMinimized] = useState(true);
  const [subMenuSelecionado, setSubMenuSelecionado] = useState('');
  const funci = new Funci();

  return (
    <nav
      id="sidebar"
      className={minimized ? 'sidebar' : 'sidebar is-open'}
      onMouseEnter={() => setMinimized(false)}
      onMouseLeave={() => setMinimized(true)}
    >
      {minimized ? (
        <div className="sidebar-sortable">
          <ul className="menu">
            {MENUS.map(
              (i, index) =>
                funci.hasPerfil(i.perfis) && (
                  <li
                    key={`${index}-${i.titulo}`}
                    className={index === 0 ? 'sidebar-toggle-icon' : 'sublink'}
                    onMouseEnter={() => setSubMenuSelecionado(index)}
                  >
                    <span className="icon-wrapper">
                      <i style={{ width: 15, textAlign: 'center' }} className={`fa fa-inverse ${i.icone}`} />
                    </span>
                  </li>
                ),
            )}
          </ul>
        </div>
      ) : (
        <div className="sidebar-sortable">
          <ul className="menu">
            {MENUS.map(
              (i, index) =>
                funci.hasPerfil(i.perfis) && (
                  <li
                    key={`${index}-${i.titulo}`}
                    className={index === 0 ? 'sidebar-toggle-icon' : 'sublink'}
                    onMouseEnter={() => setSubMenuSelecionado(index)}
                  >
                    <Link to={i.destino} className={i.destino !== '' ? 'menu-link clicable' : 'menu-link'}>
                      <span className="icon-wrapper">
                        <i style={{ width: 15, textAlign: 'center' }} className={`fa fa-inverse ${i.icone}`} />
                      </span>
                      <span className="icon-label-wrapper">{i.titulo}</span>
                      {i.subitens ? (
                        <span className="icon-arrow">
                          <i style={{ width: 15, textAlign: 'center' }} className="fa fa-inverse fa-chevron-right" />
                        </span>
                      ) : null}
                    </Link>
                    {i.subitens && !minimized && subMenuSelecionado === index ? (
                      <section className="sidebar-sortable-sub">
                        <h3>{i.titulo}</h3>
                        <ul>
                          {i.subitens.map(
                            (sub, si) =>
                              funci.hasPerfil(sub.perfis) && (
                                <li key={si * 2}>
                                  <Link to={sub.destino}>{sub.titulo}</Link>
                                </li>
                              ),
                          )}
                        </ul>
                      </section>
                    ) : null}
                  </li>
                ),
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default SidebarMenu;
