import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Card } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Carregando } from '.';
import { Funci } from '../services';
import { alertar, formatarCEP, formatarCPF, validarCPF } from '../utils';

const SelecaoSocioOuDemais = props => {
  const { show = false, titulo = '', onConfirm = () => {}, onCancel = () => {} } = props;
  const { handleSubmit, register, errors, control } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [tipoSacado, setTipoSacado] = useState();
  const [socio, setSocio] = useState({});

  const onSubmitSocio = async dados => {
    try {
      setSocio({});
      setCarregando(true);
      const { titulo } = dados;
      const consultaSocio = await Funci.consultarSocioPorTitulo(titulo);

      if (!consultaSocio) {
        alertar('warning', 'Nenhum sócio encontrado.');
      } else if (consultaSocio.erro) {
        alertar('danger', consultaSocio.erro);
      } else {
        setSocio(consultaSocio);
      }
    } catch (error) {
      alertar('danger', error);
    } finally {
      setCarregando(false);
    }
  };

  const selecionarSocio = () => {
    const { idPessoa, cpfCnpj, nome, logradouro = '', numero = '', complemento = '', bairro = '', cidade = '', uf = '', cep = '' } = socio;
    const payload = {
      tipoSacado: 'socio',
      idPessoa,
      nome: nome,
      cpf: formatarCPF(cpfCnpj, true),
      enderecoLogradouro: `${logradouro || ''}${logradouro && numero ? ', ' + numero : ''}`,
      enderecoComplemento: `${complemento || ''}${complemento && bairro ? ' - ' : ''}${bairro || ''}`,
      enderecoUF: `${uf || ''}`,
      enderecoCidade: `${cidade || ''}`,
      enderecoCep: `${formatarCEP(cep)}`,
    };
    console.log('cpfCnpj', cpfCnpj);
    console.log('payload', payload);
    onConfirm(payload);
  };

  const onSubmitCpf = dados => {
    const { cpf, nome } = dados;
    const payload = {
      tipoSacado: 'cpf',
      nome,
      cpf,
    };
    onConfirm(payload);
  };

  if (show) {
    return (
      <Modal centered animation={false} backdrop="static" show={show} keyboard={false} size="lg" className="bg-invisible">
        <Carregando show={carregando} />

        <Modal.Header closeButton onHide={onCancel}>
          <Modal.Title>{`${titulo}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="d-flex justify-content-center">
            <Form.Check
              inline="true"
              type="radio"
              id="socio"
              name="tipoSacado"
              label="Sócio"
              checked={tipoSacado === 'socio'}
              onChange={() => setTipoSacado('socio')}
            />
            <Form.Check
              inline="true"
              type="radio"
              id="cpf"
              name="tipoSacado"
              label="CPF"
              checked={tipoSacado === 'cpf'}
              onChange={() => setTipoSacado('cpf')}
            />
          </Form>

          {tipoSacado === 'socio' && (
            <Form onSubmit={handleSubmit(onSubmitSocio)} className="mt-2">
              <Row className="justify-content-start">
                <Form.Group as={Col} xs={4} controlId="titulo" className="align-middle mb-0">
                  <Form.Control
                    name="titulo"
                    type="text"
                    maxLength={10}
                    placeholder="Título do associado"
                    ref={register({
                      required: 'Informe o título do sócio para a pesquisa.',
                      minLength: { value: 3, message: 'A pesquisa deve conter no mínimo 3 caracteres.' },
                      maxLength: { value: 10, message: 'A pesquisa deve conter no máximo 10 caracteres.' },
                    })}
                  />
                  <Col as={Card.Text} className="text-danger">
                    {errors.titulo && errors.titulo.message}
                  </Col>
                </Form.Group>
                <Col xs="auto">
                  <Button type="submit">Buscar</Button>
                </Col>
              </Row>
              {socio.nome && (
                <>
                  <hr />
                  <Row>
                    <Col>
                      <Card.Text>{socio.nome}</Card.Text>
                    </Col>
                    <Col xs="auto">
                      <Button onClick={selecionarSocio}>Selecionar</Button>
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          )}

          {tipoSacado === 'cpf' && (
            <Form onSubmit={handleSubmit(onSubmitCpf)} className="mt-2">
              <Row className="justify-content-center">
                <Form.Group as={Col} xs={4} controlId="cpf" className="align-middle mb-0">
                  <Controller
                    render={props => (
                      <Form.Control
                        type="text"
                        placeholder="CPF"
                        value={props.value}
                        onChange={e => props.onChange(formatarCPF(e.target.value))}
                        ref={props.ref} // wire up the input ref
                      />
                    )}
                    name="cpf"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: 'É necessário informar o CPF do sacado.',
                      validate: value => validarCPF(value) || 'CPF inválido.',
                    }}
                  />
                  <Col as={Card.Text} className="text-danger">
                    {errors.cpf && errors.cpf.message}
                  </Col>
                </Form.Group>
                <Form.Group as={Col} xs={4} controlId="nome" className="align-middle mb-0">
                  <Form.Control
                    name="nome"
                    type="text"
                    maxLength={150}
                    placeholder="Nome completo"
                    ref={register({
                      required: 'É necessário informar o nome completo do sacado.',
                      minLength: { value: 3, message: 'Informe o nome completo do sacado.' },
                      maxLength: { value: 150, message: 'O nome deve conter no máximo 150 caracteres.' },
                    })}
                  />
                  <Col as={Card.Text} className="text-danger">
                    {errors.nome && errors.nome.message}
                  </Col>
                </Form.Group>
                <Col xs="auto">
                  <Button type="submit">Selecionar</Button>
                </Col>
              </Row>
            </Form>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onCancel}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
};

export default SelecaoSocioOuDemais;
