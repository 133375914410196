import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Voltar, Confirmacao, Carregando } from '../../components';
import { alertar } from '../../utils';
import { modelTitulo } from '../../models';
import { Funci } from '../../services';

export default withRouter(props => {
  const { history, location } = props;
  const { titulo, atualizarTitulos = () => {} } = location.state || {};
  const { handleSubmit, register, errors, reset } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [objTitulo, setObjTitulo] = useState({});
  const [categorias, setCategorias] = useState([]);

  const statusColors = {
    ATIVO: 'text-primary',
    CANCELADO: 'text-danger',
  };

  const onSubmit = dados => {
    const { nrTitulo, idCategoria } = dados;

    // Verificação dos campos obrigatórios é feita pelas regras do form e no backend

    // Montar o objeto de título
    const obj = {
      titulo: nrTitulo,
      idCategoria: parseInt(idCategoria),
    };

    confirmar('Tem certeza que deseja salvar o título com os dados preenchidos?', () => salvarTitulo(obj));
  };

  const salvarTitulo = async obj => {
    try {
      setCarregando(true);
      fecharConfirmacao();
      let operacao;
      if (titulo) operacao = await Funci.alterarCategoriaTitulo(titulo, obj.idCategoria);
      else operacao = await Funci.incluirTitulo(obj);
      if (operacao === true || operacao.titulo) {
        atualizarTitulos();
        history.goBack();
      } else if (operacao.erro) {
        alertar('danger', operacao.erro);
      } else {
        alertar('error', 'Erro desconhecido na operação.');
      }
    } catch (error) {
      alertar('danger', error);
    } finally {
      setCarregando(false);
    }
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const cat = await Funci.listarCategoriasTitulos();
        if (cat.erro) alertar('danger', cat.erro);
        if (cat && cat.length > 0) {
          setCategorias(cat);
        }

        // Consultar o titulo caso venha o o numero para edição
        if (titulo) {
          const tit = await Funci.consultarTitulo(titulo);
          if (tit.erro) alertar('danger', tit.erro);
          if (tit.titulo) {
            setObjTitulo(modelTitulo(tit));
            reset({
              idCategoria: tit.idCategoria,
            });
          }
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [titulo, reset]);

  return (
    <Container fluid>
      <br />
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      <Card>
        <Card.Header as="h6">{objTitulo && objTitulo.titulo ? 'Editar título' : 'Novo título'}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
            <Row className="justify-content-between">
              <Col>
                <Row>
                  <Col>
                    {titulo && <Card.Title>{objTitulo.titulo}</Card.Title>}
                    {!titulo && (
                      <Form.Group controlId="nrTitulo" className="d-flex flex-column align-items-start">
                        <Form.Label className="mt-2 mb-0">
                          Número do Título <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="nrTitulo"
                          maxLength={10}
                          ref={register({
                            required: 'É necessário informar um número para o título.',
                          })}
                        />
                        <Col as={Card.Text} className="text-danger">
                          {errors.nrTitulo && errors.nrTitulo.message}
                        </Col>
                      </Form.Group>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="3">
                    <Form.Group controlId="idCategoria" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Categoria <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select aria-label="Categoria" name="idCategoria" ref={register({ required: 'É necessário informar uma categoria.' })}>
                        <>
                          <option value="">{''}</option>
                          {categorias.map(u => (
                            <option key={`${u.id}`} value={`${u.id}`}>{`${u.descricao}`}</option>
                          ))}
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.idCategoria && errors.idCategoria.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <hr />
              </Col>
            </Row>
            {titulo && (
              <Row>
                <Col>
                  <Card.Text>
                    Status: <span className={statusColors[objTitulo.statusLabel] || 'text-secondary'}>{objTitulo.statusLabel}</span>
                  </Card.Text>
                </Col>
              </Row>
            )}
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="success" type="submit">
                  {titulo ? 'Salvar' : 'Cadastrar título'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
});
