const ambiente = process.env.NODE_ENV;

const firebaseConfig =
  ambiente === 'development'
    ? {
        apiKey: 'AIzaSyC1bGJcwVxXsUJcQ1fwaEM5vvHocG_E8R8',
        authDomain: 'mbtc-app.firebaseapp.com',
        databaseURL: 'https://mbtc-app.firebaseio.com',
        projectId: 'mbtc-app',
        storageBucket: 'mbtc-app.appspot.com',
        messagingSenderId: '773846006351',
        appId: '1:773846006351:web:3f02ffebd325a533db483a',
      }
    : {
        apiKey: 'AIzaSyC1bGJcwVxXsUJcQ1fwaEM5vvHocG_E8R8',
        authDomain: 'mbtc-app.firebaseapp.com',
        databaseURL: 'https://mbtc-app.firebaseio.com',
        projectId: 'mbtc-app',
        storageBucket: 'mbtc-app.appspot.com',
        messagingSenderId: '773846006351',
        appId: '1:773846006351:web:3f02ffebd325a533db483a',
      };

export default firebaseConfig;
