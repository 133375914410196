import moment from 'moment';
import { campoObrigatorio, campoOpcional } from '../utils';

/**
 * @method modelProduto
 * @description Modelo padrão de objeto Produto
 * @param {object} produto - Vide código da model. Lista omitida com o objetivo de diminuir o tamanho do arquivo.
 * @returns {object} Objeto formatado
 */
const model = ({ id, nome, descricao, valor, tipoProduto, descontoAutomatico, publico, idCategoria, categoria, status, timestamp }) => {
  const getStatus = () => {
    if (status === 'A') return 'ATIVO';
    else if (status === 'I') return 'INATIVO';
    else return 'DESCONHECIDO';
  };
  const getPublicoLabel = () => {
    if (publico === 'A') return 'TODOS';
    else if (publico === 'N') return 'NÃO SÓCIOS';
    else if (publico === 'S') return 'SÓCIOS';
    else return 'N/D';
  };
  const getTipoLabel = () => {
    if (tipoProduto === 'an') return 'AN';
    else if (tipoProduto === 'av') return 'AV';
    else if (tipoProduto === 'ca') return 'CA';
    else if (tipoProduto === 'es') return 'ES';
    else if (tipoProduto === 'ml') return 'ML';
    else if (tipoProduto === 'ms') return 'MS';
    else if (tipoProduto === 'na') return 'NA';
    else if (tipoProduto === 'pa') return 'PA';
    else if (tipoProduto === 're') return 'RE';
    else if (tipoProduto === 'OT') return 'OT';
    else return 'DESCONHECIDO';
  };

  return {
    id: campoOpcional(id, 'id', ['number']),
    nome: campoObrigatorio(nome, 'nome', ['string'], 3, 40),
    descricao: campoOpcional(descricao, 'descricao', ['string'], 0, 150),
    valor: campoOpcional(valor, 'valor', ['number']),
    tipoProduto: campoObrigatorio(tipoProduto, 'tipoProduto', ['string'], 1, 5),
    tipoProdutoLabel: getTipoLabel(),
    descontoAutomatico: campoObrigatorio(descontoAutomatico, 'descontoAutomatico', ['string'], 1, 1, ['S', 'N']),
    publico: campoObrigatorio(publico, 'publico', ['string'], 1, 1, ['A', 'S', 'N']),
    publicoLabel: getPublicoLabel(),
    idCategoria: campoObrigatorio(idCategoria, 'idCategoria', ['number']),
    categoria: campoOpcional(categoria, 'categoria', ['string']),
    status: campoOpcional(status, 'status', ['string']),
    statusLabel: getStatus(),
    timestamp: timestamp ? moment(timestamp) : null,
  };
};

export default model;
