import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { GestaoChurrasqueiras } from '.';
import { Reservas } from '../reservas';

export default () => {
  return (
    <Container fluid>
      <br />
      <Tabs defaultActiveKey="reservas" id="uncontrolled-tab-example">
        {/** ************************************************************************************************************************************* */}
        {/** * RESERVAS                                                                                                                            */}
        {/** ************************************************************************************************************************************* */}
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="reservas" title="Reservas">
          <Reservas />
        </Tab>
        {/** ************************************************************************************************************************************* */}
        {/** * CHURRASQUEIRAS                                                                                                                      */}
        {/** ************************************************************************************************************************************* */}
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="churrasqueiras" title="Churrasqueiras">
          <br />
          <GestaoChurrasqueiras />
        </Tab>
      </Tabs>
    </Container>
  );
};
