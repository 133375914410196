import moment from 'moment';
import formatarValor from './formatarValor';

import validarTipo from './validarTipo';

/**
 * gerarTextoInstrucoes
 *
 * @param {objeto} boleto
 * @param {string} textoAdicional
 * @returns
 */
const gerarTextoInstrucoes = (boleto, textoAdicional) => {
  if (!validarTipo(boleto, 'object')) return [' '];
  const {
    inAceiteTituloVencido,
    nrDiasLimRecebimento,
    descTp,
    descDtExp,
    descPorcent,
    descValor,
    jurosMoraTp,
    jurosMoraPorcent,
    jurosMoraValor,
    multaTp,
    multaPorcent,
    multaValor,
  } = boleto;
  const lista = [];

  // Permissão de pagamento de título vencido
  if (inAceiteTituloVencido === 'S' && nrDiasLimRecebimento > 0) {
    lista.push(`Sr. CAIXA, não receber após ${nrDiasLimRecebimento} dias do vencimento.`);
  }

  // Desconto
  if (descTp === 1)
    lista.push(`Até o dia ${moment(descDtExp, 'DD/MM/YYYY').format('DD/MM/YYYY')} conceder desconto de R$ ${formatarValor(descValor)}.`);
  if (descTp === 2)
    lista.push(`Até o dia ${moment(descDtExp, 'DD/MM/YYYY').format('DD/MM/YYYY')} conceder desconto de ${formatarValor(descPorcent)}%.`);

  // Juros/Mora e Multa
  let textoJurosMulta = 'Após o vencimento: ';
  if (jurosMoraTp === 1) textoJurosMulta = `${textoJurosMulta}Cobrar mora de R$ ${formatarValor(jurosMoraValor)} por dia de atraso. `;
  if (jurosMoraTp === 2) textoJurosMulta = `${textoJurosMulta}Cobrar juros de ${formatarValor(jurosMoraPorcent)}% por mês de atraso. `;
  if (multaTp === 1) textoJurosMulta = `${textoJurosMulta}Cobrar multa de R$ ${formatarValor(multaValor)} por dia de atraso. `;
  if (multaTp === 2) textoJurosMulta = `${textoJurosMulta}Cobrar multa de ${formatarValor(multaPorcent)}% por mês de atraso. `;

  if (jurosMoraTp > 0 || multaTp > 0) {
    lista.push(textoJurosMulta);
  }

  // Instrução adicional
  if (textoAdicional && validarTipo(textoAdicional, 'string')) lista.push(textoAdicional);

  if (lista.length > 0) return lista;
  return [' '];
};

export default gerarTextoInstrucoes;
