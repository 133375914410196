import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Badge, Table, Form, Card, Container, Tabs, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Carregando, Paginacao } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarValor, withOrdenar } from '../../utils';
import { useForm } from 'react-hook-form';
import { modelProduto } from '../../models';

export default withRouter(props => {
  const { history, location } = props;
  const { criterio, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao } = {
    criterio: '',
    pagina: 1,
    qtdePagina: 25,
    campoOrdenacao: '0',
    sentidoOrdenacao: '0',
    ...location.state,
  };
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const [produtos, setProdutos] = useState([]);
  const [records, setRecords] = useState('');
  const { handleSubmit, register, setValue, errors } = useForm();

  setValue('paramCriterio', criterio);

  const onSubmitForm = async values => {
    const { paramCriterio } = values;
    // Validar quaisquer campos necessários se houver

    // Comandar a navagação paginada
    history.push('/produtos', { criterio: paramCriterio, pagina: 1, qtdePagina: 25, campoOrdenacao: '0', sentidoOrdenacao: '0' });
  };

  const navegar = (crit, pag, qtde, campo, sentido) => {
    try {
      history.push('/produtos', { criterio: crit, pagina: pag, qtdePagina: qtde, campoOrdenacao: campo, sentidoOrdenacao: sentido });
    } catch (e) {
      alertar('error', e.msg || e);
    }
  };

  const ordenar = (campo, sentido) => {
    navegar(criterio, pagina, qtdePagina, campo, sentido);
  };

  const paginar = pag => {
    navegar(criterio, pag, qtdePagina, campoOrdenacao, sentidoOrdenacao);
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.buscarProdutosPorCriterio(criterio, pagina, qtdePagina, campoOrdenacao || '0', sentidoOrdenacao || '0');
        if (consulta.erro) {
          setProdutos([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) {
            setProdutos([]);
            alertar('warning', 'Nenhum produto encontrado.');
          }
          if (consulta.length > 0) {
            setRecords(consulta[0].records);
            setProdutos(consulta.map(p => modelProduto(p)));
          }
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [atualizar, criterio, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao]);

  const statusColors = {
    ATIVO: 'text-primary',
    INATIVO: 'text-secondary',
  };

  const rotaNovo = { pathname: '/editarproduto' };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Tabs defaultActiveKey="produtos" id="uncontrolled-tab-example">
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="produtos" title="Produtos">
          <Row className="px-2">
            <Col xs="auto">
              <LinkContainer to={rotaNovo}>
                <Button variant="success" className="mt-2">
                  Novo produto
                </Button>
              </LinkContainer>
            </Col>
            <Col className="justify-content-end">
              <Form noValidate onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
                <Row className="justify-content-end">
                  <Form.Group as={Col} xs={4} controlId="paramCriterio" className="align-middle mb-0">
                    <Form.Control
                      name="paramCriterio"
                      type="text"
                      maxLength={50}
                      placeholder="Nome, descrição ou categoria"
                      ref={register({
                        minLength: { value: 1, message: 'A pesquisa deve conter no mínimo 1 caractere(s).' },
                        maxLength: { value: 50, message: 'A pesquisa deve conter no máximo 50 caracteres.' },
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.paramCriterio && errors.paramCriterio.message}
                    </Col>
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit">Buscar</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          {produtos && produtos.length > 0 && (
            <>
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th className="text-center">Id</th>
                    <th>Nome</th>
                    <th className="text-center">Tipo</th>
                    <th className="text-center">Público</th>
                    <th className="text-center">Desconto Autom.</th>
                    <th className="text-center">Valor</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Detalhes</th>
                  </tr>
                </thead>
                <tbody>
                  {produtos.map(p => (
                    <tr key={`${p.id}`}>
                      <td className="text-center">{p.id}</td>
                      <td>{p.nome || ''}</td>
                      <td className="text-center">{p.tipoProdutoLabel}</td>
                      <td className="text-center">{p.publicoLabel}</td>
                      <td className="text-center">{p.descontoAutomatico}</td>
                      <td className="text-center">{p.valor ? `R$ ${formatarValor(p.valor)}` : ''}</td>
                      <td className={`${statusColors[p.statusLabel] || 'text-secondary'} text-center`}>{p.statusLabel}</td>
                      <td className="text-center">
                        <LinkContainer to={{ pathname: `/detalheproduto/${p.id}` }}>
                          <Badge>Clique aqui</Badge>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-center">
                <Paginacao records={records} qtdePorPagina={qtdePagina} paginaAtual={pagina} paginar={paginar} />
              </div>
            </>
          )}
          {!produtos || (produtos.length <= 0 && <Card.Text className="text-center pb-0 mt-2">{'Nenhum produto encontrado.'}</Card.Text>)}
        </Tab>
      </Tabs>
    </Container>
  );
});
