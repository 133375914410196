import { Col, Image, Nav, Row } from 'react-bootstrap';
import moment from 'moment';

import './Header.scss';

import { Auth, Funci } from '../services';
import { Timer } from '.';

const logo = require('../assets/logo/mbtc-logo.png');

const Header = () => {
  const funci = new Funci();
  const auth = new Auth();
  const backgroundColors = {
    PRODUCAO: 'bg-dark',
    HM: 'bg-danger',
    DESENV: 'bg-danger',
    LOCAL: 'bg-info',
  };
  const modoHomologacao = funci.getAmbiente() !== 'PRODUCAO';
  const bgColor = backgroundColors[funci.getAmbiente()] || 'bg-danger';
  console.log('auth.sessionExpiration', auth.sessionExpiration);

  return (
    <Nav variant="pills" className={`navbar navbar-darkborder-bottom border-white w-100 m-0 justify-content-between ${bgColor} header-top`}>
      <Col xs="auto mx-2">
        <h3 className="text-white">Painel de Controle - MBTC</h3>
      </Col>
      {modoHomologacao && (
        <Col>
          <h3 className="text-white text-center px-1">AMBIENTE DE HOMOLOGAÇÃO</h3>
        </Col>
      )}
      <Col>
        <Row className="align-items-center">
          <Col className="d-flex flex-row justify-content-end">
            <div className="text-danger text-end">
              Não utilize o botão de voltar do navegador
              <br />
              <div className="d-flex flex-row justify-content-end align-items-center">
                <Timer expiryTimestamp={moment(auth.sessionExpiration, 'DD/MM/YYYY HH:mm:ss')} />
                <span className="ms-3 text-white text-end" style={{ fontSize: 10 }}>
                  {`Versão ${process.env.REACT_APP_VERSION}`}
                </span>
              </div>
            </div>
          </Col>
          <Col xs="auto mx-2">
            <a href="https://www.minasbrasilia.com.br" target="_blank" rel="noopener noreferrer">
              <Image className="ml-10" src={logo} style={{ height: 35 }} />
            </a>
          </Col>
        </Row>
      </Col>
    </Nav>
  );
};

export default Header;
