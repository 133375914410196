import React, { useState, useEffect } from 'react';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Card, Container, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import * as WebDataRocksReact from 'react-webdatarocks';
import { Funci } from '../../services';
import { alertar } from '../../utils';
import moment from 'moment';
import { Carregando, Voltar } from '../../components';

const portugues = require('./pr.json');

export default withRouter(props => {
  const [atualizacao, setAtualizacao] = useState(0);
  const [carregando, setCarregando] = useState(true);
  const [relatorio, setRelatorio] = useState([]);

  const customizeToolbar = toolbar => {
    const tabs = toolbar.getTabs(); // get all tabs from the toolbar
    console.log('tabs', tabs);
    toolbar.getTabs = function () {
      delete tabs[0]; // delete the first tab
      delete tabs[1]; // delete the second tab
      delete tabs[2]; // delete the third tab
      return tabs;
    };
  };

  const report = {
    dataSource: {
      dataSourceType: 'json',
      data: relatorio,
    },
    // *****
    // customizações
    localization: portugues,
    // *****
    slice: {
      rows: [
        {
          uniqueName: 'idPagamento',
          caption: 'ID Pagamento',
        },
        {
          uniqueName: 'idPessoa',
          caption: 'ID Pessoa',
        },
        {
          uniqueName: 'nome',
          caption: 'Nome',
        },
        {
          uniqueName: 'vlTotal',
          caption: 'Valor Total',
        },
        {
          uniqueName: 'vlPago',
          caption: 'Valor Pago',
        },
        {
          uniqueName: 'vlDiferenca',
          caption: 'Diferença',
        },
        {
          uniqueName: 'dtPagamento',
          caption: 'Data Pagamento',
        },
        {
          uniqueName: 'observacao',
          caption: 'Observação',
        },
      ],
      columns: [
        {
          uniqueName: 'Measures',
        },
      ],
      measures: [
        {
          uniqueName: 'vlTotal',
          aggregation: 'sum',
        },
        {
          uniqueName: 'vlPago',
          aggregation: 'sum',
        },
        {
          uniqueName: 'vlDiferenca',
          aggregation: 'sum',
        },
      ],
      flatOrder: ['idPagamento', 'idPessoa', 'nome', 'vlTotal', 'vlPago', 'vlDiferenca', 'dtPagamento', 'observacao'],
      drills: {
        drillAll: false,
      },
    },
    tableSizes: {
      columns: [
        { idx: 0, width: 70 },
        { idx: 1, width: 70 },
        { idx: 2, width: 250 },
        { idx: 3, width: 100 },
        { idx: 4, width: 100 },
        { idx: 5, width: 100 },
        { idx: 6, width: 100 },
        { idx: 7, width: 250 },
      ],
    },
    conditions: [
      {
        formula: '#value < 0',
        measure: 'vlDiferenca',
        format: {
          backgroundColor: '#E57373',
          color: '#000000',
          fontWeight: 'bold',
        },
      },
      {
        formula: '#value > 0',
        measure: 'vlDiferenca',
        format: {
          backgroundColor: '#AED581',
          color: '#000000',
          fontWeight: 'bold',
        },
      },
    ],
    options: {
      grid: {
        type: 'flat',
        title: 'Banco do Brasil - Liquidação de boletos pagos por data',
        showFilter: true,
        showHeaders: false,
        showTotals: 'off',
        // showGrandTotals: 'off',
        // showHierarchies: false,
        // showHierarchyCaptions: false,
        // showReportFiltersArea: false,
      },
      configuratorActive: false,
      configuratorButton: false,
      showAggregations: false,
      showCalculatedValuesButton: false,
      drillThrough: false,
      showDrillThroughConfigurator: false,
      sorting: 'on',
      datePattern: 'dd/MM/yyyy',
      dateTimePattern: 'dd/MM/yyyy HH:mm:ss',
      saveAllFormats: false,
      showDefaultSlice: false,
      defaultHierarchySortName: 'asc',
    },
    formats: [
      {
        name: '',
        thousandsSeparator: '.',
        decimalSeparator: ',',
        decimalPlaces: 2,
        maxSymbols: 20,
        currencySymbol: 'R$ ',
        currencySymbolAlign: 'left',
        nullValue: ' ',
        infinityValue: 'Infinity',
        divideByZeroValue: 'Infinity',
      },
    ],
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const consulta = await Funci.relatorioBoletosPagosPorData(moment().format('YYYY-MM-DD'));
        if (consulta.erro) return alertar('danger', consulta.erro);
        console.log('reserv', consulta);
        if (consulta.length > 0) {
          setRelatorio(consulta);
        } else {
          alertar('warning', 'Nenhum dado encontrado.');
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [atualizacao]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Row className="justify-content-between">
        <Voltar />
      </Row>
      <Card>
        <Card.Header as="h6">{`Relatório`}</Card.Header>
        <Card.Body className="pb-4">
          {relatorio && relatorio.length > 0 && (
            <WebDataRocksReact.Pivot toolbar={true} width="100%" report={report} beforetoolbarcreated={customizeToolbar} />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
});
