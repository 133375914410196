import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Card, Row } from 'react-bootstrap';
import { Voltar, Alerta, Carregando, Confirmacao } from '../../components';
import { useParams } from 'react-router-dom';
import { Funci } from '../../services';
import { modelChurrasqueira } from '../../models';

export default () => {
  const { idChurrasqueira } = useParams();
  const [atualizacao, setAtualizacao] = useState(0);
  const [carregando, setCarregando] = useState(true);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', onConfirm: () => {}, onCancel: () => {}, show: false });
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [churrasqueira, setChurrasqueira] = useState(null);

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const alterarStatusChurrasqueira = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      setCarregando(true);
      let alteracao;
      if (churrasqueira.status === 'A') {
        alteracao = await Funci.desativarChurrasqueira(idChurrasqueira);
      } else if (churrasqueira.status === 'I') {
        alteracao = await Funci.ativarChurrasqueira(idChurrasqueira);
      } else {
        alertar('warning', 'Status da churrasqueira não reconhecido.');
      }
      if (alteracao.erro) alertar('danger', alteracao.erro);
      if (alteracao === true) {
        alertar('success', 'Churrasqueira alterada com sucesso.');
        return setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  const alterarChurrasqueiraApp = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      setCarregando(true);
      let alteracao;
      if (churrasqueira.habilitadoApp === true) {
        const objCh = { ...churrasqueira, habilitadoApp: false };
        alteracao = await Funci.editarChurrasqueira(objCh);
      } else if (churrasqueira.habilitadoApp === false) {
        const objCh = { ...churrasqueira, habilitadoApp: true };
        alteracao = await Funci.editarChurrasqueira(objCh);
      } else {
        alertar('warning', 'Status da churrasqueira não reconhecido.');
      }
      if (alteracao.erro) alertar('danger', alteracao.erro);
      if (alteracao === true) {
        alertar('success', 'Churrasqueira alterada com sucesso.');
        return setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const churrasq = await Funci.consultarChurrasqueira(idChurrasqueira);
        if (churrasq.erro) alertar('danger', churrasq.erro);
        if (churrasq.idChurrasqueira) setChurrasqueira(modelChurrasqueira(churrasq));
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [idChurrasqueira, atualizacao]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>
      {churrasqueira ? (
        <Card>
          <Card.Header as="h6">{`Churrasqueira`}</Card.Header>
          <Card.Body>
            <Card.Title>{`${churrasqueira.nome}`}</Card.Title>
            <Row>
              <Col>
                <Card.Text>
                  {`Categoria: ${{ N: 'NORMAL', E: 'ESPECIAL' }[churrasqueira.categoria]}`}
                  <br />
                  {`Capacidade: ${churrasqueira.capacidade} pessoas`}
                </Card.Text>
              </Col>
              <Col>
                <Card.Text>
                  {`Status: ${{ A: 'ATIVA', I: 'INATIVA' }[churrasqueira.status]}`}
                  <br />
                  {`Disponível no App? ${churrasqueira.habilitadoAppLabel}`}
                  <br />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  className="mx-2"
                  variant={`${{ A: 'danger', I: 'primary' }[churrasqueira.status]}`}
                  onClick={() =>
                    confirmar(
                      `Tem certeza que deseja ${{ A: 'desativar', I: 'ativar' }[churrasqueira.status]} ${churrasqueira.nome}?`,
                      alterarStatusChurrasqueira,
                    )
                  }
                >
                  {`${{ A: 'Desativar', I: 'Ativar' }[churrasqueira.status]}`}
                </Button>
                <Button
                  className="mx-2"
                  variant={`${churrasqueira.habilitadoApp ? 'danger' : 'primary'}`}
                  onClick={() =>
                    confirmar(
                      `Tem certeza que deseja ${churrasqueira.habilitadoApp ? 'desativar' : 'ativar'} ${churrasqueira.nome} para utilização no App?`,
                      alterarChurrasqueiraApp,
                    )
                  }
                >
                  {`${churrasqueira.habilitadoApp ? 'Desabilitar no APP' : 'Habilitar no APP'}`}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </Container>
  );
};
