import moment from 'moment';
import validarTipo from './validarTipo';

/**
 * withOrdenar
 *
 * Função que permite encadear ordenações facilitadas em um array de objetos
 * Uso: withOrdenar([{x:1, y:'AA'},{x:2, y:'AB'},{x:3, y:'AC'}]).ordenar('x', 'A').ordenar('y', 'D');
 *      Ordena pelo campo 'x' em ordem crescente,
 *      depois pelo campo 'x' em ordem decrescente
 *      e retorna o array ordenado.
 *
 * @param {array} array de objetos
 * @returns {array} arrayPrototipado
 */
const withOrdenar = array => {
  // Adiciona a prototipação da função ordenar no objeto recebido
  const objPrototipado = [...array];
  Object.defineProperty(objPrototipado, 'ordenar', { value: ordenar });

  // Validações
  if (!validarTipo(array, 'object') || !array.length) {
    console.log('Função ordernar: Tipo inválido ou objeto vazio.');
    return objPrototipado;
  }
  if (array.reduce((total, it) => total + (validarTipo(it, 'object') ? 0 : 1), 0)) {
    console.log('Função ordernar: O array possui itens que não são objetos js.');
    return objPrototipado;
  }

  return objPrototipado;
};

/**
 * ordenar
 *
 * Função para ordenação de objetos
 * Uso: ordenar(....)
 *
 * @version 1.0.0
 * @param {string} campo
 * @param {string} ordenação [A: Ascendente (padrão), D: Descendente]
 * @param {string} [opcional] Informar o formato em caso de string de data
 * @returns {array} array ordenado
 */
const ordenar = function (campo, ordenacao = 'A', formato) {
  const array = this;

  // Validações
  if (!validarTipo(campo, 'string') || campo.length < 1) {
    console.log('Função ordernar: Campo inválido.');
    return array;
  }
  if (!validarTipo(ordenacao, 'string') || ordenacao.length < 1 || (ordenacao !== 'A' && ordenacao !== 'D')) {
    console.log('Função ordernar: Tipo de ordenação inválido.');
    return array;
  }

  //ORDENAÇÃO
  try {
    if (formato) {
      // Por string de data formatada
      if (ordenacao === 'A') array.sort((a, b) => moment(a[campo], formato).diff(moment(b[campo], formato)));
      if (ordenacao === 'D') array.sort((a, b) => moment(b[campo], formato).diff(moment(a[campo], formato)));
    } else {
      // Por ordem literal - número ou string
      array.sort((a, b) => {
        if (a[campo] < b[campo]) return ordenacao === 'A' ? -1 : 1;
        if (a[campo] > b[campo]) return ordenacao === 'A' ? 1 : -1;
        return 0;
      });
    }
    return array;
  } catch (error) {
    console.log(`Função ordernar: Erro ao ordernar o array. ${error}`);
    return array;
  }
};

export default withOrdenar;
