import { validarTipo } from '../utils';
import Auth from './Auth';
import Requisicoes from './Requisicoes';

/**
 * Funci
 * Classe singleton
 *
 * @export
 * @class Funci
 */
export default class Funci {
  /**
   * Cria uma instância singleton da classe Funci.
   * @memberof Funci
   */
  constructor() {
    if (!Funci.instance) {
      this.info = {};
      this.autenticado = false;
      this.perfis = [];
      this.perfisCarregados = false;
      Funci.instance = this;
    }
    return Funci.instance;
  }

  /**
   * isAutenticado
   *
   * @returns
   * @memberof Funci
   */
  isAutenticado() {
    return this.autenticado;
  }

  /**
   * loadInfoApp
   *
   * @memberof Funci
   */
  async loadInfoApp() {
    this.info = await Requisicoes.getInfo();
    return this.info;
  }

  /**
   * getVersaoFrontend
   *
   * @memberof Funci
   */
  getVersaoFrontend() {
    const { versaoFrontend } = this.info;
    if (versaoFrontend) return versaoFrontend;
  }

  /**
   * getAmbiente
   *
   * @returns {string} ambiente [LOCAL, DESENV, HOMOLOGACAO, PRODUCAO]
   * @memberof Funci
   */
  getAmbiente() {
    const { ambiente } = this.info;
    if (ambiente) return ambiente;
  }

  /**
   * isFunci
   *
   * @static
   * @param {string} email
   * @memberof Funci
   */
  static async isFunci(email) {
    if (!validarTipo(email, 'string')) return false;
    const convites = await Requisicoes.getIsFunci(email);
    return convites;
  }

  /**
   * consultarSocioPorTitulo
   *
   * @param {*} titulo
   * @returns
   * @memberof Funci
   */
  static async consultarSocioPorTitulo(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const socio = await Requisicoes.getSocioPorTitulo(titulo);
    return socio;
  }

  /**
   * buscarPessoas
   *
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarPessoas(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(criterio, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const consulta = await Requisicoes.getBuscarPessoas(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    return consulta;
  }

  /**
   * consultarPessoa
   *
   * @param {string|number} id
   * @memberof Funci
   */
  static async consultarPessoa(id) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    const consulta = await Requisicoes.getConsultarPessoa(id);
    return consulta;
  }

  /**
   * incluirPessoa
   *
   * @param {object} pessoa
   * @memberof Funci
   */
  static async incluirPessoa(pessoa) {
    if (!pessoa) return false;
    const resposta = await Requisicoes.postIncluirPessoas(pessoa);
    return resposta;
  }

  /**
   * obterFoto
   *
   * @param {string|number} id
   * @memberof Funci
   */
  static async obterFoto(id) {
    if (!id) return false;
    const resposta = await Requisicoes.getObterFoto(id);
    return resposta;
  }

  /**
   * convitesDispAvulsos
   *
   * @param {*} titulo
   * @memberof Funci
   */
  static async convitesDispAvulsos(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const convites = await Requisicoes.getConvitesAvulsosDispPorTitulo(titulo);
    return convites;
  }

  /**
   * convitesDispChurras
   *
   * @param {string} ano
   * @param {string} titulo
   * @memberof Funci
   */
  static async convitesDispChurras(ano, titulo) {
    if (!validarTipo(ano, 'string')) return false;
    if (!validarTipo(titulo, 'string')) return false;
    const convites = await Requisicoes.getConvitesChurrasDispPorTitulo(ano, titulo);
    return convites;
  }

  /**
   * convitesDispChurrasPorReserva
   *
   * @param {string} idReserva
   * @param {string} ano
   * @memberof Funci
   */
  static async convitesDispChurrasPorReserva(idReserva, ano) {
    if (!validarTipo(idReserva, 'string')) return false;
    if (!validarTipo(ano, 'string')) return false;
    const convites = await Requisicoes.getConvitesChurrasDispPorReserva(idReserva, ano);
    return convites;
  }

  /**
   * convitesDispChurrasqueiras
   *
   * @param {string} ano
   * @memberof Funci
   */
  async convitesDispChurrasqueiras(ano) {
    if (!this.uid) return false;
    const convites = await Requisicoes.getConvitesDispChurrasq(this.uid, ano);
    return convites;
  }

  /**
   * emitirConvite
   *
   * @param {object} convite
   * @memberof Funci
   */
  static async emitirConvite(convite) {
    if (!convite) return false;
    const convites = await Requisicoes.postEmitirConvite(convite);
    return convites;
  }

  /**
   * listarConvites
   *
   * @param {*} titulo
   * @memberof Funci
   */
  static async listarConvites(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const reservas = await Requisicoes.getListarConvites(titulo);
    return reservas;
  }

  /**
   * consultarConvite
   *
   * @param {*} idConvite
   * @memberof Funci
   */
  static async consultarConvite(idConvite) {
    if (!validarTipo(idConvite, 'string') && !validarTipo(idConvite, 'number')) return false;
    const reservas = await Requisicoes.getConsultarConvite(idConvite);
    return reservas;
  }

  /**
   * confirmarEntrada
   *
   * @param {*} idConvite
   * @memberof Funci
   */
  static async confirmarEntrada(idConvite) {
    if (!validarTipo(idConvite, 'string') && !validarTipo(idConvite, 'number')) return false;
    const reservas = await Requisicoes.postConfirmarEntrada({ idConvite });
    return reservas;
  }

  /**
   * cancelarConvite
   *
   * @param {*} convite
   * @memberof Funci
   */
  static async cancelarConvite(convite) {
    if (!validarTipo(convite, 'object')) return false;
    const reservas = await Requisicoes.patchAlterarConvite({ ...convite, acao: 'C' });
    return reservas;
  }

  /**
   * infoTurnos
   *
   * @memberof Funci
   */
  static async infoTurnos() {
    const convites = await Requisicoes.getTurnos();
    return convites;
  }

  /**
   * listarChurrasqueiras
   *
   * @memberof Funci
   */
  static async listarChurrasqueiras() {
    const convites = await Requisicoes.getChurrasqueiras();
    return convites;
  }

  /**
   * consultarChurrasqueira
   *
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async consultarChurrasqueira(idChurrasqueira) {
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const convites = await Requisicoes.getChurrasqueiraPorId(idChurrasqueira);
    return convites;
  }

  /**
   * ativarChurrasqueira
   *
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async ativarChurrasqueira(idChurrasqueira) {
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const convites = await Requisicoes.patchAlterarChurrasqueira({ idChurrasqueira, acao: 'A' });
    return convites;
  }

  /**
   * desativarChurrasqueira
   *
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async desativarChurrasqueira(idChurrasqueira) {
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const convites = await Requisicoes.patchAlterarChurrasqueira({ idChurrasqueira, acao: 'D' });
    return convites;
  }

  /**
   * editarChurrasqueira
   *
   * @param {*} churrasqueira
   * @memberof Funci
   */
  static async editarChurrasqueira(churrasqueira) {
    if (!validarTipo(churrasqueira, 'object')) return false;
    const convites = await Requisicoes.patchAlterarChurrasqueira({ ...churrasqueira, acao: 'E' });
    return convites;
  }

  /**
   * listarReservas
   *
   * @param {string} dataInicial - formato YYYY-MM-DD
   * @param {string} dataFinal - formato YYYY-MM-DD
   * @param {string} titulo
   * @memberof Funci
   */
  static async listarReservas(dataInicial, dataFinal, titulo) {
    if (!validarTipo(dataInicial, 'string')) return false;
    if (!validarTipo(dataFinal, 'string')) return false;
    if (!validarTipo(titulo, 'string')) return false;
    const reservas = await Requisicoes.getListarReservas(dataInicial.replace(/\//g, '-'), dataFinal.replace(/\//g, '-'), titulo);
    return reservas;
  }

  /**
   * consultarReserva
   *
   * @param {*} idReserva
   * @memberof Funci
   */
  static async consultarReserva(idReserva) {
    if (!validarTipo(idReserva, 'string')) return false;
    const reserva = await Requisicoes.getConsultarReservaPorId(idReserva, this.uid);
    return reserva;
  }

  /**
   * cancelarReserva
   *
   * @param {*} idReserva
   * @memberof Funci
   */
  static async cancelarReserva(idReserva) {
    if (!validarTipo(idReserva, 'string') && !validarTipo(idReserva, 'number')) return false;
    const alteracao = await Requisicoes.patchAlterarReserva({ idReserva, acao: 'C' });
    return alteracao;
  }

  /**
   * alterarChurrasReserva
   *
   * @param {*} idReserva
   * @param {*} idChurrasqueira
   * @memberof Funci
   */
  static async alterarChurrasReserva(idReserva, idChurrasqueira) {
    if (!validarTipo(idReserva, 'string') && !validarTipo(idReserva, 'number')) return false;
    if (!validarTipo(idChurrasqueira, 'string') && !validarTipo(idChurrasqueira, 'number')) return false;
    const alteracao = await Requisicoes.patchAlterarReserva({ idReserva, tipoObjeto: 'C', idObjeto: idChurrasqueira, acao: 'A' });
    return alteracao;
  }

  /**
   * listarChurrasqueirasDispPorData
   *
   * @param {*} data - Formato YYYY-MM-DD
   * @memberof Funci
   */
  static async listarChurrasqueirasDispPorData(data) {
    if (!validarTipo(data, 'string')) return false;
    const reserva = await Requisicoes.getChurrasqDispPorData(data);
    return reserva;
  }

  /**
   * listarReservasDatasDisp
   *
   * @memberof Funci
   */
  static async listarReservasDatasDisp() {
    const reserva = await Requisicoes.getReservasDatasDisp();
    return reserva;
  }

  /**
   * listarConvitesPorIdReserva
   *
   * @param {*} idReserva
   * @memberof Funci
   */
  static async listarConvitesPorIdReserva(idReserva) {
    if (!validarTipo(idReserva, 'string') && !validarTipo(idReserva, 'number')) return false;
    const consulta = await Requisicoes.getConvitesPorIdReserva(idReserva);
    return consulta;
  }

  /**
   * enviarConvitePorEmail
   *
   * @param {object} convite
   * @memberof Funci
   */
  static async enviarConvitePorEmail(convite) {
    if (!validarTipo(convite, 'object')) return false;
    const envio = await Requisicoes.postConviteEnviarEmail(convite);
    return envio;
  }

  /**
   * emitirReserva
   *
   * @param {object} reserva
   * @memberof Funci
   */
  static async emitirReserva(reserva) {
    if (!validarTipo(reserva, 'object')) return false;
    const emissao = await Requisicoes.postEmitirReserva(reserva);
    return emissao;
  }

  /**
   * listarUsuariosSistema
   *
   * @param {string} titulo
   * @memberof Funci
   */
  static async listarUsuariosSistema(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const resposta = await Requisicoes.getListarUsuariosSistema(titulo);
    return resposta;
  }

  /**
   * consultarUsuarioSistema
   *
   * @param {string} uid
   * @memberof Funci
   */
  static async consultarUsuarioSistema(uid) {
    if (!validarTipo(uid, 'string')) return false;
    const resposta = await Requisicoes.getConsultarUsuarioSistema(uid);
    return resposta;
  }

  /**
   * atualizarPerfisUsuario
   *
   * @memberof Funci
   */
  async atualizarPerfisUsuario() {
    const { uid } = Auth.getUsuario() || { uid: null };
    const resposta = await Requisicoes.getListarPerfisUsuario(uid);
    if (resposta && validarTipo(resposta, 'object')) {
      this.perfis = resposta;
      this.perfisCarregados = true;
      return true;
    }
    return false;
  }

  /**
   * limparPerfisUsuario
   *
   * @memberof Funci
   */
  async limparPerfisUsuario() {
    this.perfis = [];
    this.perfisCarregados = false;
  }

  /**
   * hasPerfil
   *
   * Busca se o usuário possui um determinado perfil, ou se possui algum dos perfis de uma lista informada
   *
   * @param {string | array} perfil
   * @memberof Funci
   */
  hasPerfil(perfil) {
    // Se o usuário for ADMIN, recebe permissão automática
    if (this.perfis.filter(p => p.descricao === 'ADMIN').length > 0) return true;
    if (validarTipo(perfil, 'string')) {
      if (perfil === '') return true;
      const filtro = this.perfis.filter(p => p.descricao === perfil);
      return filtro.length > 0;
    }
    if (validarTipo(perfil, 'object')) {
      const filtro = this.perfis.filter(p => perfil.findIndex(i => i === p.descricao) >= 0);
      return filtro.length > 0;
    }
  }

  /**
   * listarPerfisSistema
   *
   * @memberof Funci
   */
  static async listarPerfisSistema() {
    const resposta = await Requisicoes.getListarPerfisSistema();
    return resposta;
  }

  /**
   * desativarUsuarioSistema
   *
   * @param {*} usuario
   * @memberof Funci
   */
  static async desativarUsuarioSistema(usuario) {
    if (!validarTipo(usuario, 'object')) return false;
    const resposta = await Requisicoes.patchAlterarUsuarioSistema({ ...usuario, acao: 'D' });
    return resposta;
  }

  /**
   * ativarUsuarioSistema
   *
   * @param {*} usuario
   * @memberof Funci
   */
  static async ativarUsuarioSistema(usuario) {
    if (!validarTipo(usuario, 'object')) return false;
    const resposta = await Requisicoes.patchAlterarUsuarioSistema({ ...usuario, acao: 'A' });
    return resposta;
  }

  /**
   * atualizarPemissoesUsuarioSistema
   *
   * @param {*} usuario
   * @memberof Funci
   */
  static async atualizarPemissoesUsuarioSistema(usuario) {
    if (!validarTipo(usuario, 'object')) return false;
    const resposta = await Requisicoes.patchAlterarUsuarioSistema({ ...usuario, acao: 'AP' });
    return resposta;
  }

  /**
   * listarAcessosFisicos
   *
   * @param {string} dataInicial - formato YYYY-MM-DD
   * @param {string} dataFinal - formato YYYY-MM-DD
   * @param {string} tipoIdentificador
   * @param {string} identificador
   * @memberof Funci
   */
  static async listarAcessosFisicos(dataInicial, dataFinal, tipoIdentificador, identificador) {
    if (!validarTipo(dataInicial, 'string')) return false;
    if (!validarTipo(dataFinal, 'string')) return false;
    if (!validarTipo(tipoIdentificador, 'string')) return false;
    if (!validarTipo(identificador, 'string')) return false;
    const reservas = await Requisicoes.getListarAcessosFisicos(
      dataInicial.replace(/\//g, '-'),
      dataFinal.replace(/\//g, '-'),
      tipoIdentificador,
      identificador,
    );
    return reservas;
  }

  /**
   * registrarAcessoFisico
   *
   * @param {body} acesso
   * @memberof Funci
   */
  static async registrarAcessoFisico(acesso) {
    if (!validarTipo(acesso, 'object')) return false;
    const resposta = await Requisicoes.postRegistrarAcessoFisicos(acesso);
    return resposta;
  }

  /**
   * buscarExamesMedicos
   *
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarExamesMedicos(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(criterio, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const exames = await Requisicoes.getBuscarExamesMedicos(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    return exames;
  }

  /**
   * buscarExamesMedicosPorData
   *
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarExamesMedicosPorData(dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(dataInicial, 'string')) return false;
    if (!validarTipo(dataFinal, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const exames = await Requisicoes.getBuscarExamesMedicosPorData(dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    return exames;
  }

  /**
   * ConsultarExameMedico
   *
   * @param {string|number} id
   * @memberof Funci
   */
  static async consultarExameMedico(id) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    const exame = await Requisicoes.getConsultarExameMedico(id);
    return exame;
  }

  /**
   * validarExameMedico
   *
   * @param {object} exame
   * @memberof Funci
   */
  static async validarExameMedico(exame) {
    if (!validarTipo(exame, 'object')) return false;
    const reservas = await Requisicoes.patchAlterarExameMedico({ ...exame, acao: 'V' });
    return reservas;
  }

  /**
   * revalidarExameMedico
   *
   * @param {object} exame
   * @memberof Funci
   */
  static async revalidarExameMedico(exame) {
    if (!validarTipo(exame, 'object')) return false;
    const reservas = await Requisicoes.patchAlterarExameMedico({ ...exame, acao: 'R' });
    return reservas;
  }

  /**
   * cancelarExameMedico
   *
   * @param {object} exame
   * @memberof Funci
   */
  static async cancelarExameMedico(exame) {
    if (!validarTipo(exame, 'object')) return false;
    const reservas = await Requisicoes.patchAlterarExameMedico({ ...exame, acao: 'C' });
    return reservas;
  }

  /**
   * incluirExameMedico
   *
   * @param {object} exame
   * @memberof Funci
   */
  static async incluirExameMedico(exame) {
    if (!validarTipo(exame, 'object')) return false;
    const reservas = await Requisicoes.postIncluirExameMedico(exame);
    return reservas;
  }

  /**
   * buscarRestricoesPorCriterio
   *
   * @param {string} criterio
   * @param {string} dataInicial
   * @param {string} dataFinal
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarRestricoesPorCriterio(criterio, dataInicial, dataFinal, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(criterio, 'string')) return false;
    if (!validarTipo(dataInicial, 'string')) return false;
    if (!validarTipo(dataFinal, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const exames = await Requisicoes.getBuscarRestricoesPorCriterio(
      criterio,
      dataInicial,
      dataFinal,
      pagina,
      qtdePorPagina,
      campoOrdenacao,
      sentidoOrdenacao,
    );
    return exames;
  }

  /**
   * buscarSocioEDepPorTitulo
   *
   * @param {*} titulo
   * @returns
   * @memberof Funci
   */
  static async buscarSocioEDepPorTitulo(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const socio = await Requisicoes.getSocioEDepPorTitulo(titulo);
    return socio;
  }

  /**
   * incluirRestricao
   *
   * @param {object} restricao
   * @memberof Funci
   */
  static async incluirRestricao(restricao) {
    if (!validarTipo(restricao, 'object')) return false;
    const requisicao = await Requisicoes.postIncluirRestricao(restricao);
    return requisicao;
  }

  /**
   * alterarRestricao
   *
   * @param {object} restricao
   * @memberof Funci
   */
  static async alterarRestricao(restricao) {
    if (!validarTipo(restricao, 'object')) return false;
    const requisicao = await Requisicoes.patchAlterarRestricao(restricao);
    return requisicao;
  }

  /**
   * buscarBoletosPorCriterio
   *
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarBoletosPorCriterio(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(criterio, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const consulta = await Requisicoes.getBuscarBoletosPorCriterio(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    return consulta;
  }

  /**
   * listarBoletosAbertosPorCPF
   *
   * @param {string} cpf
   * @param {string} nascimento
   * @memberof Funci
   */
  static async listarBoletosAbertosPorCPF(cpf, nascimento) {
    if (!validarTipo(cpf, 'string')) return false;
    if (!validarTipo(nascimento, 'string')) return false;
    const consulta = await Requisicoes.getListarBoletosAbertosPorCPF(cpf, nascimento);
    return consulta;
  }

  /**
   * consultarBoletoPorId
   *
   * @param {string} idBoleto
   * @param {boolean} consultarIf
   * @returns
   * @memberof Funci
   */
  static async consultarBoletoPorId(idBoleto, consultarIf) {
    if (!validarTipo(idBoleto, 'string')) return false;
    const socio = await Requisicoes.getBoletoPorId(idBoleto, consultarIf || false);
    return socio;
  }

  /**
   * incluirBoleto
   *
   * @param {object} boleto
   * @memberof Funci
   */
  static async incluirBoleto(boleto) {
    if (!validarTipo(boleto, 'object')) return false;
    const requisicao = await Requisicoes.postIncluirBoleto(boleto);
    return requisicao;
  }

  /**
   * cancelarBoleto
   *
   * @param {string|number} id
   * @memberof Funci
   */
  static async cancelarBoleto(id) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    const requisicao = await Requisicoes.patchAlterarBoleto(id, { acao: 'C' });
    return requisicao;
  }

  /**
   * baixarBoleto
   *
   * @param {string|number} id
   * @param {string} motivo
   * @param {number} cdTipoBaixa
   * @memberof Funci
   */
  static async baixarBoleto(id, motivo, cdTipoBaixa) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    if (!validarTipo(motivo, 'string')) return false;
    if (!validarTipo(cdTipoBaixa, 'number')) return false;
    const requisicao = await Requisicoes.patchAlterarBoleto(id, { acao: 'B', motivo, cdTipoBaixa });
    return requisicao;
  }

  /**
   * downloadBoletoPdfPorId
   *
   * @param {string} idBoleto
   * @returns
   * @memberof Funci
   */
  static async downloadBoletoPdfPorId(idBoleto) {
    if (!validarTipo(idBoleto, 'string')) return false;
    const socio = await Requisicoes.getBoletoPdfPorId(idBoleto);
    return socio;
  }

  /**
   * enviarBoletoPorEmail
   *
   * @param {string} idBoleto
   * @param {object} payload
   * @memberof Funci
   */
  static async enviarBoletoPorEmail(idBoleto, payload) {
    if (!validarTipo(idBoleto, 'string')) return false;
    if (!validarTipo(payload, 'object')) return false;
    const envio = await Requisicoes.postBoletoEnviarEmail(idBoleto, payload);
    return envio;
  }

  /**
   * buscarProdutosPorCriterio
   *
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarProdutosPorCriterio(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(criterio, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const consulta = await Requisicoes.getBuscarProdutosPorCriterio(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    return consulta;
  }

  /**
   * consultarProdutoPorId
   *
   * @param {string} idBoleto
   * @returns
   * @memberof Funci
   */
  static async consultarProdutoPorId(idProduto) {
    if (!validarTipo(idProduto, 'string')) return false;
    const socio = await Requisicoes.getProdutoPorId(idProduto);
    return socio;
  }

  /**
   * desativarProduto
   *
   * @param {string|number} id
   * @memberof Funci
   */
  static async desativarProduto(id) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    const requisicao = await Requisicoes.patchAlterarProduto(id, { id, acao: 'D' });
    return requisicao;
  }

  /**
   * reativarProduto
   *
   * @param {string|number} id
   * @memberof Funci
   */
  static async reativarProduto(id) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    const requisicao = await Requisicoes.patchAlterarProduto(id, { id, acao: 'R' });
    return requisicao;
  }

  /**
   * alterarProduto
   *
   * @param {string|number} id
   * @param {object} produto
   * @memberof Funci
   */
  static async alterarProduto(id, produto) {
    if (!validarTipo(id, 'string') && !validarTipo(id, 'number')) return false;
    if (!validarTipo(produto, 'object')) return false;
    const requisicao = await Requisicoes.patchAlterarProduto(id, { ...produto, acao: 'A' });
    return requisicao;
  }

  /**
   * incluirProduto
   *
   * @param {object} produto
   * @memberof Funci
   */
  static async incluirProduto(produto) {
    if (!validarTipo(produto, 'object')) return false;
    const requisicao = await Requisicoes.postIncluirProduto(produto);
    return requisicao;
  }

  /**
   * listarCategoriasProdutos
   *
   * @memberof Funci
   */
  static async listarCategoriasProdutos() {
    const consulta = await Requisicoes.getListarCategoriasProdutos();
    return consulta;
  }

  /**
   * listarCategoriasAssociacao
   *
   * @memberof Funci
   */
  static async listarCategoriasAssociacao() {
    const consulta = await Requisicoes.getListarCategoriasAssociacao();
    return consulta;
  }

  /**
   * listarFormasPagto
   *
   * @memberof Funci
   */
  static async listarFormasPagto() {
    const consulta = await Requisicoes.getListarFormasPagto();
    return consulta;
  }

  /**
   * buscarProdutosPorIdPessoa
   *
   * @param {string|number} idPessoa
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarProdutosPorIdPessoa(idPessoa, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(idPessoa, 'string') && !validarTipo(idPessoa, 'number')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const consulta = await Requisicoes.getBuscarProdutosPorIdPessoa(idPessoa, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    return consulta;
  }

  /**
   * incluirContratacao
   *
   * @param {object} contratacao
   * @memberof Funci
   */
  static async incluirContratacao(contratacao) {
    if (!validarTipo(contratacao, 'object')) return false;
    const requisicao = await Requisicoes.postIncluirContratacao(contratacao);
    return requisicao;
  }

  /**
   * cancelarContratacao
   *
   * @param {string|number} idPessoa
   * @param {string|number} idProduto
   * @param {string|number} ordem
   * @memberof Funci
   */
  static async cancelarContratacao(idPessoa, idProduto, ordem) {
    if (!validarTipo(idPessoa, 'string') && !validarTipo(idPessoa, 'number')) return false;
    if (!validarTipo(idProduto, 'string') && !validarTipo(idProduto, 'number')) return false;
    if (!validarTipo(ordem, 'string') && !validarTipo(ordem, 'number')) return false;
    const requisicao = await Requisicoes.patchAlterarContratacao({ idPessoa, idProduto, ordem, acao: 'C' });
    return requisicao;
  }

  /**
   * listarLancamentosContratacao
   *
   * @param {string|number} idPessoa
   * @param {string|number} idProduto
   * @param {string|number} ordem
   * @memberof Funci
   */
  static async listarLancamentosContratacao(idPessoa, idProduto, ordem) {
    if (!validarTipo(idPessoa, 'string') && !validarTipo(idPessoa, 'number')) return false;
    if (!validarTipo(idProduto, 'string') && !validarTipo(idProduto, 'number')) return false;
    if (!validarTipo(ordem, 'string') && !validarTipo(ordem, 'number')) return false;
    const consulta = await Requisicoes.getListarLancamentosContratacao(idPessoa, idProduto, ordem);
    return consulta;
  }

  /**
   * buscarPessoasPassiveisLancamento
   *
   * @param {string|number} idProduto
   * @param {string|number} idCategoria
   * @param {string|number} idFormaPagto
   * @param {number} anoReferencia
   * @param {number} anoReferencia
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarPessoasPassiveisLancamento(
    idProduto,
    idCategoria,
    idFormaPagto,
    mesReferencia,
    anoReferencia,
    pagina,
    qtdePorPagina,
    campoOrdenacao,
    sentidoOrdenacao,
  ) {
    if (!validarTipo(idProduto, 'string') && !validarTipo(idProduto, 'number')) return false;
    if (!validarTipo(idCategoria, 'string') && !validarTipo(idCategoria, 'number')) return false;
    if (!validarTipo(idFormaPagto, 'string') && !validarTipo(idFormaPagto, 'number')) return false;
    if (!validarTipo(mesReferencia, 'string')) return false;
    if (!validarTipo(anoReferencia, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const consulta = await Requisicoes.getListarPessoasLancamento(
      idProduto,
      idCategoria,
      idFormaPagto,
      mesReferencia,
      anoReferencia,
      pagina,
      qtdePorPagina,
      campoOrdenacao,
      sentidoOrdenacao,
    );
    return consulta;
  }

  /**
   * buscarProdutosPassiveisCobranca
   *
   * @param {string|number} idPessoa
   * @param {string|number} idFormaPagto
   * @param {number} anoReferencia
   * @param {number} anoReferencia
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async buscarProdutosPassiveisCobranca(
    idPessoa,
    idFormaPagto,
    mesReferencia,
    anoReferencia,
    pagina,
    qtdePorPagina,
    campoOrdenacao,
    sentidoOrdenacao,
  ) {
    if (!validarTipo(idPessoa, 'string') && !validarTipo(idPessoa, 'number')) return false;
    if (!validarTipo(idFormaPagto, 'string') && !validarTipo(idFormaPagto, 'number')) return false;
    if (!validarTipo(mesReferencia, 'string')) return false;
    if (!validarTipo(anoReferencia, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const consulta = await Requisicoes.getBuscarProdutosPassiveisCobranca(
      idPessoa,
      idFormaPagto,
      mesReferencia,
      anoReferencia,
      pagina,
      qtdePorPagina,
      campoOrdenacao,
      sentidoOrdenacao,
    );
    return consulta;
  }

  /**
   * incluirLancamentos
   *
   * @param {array} lancamentos
   * @memberof Funci
   */
  static async incluirLancamentos(lancamentos) {
    if (!validarTipo(lancamentos, 'object') || !Array.isArray(lancamentos)) return false;
    const requisicao = await Requisicoes.postIncluirLancamentos(lancamentos);
    return requisicao;
  }

  /**
   * listarTitulosPorCriterio
   *
   * @param {string} criterio
   * @param {string|number} pagina
   * @param {string|number} qtdePorPagina
   * @param {string} campoOrdenacao
   * @param {string} sentidoOrdenacao
   * @memberof Funci
   */
  static async listarTitulosPorCriterio(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao) {
    if (!validarTipo(criterio, 'string')) return false;
    if (!validarTipo(pagina, 'string') && !validarTipo(pagina, 'number')) return false;
    if (!validarTipo(qtdePorPagina, 'string') && !validarTipo(qtdePorPagina, 'number')) return false;
    if (!validarTipo(campoOrdenacao, 'string')) return false;
    if (!validarTipo(sentidoOrdenacao, 'string')) return false;
    const consulta = await Requisicoes.getListarTitulosPorCriterio(criterio, pagina, qtdePorPagina, campoOrdenacao, sentidoOrdenacao);
    return consulta;
  }

  /**
   * consultarTitulo
   *
   * @param {string} titulo
   * @memberof Funci
   */
  static async consultarTitulo(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const consulta = await Requisicoes.getConsultarTitulo(titulo);
    return consulta;
  }

  /**
   * cancelarTitulo
   *
   * @param {string} titulo
   * @memberof Funci
   */
  static async cancelarTitulo(titulo) {
    if (!validarTipo(titulo, 'string')) return false;
    const requisicao = await Requisicoes.patchAlterarTitulo({ titulo, acao: 'C' });
    return requisicao;
  }

  /**
   * alterarCategoriaTitulo
   *
   * @param {string} titulo
   * @param {number} idCategoria
   * @memberof Funci
   */
  static async alterarCategoriaTitulo(titulo, idCategoria) {
    if (!validarTipo(titulo, 'string')) return false;
    if (!validarTipo(idCategoria, 'number')) return false;
    const requisicao = await Requisicoes.patchAlterarTitulo({ titulo, idCategoria, acao: 'A' });
    return requisicao;
  }

  /**
   * incluirTitulo
   *
   * @param {object} titulo
   * @memberof Funci
   */
  static async incluirTitulo(titulo) {
    if (!validarTipo(titulo, 'object')) return false;
    const requisicao = await Requisicoes.postIncluirTitulo(titulo);
    return requisicao;
  }

  /**
   * listarCategoriasTitulos
   *
   * @memberof Funci
   */
  static async listarCategoriasTitulos() {
    const consulta = await Requisicoes.getListarCategoriasTitulos();
    console.log('consulta', consulta);
    return consulta;
  }

  /**
   * relatorioBoletosPagosPorData
   *
   * @param {string} data
   * @memberof Funci
   */
  static async relatorioBoletosPagosPorData(data) {
    if (!validarTipo(data, 'string')) return false;
    const consulta = await Requisicoes.getRelatorioBoletosPagosData(data);
    return consulta;
  }
}
