import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Badge, Table, Form } from 'react-bootstrap';
import { Alerta, Carregando } from '../../components';
import { withRouter } from 'react-router-dom';
import { Funci } from '../../services';
import { LinkContainer } from 'react-router-bootstrap';
import modelReserva from '../../models/modelReserva';
import { withOrdenar } from '../../utils';

export default withRouter(props => {
  const maxDiasPesqSemSocio = 3; // Qtde máxima de dias para a pesquisa sem sócio
  const maxDiasPesqComSocio = 365; // Qtde máxima de dias para a pesquisa com sócio
  const { history, location } = props;
  const { dataInicial, dataFinal, titulo } = location.state || { dataInicial: '', dataFinal: '', titulo: '' };
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedPesquisa, setValidatedPesquisa] = useState(false);
  const [check, setCheck] = useState({ reservada: true, nUtilizada: false, cancelada: true, efetivada: true });
  const [reservas, setReservas] = useState([]);
  // Estados de Pesquisa
  const [pDataInicial, setPDataInicial] = useState(moment().format('DD/MM/YYYY'));
  const [pDataFinal, setPDataFinal] = useState(moment().format('DD/MM/YYYY'));
  const [pTitulo, setPTitulo] = useState('');

  const handleSubmit = event => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      history.push('/churrasqueiras/reservas', { dataInicial: pDataInicial, dataFinal: pDataFinal, titulo: pTitulo });
    } else {
      setValidatedPesquisa(true);
    }
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      fecharAlerta();
      setReservas([]);
      setCarregando(true);
      try {
        if ((!dataInicial || !dataFinal) && !titulo) return null;
        if (!titulo && moment(dataFinal, 'DD/MM/YYYY').diff(moment(dataInicial, 'DD/MM/YYYY'), 'days') > maxDiasPesqSemSocio) {
          return alertar('danger', `Intervalo máximo de ${maxDiasPesqSemSocio} dias para pesquisa de reservas gerais.`);
        }
        if (titulo && moment(dataFinal, 'DD/MM/YYYY').diff(moment(dataInicial, 'DD/MM/YYYY'), 'days') > maxDiasPesqComSocio) {
          return alertar('danger', `Intervalo máximo de ${maxDiasPesqComSocio} dias para pesquisa de reservas de um sócio.`);
        }
        const fDataIni = moment(dataInicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const fDataFin = moment(dataFinal, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const consulta = await Funci.listarReservas(`${fDataIni}`, `${fDataFin}`, `${titulo || '0'}`); // Garantia que os termos serão passados por string

        if (consulta.erro) return alertar('danger', consulta.erro);
        if (consulta.length === 0) alertar('warning', 'Não foram encontradas reservas com os parametros informados.');
        if (consulta.length > 0)
          setReservas(
            withOrdenar(consulta.map(c => modelReserva(c)))
              .ordenar('status', 'D')
              .ordenar('dataInicial', 'D', 'DD/MM/YYYY'),
          );
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [dataInicial, dataFinal, titulo]);

  const filtrar = reserv => {
    if (reserv.length > 0) {
      return reserv.filter(
        c =>
          (c.status === 'R' && check.reservada) ||
          (c.status === 'C' && check.cancelada) ||
          (c.status === 'E' && check.efetivada) ||
          (c.status === 'N' && check.nUtilizada),
      );
    } else {
      return [];
    }
  };

  return (
    <React.Fragment>
      <Carregando show={carregando} />
      <br />
      {alerta.show ? (
        <Row className="justify-content-between">
          <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} />
        </Row>
      ) : null}
      <Row className="px-2">
        <Col xs="auto">Em construção</Col>
      </Row>
    </React.Fragment>
  );
});
