import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Voltar, Alerta, Carregando, Confirmacao } from '../../components';
import { useParams } from 'react-router-dom';
import { Funci } from '../../services';
import { modelUsuarioSistema, modelPerfil } from '../../models';

export default () => {
  const { uid } = useParams();
  const [atualizacao, setAtualizacao] = useState(0);
  const [carregando, setCarregando] = useState(true);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', onConfirm: () => {}, onCancel: () => {}, show: false });
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [usuario, setUsuario] = useState(null);
  const [perfis, setPerfis] = useState([]);
  const [selecaoPerfis, setSelecaoPerfis] = useState({});

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const atualizarPermissoes = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      setCarregando(true);
      let alteracao;
      if (usuario.status === 'A') {
        alteracao = await Funci.atualizarPemissoesUsuarioSistema({ ...usuario, perfis: selecaoPerfis });
      } else {
        alertar('danger', 'Não é permitido alterar permissões de usuários desativados.');
      }
      if (alteracao.erro) alertar('danger', alteracao.erro);
      if (alteracao === true) {
        alertar('success', 'Permissões alteradas com sucesso.');
        return setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  const alterarStatus = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      setCarregando(true);
      let alteracao;
      if (usuario.status === 'A') {
        alteracao = await Funci.desativarUsuarioSistema(usuario);
      } else if (usuario.status === 'I') {
        alteracao = await Funci.ativarUsuarioSistema(usuario);
      } else {
        alertar('warning', 'Status do usuário não reconhecido.');
      }
      if (alteracao.erro) alertar('danger', alteracao.erro);
      if (alteracao === true) {
        alertar('success', 'Usuário alterado com sucesso.');
        return setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      console.log('e', e);
      alertar('danger', e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const prfs = await Funci.listarPerfisSistema();
        if (prfs.erro) alertar('danger', prfs.erro);
        const modelPrfs = prfs.length > 0 ? prfs.map(p => modelPerfil(p)) : [];
        if (modelPrfs.length > 0) setPerfis(modelPrfs);
        const usr = await Funci.consultarUsuarioSistema(uid);
        if (usr.erro) alertar('danger', usr.erro);
        if (usr.uid) {
          const modelUsr = modelUsuarioSistema(usr);
          setUsuario(modelUsr);
          if (modelPrfs.length > 0) {
            const selecao = {};
            modelPrfs.forEach(p => {
              if (modelUsr.perfis.findIndex(up => up.id === p.id) >= 0) selecao[p.id] = true;
              else selecao[p.id] = false;
            });
            setSelecaoPerfis(selecao);
          }
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [uid, atualizacao]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>
      {usuario ? (
        <Card>
          <Card.Header as="h6">{`Usuário`}</Card.Header>
          <Card.Body>
            <Card.Title>
              {`${usuario.nome}`}
              {usuario.apelido ? ` (${usuario.apelido})` : null}
            </Card.Title>
            <Row>
              <Col>
                <Card.Text>
                  {`Título: ${usuario.titulo}`}
                  <br />
                  {`Email: ${usuario.email}`}
                </Card.Text>
              </Col>
              <Col>
                <Card.Text>
                  {`Super Usuário: ${usuario.superUsuario === true ? 'SIM' : 'NÃO'}`}
                  {/* <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Um Super Usuário tem o poder de administrar outros usuários e as configurações do sistema.
                      </Tooltip>
                    }
                  >
                    <img src="holder.js/20x20?text=i&bg=28a745&fg=FFF" alt="i" />
                  </OverlayTrigger> */}
                  <br />
                  {`Status: ${{ A: 'ATIVO', I: 'INATIVO' }[usuario.status]}`}
                  <br />
                </Card.Text>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Text>Perfis de usuário:</Card.Text>
                <Container className="perfis-rolagem">
                  <Form>
                    <Form.Group controlId="perfis">
                      {perfis.map(p => (
                        <Form.Check
                          key={p.id.toString()}
                          type="checkbox"
                          id={`${p.id}`}
                          disabled={usuario.status !== 'A'}
                          label={p.descricao}
                          checked={selecaoPerfis[p.id]}
                          onChange={event => setSelecaoPerfis({ ...selecaoPerfis, [p.id]: event.target.checked })}
                        />
                      ))}
                    </Form.Group>
                  </Form>
                </Container>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button
                  className="mx-2"
                  variant={'primary'}
                  disabled={usuario.status !== 'A'}
                  onClick={() => confirmar(`Tem certeza que deseja atualizar as permissões do usuário ${usuario.nome}?`, atualizarPermissoes)}
                >
                  ATUALIZAR PERMISSÕES
                </Button>
                <Button
                  className="mx-2"
                  variant={`${usuario.status === 'A' ? 'danger' : 'primary'}`}
                  onClick={() =>
                    confirmar(`Tem certeza que deseja ${usuario.status === 'A' ? 'desativar' : 'ativar'} o usuário ${usuario.nome}?`, alterarStatus)
                  }
                >
                  {`${usuario.status === 'A' ? 'Desabilitar Usuário' : 'Habilitar Usuário'}`}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </Container>
  );
};
