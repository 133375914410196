import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { Voltar, Carregando, Confirmacao } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarValor } from '../../utils';
import { modelProduto } from '../../models';

export default withRouter(props => {
  const { history, location } = props;
  const { id } = useParams();
  const { atualizarProdutos = () => {} } = location.state || {};
  const [atualizar, setAtualizar] = useState(0);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [carregando, setCarregando] = useState(true);
  const [produto, setProduto] = useState(null);

  const statusColors = {
    ATIVO: 'text-primary',
    INATIVO: 'text-secondary',
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const desativarProduto = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.desativarProduto(id);
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        atualizarProdutos();
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const reativarProduto = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.reativarProduto(id);
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        atualizarProdutos();
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const consulta = await Funci.consultarProdutoPorId(id);
        if (consulta.erro) alertar('danger', consulta.erro);
        if (consulta.id) {
          const b = modelProduto(consulta);
          setProduto(b);
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, atualizar]);

  return (
    <>
      <Col as="div" className="rolagem">
        <Container fluid>
          <Carregando show={carregando} />
          <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
          <br />
          <Row className="justify-content-between">
            <Voltar />
          </Row>

          <Card>
            <Card.Header as="h6">Detalhe Produto</Card.Header>
            <Card.Body>
              {produto && (
                <>
                  <Row>
                    <Col>
                      <Card.Text className="fw-bold">{`Nome: ${produto.nome || ''}`}</Card.Text>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Card.Text>{`Descrição: ${produto.descricao || ''}`}</Card.Text>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Card.Text>{`Tipo: ${produto.tipoProdutoLabel || ''}`}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Publico: ${produto.publicoLabel || ''}`}</Card.Text>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Card.Text>{`Categoria: ${produto.categoria || ''}`}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Desconto Automático: ${produto.descontoAutomatico || ''}`}</Card.Text>
                    </Col>
                  </Row>

                  <br />
                  <Row>
                    <Col>
                      <Card.Text>{`Valor: ${produto.valor ? `R$ ${formatarValor(produto.valor)}` : ''}`}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text className="fw-bold">
                        {`Status: `}
                        <span className={`${statusColors[produto.statusLabel] || 'text-secondary'}`}>{`${produto.statusLabel}`}</span>
                      </Card.Text>
                    </Col>
                  </Row>
                </>
              )}
              <hr />
              {produto && (
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                      Voltar
                    </Button>
                    <Button className="mx-2" variant="primary" onClick={() => history.push('/editarproduto', { id })}>
                      Editar
                    </Button>
                    {produto.status === 'A' && (
                      <Button
                        className="mx-2"
                        variant="danger"
                        onClick={() => confirmar('Tem certeza que deseja desativar o produto?', desativarProduto)}
                      >
                        Desativar
                      </Button>
                    )}
                    {produto.status === 'I' && (
                      <Button
                        className="mx-2"
                        variant="success"
                        onClick={() => confirmar('Tem certeza que deseja reativar o produto?', reativarProduto)}
                      >
                        Reativar
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Col>
    </>
  );
});
