import React, { useState } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Voltar, Carregando, Confirmacao } from '../../components';
import { Funci } from '../../services';
import { alertar } from '../../utils';

export default withRouter(props => {
  const { history, location } = props;
  const { tipo, identificador } = location.state;
  const { handleSubmit, register, errors, getValues } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });

  const tipoPessoa = tipo === 'visitante' ? 'Visitante' : { D: 'Dependente', T: 'Titular' }[identificador.tipo] || 'Sócio';

  const onSubmit = async () => {
    confirmar('Confirma o registro da restrição?', registrar);
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const registrar = async () => {
    fecharConfirmacao();
    setCarregando(true);
    try {
      if (!tipo && !identificador.titulo && !identificador.cpf) throw new Error('Pessoa não definida.');
      const objRestricao = {};
      if (tipo === 'visitante') {
        objRestricao.tipoIdentificador = 'cpf';
        objRestricao.identificador = identificador.cpf;
      }
      if (tipo === 'socio') {
        objRestricao.tipoIdentificador = 'titulo';
        objRestricao.identificador = `${identificador.titulo}${identificador.codDependente || ''}`;
      }
      objRestricao.dataRef = moment().format('YYYY-MM-DD');
      objRestricao.tipo = getValues('tipo');

      const registroRestricao = await Funci.incluirRestricao(objRestricao);
      if (registroRestricao.erro) {
        throw registroRestricao.erro;
      } else if (registroRestricao === true) {
        history.replace(`/restricoes`);
        alertar('success', 'Restrição registrada com sucesso.');
      } else {
        throw new Error('Ocorreu um erro inesperado. Por favor, tente novamente.');
      }
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  return (
    <Col as="div" className="rolagem">
      <Container fluid>
        <Carregando show={carregando} />
        <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />

        <br />
        <Row className="justify-content-between">
          <Voltar />
        </Row>

        <Card>
          <Card.Header as="h6">Registro de restrição</Card.Header>
          <Card.Body>
            <Card.Text>{`Tipo: ${tipoPessoa}`}</Card.Text>
            {/* TITULAR OU DEPENDENTE */}
            {(tipoPessoa === 'Titular' || tipoPessoa === 'Dependente') && (
              <Row>
                <Col>
                  <Card.Text>
                    {`Nome: ${identificador.nome} ${identificador.nomeRdzd ? '(' + identificador.nomeRdzd + ')' : ''}`}
                    <br />
                    {`Título: ${identificador.titulo}${identificador.codDependente ? ' / ' + identificador.codDependente : ''}`}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {' '}
                    <br />
                    {`Categoria: ${identificador.categoria}`}
                  </Card.Text>
                </Col>
              </Row>
            )}
            {/* VISITANTE */}
            {tipoPessoa === 'Visitante' && (
              <Row>
                <Col>
                  <Card.Text>{`CPF: ${identificador.cpf}`}</Card.Text>
                </Col>
                <Col>
                  <Card.Text> </Card.Text>
                </Col>
              </Row>
            )}
            <br />
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
              <br />
              <Form.Group as={Col} xs={3} controlId="tipoGroup" className="align-bottom mb-0">
                <Card.Text>{`Tipo de restrição:`}</Card.Text>
                <Form.Control as="select" name="tipo" ref={register({ required: 'Selecione um tipo de restrição a ser registrada.' })}>
                  <option value="">Selecione</option>
                  <option value="BRA">Bloqueio de Reservas pelo App</option>
                  <option value="BCA">Bloqueio de Convites pelo App</option>
                </Form.Control>
                <Card.Text className="text-danger">{errors.tipo && errors.tipo.message}</Card.Text>
              </Form.Group>
              <br />
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                  <Button className="mx-2" variant="success" type="submit">
                    Registrar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </Col>
  );
});
