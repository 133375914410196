import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Table, Form, Container, Tabs, Tab, Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Carregando } from '../../components';
import { Funci } from '../../services';
import { alertar, withOrdenar, unique, formatarCPF } from '../../utils';
import { modelAcessoFisico } from '../../models';

export default withRouter(props => {
  const maxDiasPesqSemSocio = 1; // Qtde máxima de dias para a pesquisa sem sócio
  const maxDiasPesqComSocio = 365; // Qtde máxima de dias para a pesquisa com sócio
  const { history, location } = props;
  const { dataInicial, dataFinal, tipoIdentificador, identificador } = location.state || {
    dataInicial: '',
    dataFinal: '',
    tipoIdentificador: '',
    identificador: '',
  };
  const [carregando, setCarregando] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  const [local, setLocal] = useState('TODOS');
  const [locais, setLocais] = useState([]);
  const [tipo, setTipo] = useState('TODOS');
  const [tipos, setTipos] = useState([]);
  const [acessos, setAcessos] = useState([]);
  // Estados de Pesquisa
  const [pTipoIdentificador, setPTipoIdentificador] = useState('T');
  const [pTitulo, setPTitulo] = useState('');
  const [pCPF, setPCPF] = useState('');

  const onSubmitForm = values => {
    const { dataInicial: pDataInicial, dataFinal: pDataFinal } = values;
    history.push('/acessos-fisicos', {
      dataInicial: pDataInicial,
      dataFinal: pDataFinal,
      tipoIdentificador: pTipoIdentificador,
      identificador: { T: pTitulo, C: pCPF, N: '0' }[pTipoIdentificador],
    });
  };

  useEffect(() => {
    const inicializar = async () => {
      setAcessos([]);
      setCarregando(true);
      try {
        if (!dataInicial || !dataFinal) return null;

        if (tipoIdentificador === 'N' && moment(dataFinal, 'YYYY-MM-DD').diff(moment(dataInicial, 'YYYY-MM-DD'), 'days') > maxDiasPesqSemSocio) {
          return alertar('danger', `Intervalo máximo de ${maxDiasPesqSemSocio} dia(s) para pesquisa de acessos gerais.`);
        }
        if (tipoIdentificador !== 'N' && moment(dataFinal, 'YYYY-MM-DD').diff(moment(dataInicial, 'YYYY-MM-DD'), 'days') > maxDiasPesqComSocio) {
          return alertar('danger', `Intervalo máximo de ${maxDiasPesqComSocio} dia(s) para pesquisa de acessos por Sócio ou Visitante.`);
        }

        const fDataIni = moment(dataInicial, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const fDataFin = moment(dataFinal, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const consulta = await Funci.listarAcessosFisicos(`${fDataIni}`, `${fDataFin}`, `${tipoIdentificador}`, `${identificador || '0'}`); // Garantia que os termos serão passados por string
        if (consulta.erro) return alertar('danger', consulta.erro);
        if (consulta.length === 0) alertar('warning', 'Não foram encontrados acessos com os parametros informados.');
        if (consulta.length > 0) {
          setAcessos(withOrdenar(consulta.map(a => modelAcessoFisico(a))).ordenar('timestamp', 'A', 'DD/MM/YYYY HH:mm:ss'));
          setLocais(
            unique(
              consulta.map(acesso => modelAcessoFisico(acesso).local),
              'nome',
            ),
          );
          setTipos(
            unique(
              consulta.map(acesso => modelAcessoFisico(acesso)),
              'tipoLabel',
            ),
          );
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [dataInicial, dataFinal, tipoIdentificador, identificador]);

  const filtrar = acs => {
    if (acs.length > 0) {
      if (local !== 'TODOS' && tipo !== 'TODOS') return acs.filter(a => a.local.codigo === local && a.tipo === tipo);
      if (local !== 'TODOS') return acs.filter(a => a.local.codigo === local);
      if (tipo !== 'TODOS') return acs.filter(a => a.tipo === tipo);
      return acs;
    } else {
      return [];
    }
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />

      <Tabs defaultActiveKey="acessos" id="uncontrolled-tab-example">
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="acessos" title="Registro de acessos">
          <Row className="px-2">
            <Col className="justify-content-end">
              <Form onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
                <Row className="justify-content-end align-bottom">
                  <Form.Group as={Col} xs={2} controlId="paramDataInicial" className="align-middle mb-0">
                    <Form.Control
                      name="dataInicial"
                      type="date"
                      defaultValue={dataInicial || moment().subtract(maxDiasPesqComSocio, 'days').format('YYYY-MM-DD')}
                      ref={register({
                        required: { value: true, message: 'Informe um data inicial para a consulta.' },
                        validate: value => moment(value).isValid() || 'Data inválida.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.dataInicial && errors.dataInicial.message}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} xs={2} controlId="paramDataFinal" className="align-middle mb-0">
                    <Form.Control
                      name="dataFinal"
                      type="date"
                      defaultValue={dataFinal || moment().format('YYYY-MM-DD')}
                      ref={register({
                        required: { value: true, message: 'Informe um data final para a consulta.' },
                        validate: value => moment(value).isValid() || 'Data inválida.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.dataFinal && errors.dataFinal.message}
                    </Col>
                  </Form.Group>

                  <Form.Group as={Col} xs={2} controlId="tipoIdentificador" className="align-bottom mb-0">
                    <Form.Control as="select" value={pTipoIdentificador} onChange={event => setPTipoIdentificador(event.target.value)}>
                      <option value="T">Sócio</option>
                      <option value="C">Visitante</option>
                      <option value="N">Apenas data</option>
                    </Form.Control>
                  </Form.Group>

                  {pTipoIdentificador === 'T' ? (
                    <Form.Group as={Col} xs={4} controlId="titulo" className="align-bottom mb-0">
                      <Form.Control
                        required
                        type="text"
                        placeholder="Título do associado"
                        value={pTitulo}
                        onChange={event => setPTitulo(event.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">{'Título não informado'}</Form.Control.Feedback>
                    </Form.Group>
                  ) : null}

                  {pTipoIdentificador === 'C' ? (
                    <Form.Group as={Col} xs={4} controlId="CPF" className="align-bottom mb-0">
                      <Form.Control
                        required
                        type="text"
                        placeholder="CPF"
                        value={pCPF}
                        onChange={event => setPCPF(formatarCPF(event.target.value))}
                      />
                      <Form.Control.Feedback type="invalid">{'CPF não informado'}</Form.Control.Feedback>
                    </Form.Group>
                  ) : null}

                  {pTipoIdentificador === 'N' ? (
                    <Form.Group as={Col} xs={4} controlId="Texto" className="align-bottom mb-0">
                      <Form.Control type="text" placeholder="" disabled={true} value="" onChange={() => {}} />
                    </Form.Group>
                  ) : null}

                  <Col xs="auto">
                    <Button type="submit">Buscar</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <Row className="px-2">
            <Col className="justify-content-end">
              <Form inline="true" className="d-flex flex-row justify-content-end pt-3 border-top">
                <Form.Group controlId="local" className="mx-2">
                  <Form.Label>Filtrar tipo de acesso:</Form.Label>
                  <Form.Control className="mr-5" as="select" value={tipo} onChange={event => setTipo(event.target.value)}>
                    {tipos.length > 0 ? <option value="TODOS">Todos</option> : <option value="ERRO">Selecione</option>}
                    {tipos.map(l => (
                      <option key={l.tipo} value={l.tipo}>
                        {l.tipoLabel}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="local" className="mx-2">
                  <Form.Label>Filtrar local de acesso:</Form.Label>
                  <Form.Control className="ml-2" as="select" value={local} onChange={event => setLocal(event.target.value)}>
                    {locais.length > 0 ? <option value="TODOS">Todos</option> : <option value="ERRO">Selecione</option>}
                    {locais.map(l => (
                      <option key={l.codigo} value={l.codigo}>
                        {l.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <br />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="text-center align-middle">#</th>
                <th className="align-middle">Tipo</th>
                <th className="align-middle">Data</th>
                <th className="align-middle">Local</th>
                <th className="align-middle">Nome</th>
                <th className="text-center align-middle">
                  Identificação
                  <br />
                  (Título-Dep/CPF)
                </th>
                <th className="text-center align-middle">Bloqueio?</th>
                <th className="align-middle">Motivo Bloqueio</th>
                <th className="text-center align-middle">
                  Responsável
                  <br />
                  pelo registro
                </th>
              </tr>
            </thead>
            <tbody>
              {filtrar(acessos).map((a, index) => (
                <tr key={`${a.identificador}${a.timestamp}`}>
                  <td className="text-center">{`${index + 1}`}</td>
                  <td>{a.tipoLabel}</td>
                  <td>{a.timestamp}</td>
                  <td>{`${a.local.nome}${a.tipo === 'V' ? ' -> ' + a.destino : ''}`}</td>
                  <td>{a.nome}</td>
                  <td className="text-center">{a.identificador}</td>
                  <td className={`text-center ${a.inBloqueio ? 'text-danger' : ''}`}>{a.inBloqueio ? 'SIM' : 'NÃO'}</td>
                  <td className={`${a.inBloqueio ? 'text-danger' : ''}`}>{a.inBloqueio ? a.descMotivoBloqueio : ''}</td>
                  <td className="text-center">{a.nomeResponsavel}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Container>
  );
});
