/**
 * validarCEP
 *
 * @param {string} cep
 * @returns
 */
const validarCEP = cep => {
  const fTrim = strTexto => `${strTexto}`.replace(/^s+|s+$/g, '');
  // Caso o CEP não esteja nesse formato ele é inválido!
  const objER = /^[0-9]{5}-[0-9]{3}$/;

  const strCEP = fTrim(cep);
  if (strCEP.length > 0) {
    if (objER.test(strCEP)) return true;
  }
  return false;
};

export default validarCEP;
