import moment from 'moment';
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth';
import { getApp } from 'firebase/app';

/**
 *
 *
 * @export
 * @class Auth
 */
export default class Auth {
  static LANGUAGE_CODE = 'pt-br';
  static SESSION_TIME = 900000; // 15 minutos

  /**
   * Cria uma instância singleton da classe Auth.
   * @memberof Auth
   */
  constructor() {
    if (!Auth.instance) {
      this.sessionExpiration = '';
      Auth.instance = this;
    }
    return Auth.instance;
  }

  static refreshTimer() {
    const auth = new Auth();
    if (auth.timer) {
      const newExpiration = moment()
        .add(Auth.SESSION_TIME / 1000, 'seconds')
        .format('DD/MM/YYYY HH:mm:ss');
      if (newExpiration === auth.sessionExpiration) return null;
      auth.clearTimer();
      auth.setTimer();
    }
  }

  setTimer() {
    this.sessionExpiration = moment()
      .add(Auth.SESSION_TIME / 1000, 'seconds')
      .format('DD/MM/YYYY HH:mm:ss');
    this.timer = setTimeout(() => {
      this.sessionExpiration = '';
      this.clearTimer();
      Auth.sair();
    }, Auth.SESSION_TIME);
  }

  clearTimer() {
    this.sessionExpiration = '';
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  /**
   * setLanguage
   *
   * @static
   * @memberof Auth
   */
  static setLanguage() {
    const auth = getAuth();
    auth.languageCode = this.LANGUAGE_CODE;
  }

  /**
   * getUsuario
   *
   * @static
   * @returns {firebase.user}
   * @memberof Auth
   */
  static getUsuario() {
    const auth = getAuth();
    return auth.currentUser;
  }

  /**
   * getIdToken
   *
   * @static
   * @returns {firebase.user}
   * @memberof Auth
   */
  static async getIdToken() {
    const usuario = this.getUsuario();
    if (usuario) {
      return await usuario.getIdToken();
    }
    return null;
  }

  /**
   * getApiInfo
   *
   * @static
   * @returns {firebase.apiKey}
   * @memberof Auth
   */
  static getApiInfo() {
    const app = getApp();
    const { options } = app;
    return options;
  }

  /**
   * onAuth
   *
   * @static
   * @param {function} callback
   * @memberof Auth
   */
  static onAuth(callback) {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      callback(user);
    });
  }

  /**
   * conectar
   *
   * @static
   * @param {string} email
   * @param {string} senha
   * @returns {boolean}
   * @throws {Object{erro}}
   * @memberof Auth
   */
  static async conectarComEmailESenha(email, senha) {
    const erro = {};
    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, senha).catch(error => {
        erro.code = error.code;

        switch (error.code) {
          case 'auth/invalid-email':
            erro.msg = 'Email/senha inválido(a).';
            break;

          case 'auth/user-disabled':
            erro.msg = 'Esta conta está desabilitada no momento.';
            break;

          case 'auth/user-not-found':
            erro.msg = 'Email/senha inválido(a).';
            break;

          case 'auth/wrong-password':
            erro.msg = 'Email/senha inválido(a).';
            break;

          default:
            erro.msg = 'Houve uma falha na comunicação. Por favor, tente novamente.';
        }
      });

      if (erro.code) {
        throw erro;
      }
      return true;
    } catch (e) {
      erro.msg = e.msg || e.errorMessage;
      throw erro;
    }
  }

  /**
   * cadastrarComEmail
   *
   * @static
   * @param {string} nome
   * @param {string} email
   * @param {string} senha
   * @param {string} repetirSenha
   * @returns boolean
   * @memberof Auth
   */
  static async cadastrarComEmail(email, senha, repetirSenha) {
    const erro = {};
    try {
      if (senha !== repetirSenha) {
        erro.msg = 'Senhas não conferem.';
        throw erro;
      }
      const auth = getAuth();
      const cadastro = await createUserWithEmailAndPassword(auth, email, senha).catch(error => {
        erro.code = error.code;

        switch (error.code) {
          case 'auth/invalid-email':
            erro.msg = 'Endereço de email inválido.';
            break;

          case 'auth/email-already-in-use':
            erro.msg = 'Email já cadastrado.';
            break;

          case 'auth/operation-not-allowed':
            erro.msg = 'Operação não permitida no momento. Tente novamente mais tarde.';
            break;

          case 'auth/weak-password':
            erro.msg = 'Senha muito simples. Tente usar uma senha com pelo menos 6 caracteres entre letras e números.';
            break;

          default:
            erro.msg = 'Ocorreu um erro desconhecido ou a aplicação está indisponível neste momento. Por favor, tentar novamente mais tarde.';
        }
      });

      if (!cadastro) {
        erro.code = 1;
        erro.msg = 'Houve um problema com a conexão. Por favor, tente novamente.';
      }
      if (erro.code) {
        throw erro;
      }

      return true;
    } catch (e) {
      erro.msg = e.msg || e.errorMessage;
      throw erro;
    }
  }

  /**
   * recuperarSenha
   *
   * @static
   * @param {string} email
   * @returns
   * @memberof Auth
   */
  static async recuperarSenha(email) {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * sair
   *
   * @static
   * @memberof Auth
   */
  static async sair() {
    const auth = getAuth();
    await signOut(auth);
  }
}
