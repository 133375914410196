/**
 * fomartar CEP
 *
 * @param {string} cep
 * @returns
 */
const formatarCPF = cep => {
  // retira os caracteres indesejados...
  const cepLimpo = `${cep}`.replace(/[^\d]/g, '').substring(0, 8);

  // realizar a formatação...

  // Primeiro bloco não há formatação
  if (cepLimpo.length <= 5) return cepLimpo;

  // Segundo bloco
  if (cepLimpo.length === 6) return cepLimpo.replace(/(\d{5})(\d{1})/, '$1-$2');
  if (cepLimpo.length === 7) return cepLimpo.replace(/(\d{5})(\d{2})/, '$1-$2');

  return cepLimpo.replace(/(\d{5})(\d{3})/, '$1-$2');
};

export default formatarCPF;
