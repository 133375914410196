/* eslint-disable operator-linebreak */
/**
 * validarCPF
 *
 * @param {string} cpfLimpo
 * @returns
 */
const validarCPF = cpf => {
  // retira os caracteres indesejados...
  const cpfLimpo = cpf.replace(/[^\d]/g, '').substring(0, 11);

  if (
    cpfLimpo.length !== 11 ||
    cpfLimpo === '00000000000' ||
    cpfLimpo === '11111111111' ||
    cpfLimpo === '22222222222' ||
    cpfLimpo === '33333333333' ||
    cpfLimpo === '44444444444' ||
    cpfLimpo === '55555555555' ||
    cpfLimpo === '66666666666' ||
    cpfLimpo === '77777777777' ||
    cpfLimpo === '88888888888' ||
    cpfLimpo === '99999999999'
  ) {
    return false;
  }

  let add = 0;

  for (let i = 0; i < 9; i += 1) add += parseInt(cpfLimpo.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpfLimpo.charAt(9), 10)) return false;
  add = 0;
  for (let i = 0; i < 10; i += 1) add += parseInt(cpfLimpo.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpfLimpo.charAt(10), 10)) return false;
  return true;
};

export default validarCPF;
