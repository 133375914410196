import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Badge, Table, Form, Card } from 'react-bootstrap';
import { useParams, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Carregando, Paginacao } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarCPF, formatarValor, validarCPF } from '../../utils';
import { Controller, useForm } from 'react-hook-form';
import { modelBoleto } from '../../models';
import { api } from '../../constants';

export default withRouter(props => {
  const { history, location } = props;
  const { cpf, nascimento } = location.state || {};
  const [carregando, setCarregando] = useState(false);
  const { handleSubmit, register, control, errors } = useForm();
  const [boletos, setBoletos] = useState([]);
  const [records, setRecords] = useState('');

  const onSubmitForm = async values => {
    const { cpf, nascimento } = values;
    console.log('cpf', cpf);
    console.log('nascimento', nascimento);
    history.push(`/consultaboletosocio`, { cpf, nascimento });
  };

  useEffect(() => {
    const inicializar = async () => {
      if (!cpf || !nascimento) return null;
      console.log('inicpf', cpf);
      console.log('ininascimento', nascimento);

      setCarregando(true);
      try {
        const consulta = await Funci.listarBoletosAbertosPorCPF(cpf, nascimento);
        if (consulta.erro) {
          setBoletos([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) {
            setBoletos([]);
            alertar('warning', 'Nenhum boleto encontrado.');
          }
          if (consulta.length > 0) {
            setRecords(consulta[0].records);
            // setBoletos(consulta.map(c => c));
            setBoletos(consulta.map(c => modelBoleto(c)));
          }
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [cpf, nascimento]);

  return (
    <React.Fragment>
      <Carregando show={carregando} />
      <br />
      <Row className="px-2">
        <Col className="justify-content-center">
          <Form noValidate onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
            <Row className="justify-content-center mt-4">
              <Col className="align-middle mb-0">
                <Card.Title className="text-center">Informe seus dados para consultar os boletos em aberto</Card.Title>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Form.Group as={Col} xs={4} controlId="cpf" className="align-middle mb-0">
                <Form.Label className="mt-2 mb-0">
                  CPF <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                </Form.Label>
                <Controller
                  render={props => (
                    <Form.Control
                      type="text"
                      placeholder="CPF"
                      value={props.value}
                      onChange={e => props.onChange(formatarCPF(e.target.value))}
                      ref={props.ref} // wire up the input ref
                    />
                  )}
                  name="cpf"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: 'É necessário informar o CPF.',
                    validate: value => validarCPF(value) || 'CPF inválido.',
                  }}
                />
                <Col as={Card.Text} className="text-danger">
                  {errors.cpf && errors.cpf.message}
                </Col>
              </Form.Group>
              <Form.Group as={Col} xs={3} controlId="nascimento" className="d-flex flex-column align-items-start">
                <Form.Label className="mt-2 mb-0">
                  Data de Nascimento <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                </Form.Label>
                <Form.Control
                  name="nascimento"
                  type="date"
                  ref={register({
                    required: { value: true, message: 'Informe um nascimento para a pesquisa.' },
                    validate: value => moment(value) <= moment().endOf('day') || 'Nascimento deve ser igual ou anterior à data atual.',
                  })}
                />
                <Col as={Card.Text} className="text-danger">
                  {errors.nascimento && errors.nascimento.message}
                </Col>
              </Form.Group>
            </Row>
            <Row className="justify-content-center mt-4">
              <Col xs="auto">
                <Button type="submit">Buscar</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <br />
      <br />
      {boletos && boletos.length > 0 && (
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th className="text-center">Nr Documento</th>
              <th className="text-center">Título</th>
              <th className="text-center">CPF/CNPJ</th>
              <th>Nome</th>
              <th className="text-center">Data Emissão</th>
              <th className="text-center">Data Vencimento</th>
              <th className="text-center">Valor Original</th>
              <th className="text-center">PDF</th>
            </tr>
          </thead>
          <tbody>
            {boletos.map(b => (
              <tr key={`${b.id}`}>
                <td className="text-center">{b.nrDocumento}</td>
                <td className="text-center">{b.titulo || ''}</td>
                <td className="text-center">{b.cpfCnpj}</td>
                <td>{b.nomeRazao}</td>
                <td className="text-center">{b.dtEmissao}</td>
                <td className="text-center">{b.dtVcto}</td>
                <td className="text-center">{b.vlOriginal ? `R$ ${formatarValor(b.vlOriginal)}` : ''}</td>
                <td className="text-center">
                  <Button className="mx-2" variant="primary" size="sm" href={api.BOLETOS_PDF.url(b.id)} target="_BLANK">
                    Visualizar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </React.Fragment>
  );
});
