import React from 'react';
import { Container, Card, Button } from 'react-bootstrap';

export default props => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 page-100">
      <Container className="w-50">
        <Card className="text-center" xs="auto">
          <Card.Body>
            <Card.Title className="text-danger font-weight-bold">Erro ao consultar o servidor da aplicação.</Card.Title>
            <Card.Text>Clique no botão abaixo para atualizar a página:</Card.Text>
            <Button variant="success" onClick={() => window.location.reload()}>
              Recarregar
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
