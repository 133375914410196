import React, { useState, useEffect } from 'react';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Badge, Table, Form, Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Carregando, Paginacao } from '../../components';
import { Funci } from '../../services';
import { alertar } from '../../utils';
import { useForm } from 'react-hook-form';
import { modelTitulo } from '../../models';

export default withRouter(props => {
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const { handleSubmit, register, errors } = useForm();
  const [titulos, setTitulos] = useState([]);
  const [records, setRecords] = useState('');

  const [pesquisa, setPesquisa] = useState({
    criterio: '0',
    pagina: 1,
    qtdePagina: 25,
    campoOrdenacao: '0',
    sentidoOrdenacao: '0',
  });

  const onSubmitForm = async values => {
    const { paramCriterio } = values;
    // Validar quaisquer campos necessários se houver
    // const dIni = moment(paramDataInicial);
    // const dFim = moment(paramDataFinal, 'DD/MM/YYYY');
    // console.log('dIni', dIni);
    // if (dIni > dFim) {
    //   alertar('danger', 'Data final deve ser igual ou superior à data inicial.');
    //   return null;
    // }

    // Comandar a navagação paginada
    setPesquisa({ ...pesquisa, criterio: paramCriterio || '0', pagina: 1, qtdePorPagina: 25, campoOrdenacao: '0', sentidoOrdenacao: '0' });
  };

  const navegar = (crit, pag, qtde, campo, sentido) => {
    setCarregando(true);
    try {
      setPesquisa({
        ...pesquisa,
        criterio: crit,
        pagina: pag,
        qtdePagina: qtde,
        campoOrdenacao: campo,
        sentidoOrdenacao: sentido,
      });
    } catch (e) {
      alertar('error', e.msg || e);
    } finally {
      setCarregando(false);
    }
  };

  const ordenar = (campo, sentido) => {
    navegar(pesquisa.criterio, pesquisa.pagina, pesquisa.qtdePagina, campo, sentido);
  };

  const paginar = pag => {
    navegar(pesquisa.criterio, pag, pesquisa.qtdePagina, pesquisa.campoOrdenacao, pesquisa.sentidoOrdenacao);
  };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const { criterio, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao } = pesquisa;
        const consulta = await Funci.listarTitulosPorCriterio(criterio || '0', pagina, qtdePagina, campoOrdenacao || '0', sentidoOrdenacao || '0');
        if (consulta.erro) {
          setTitulos([]);
          alertar('danger', consulta.erro);
        } else {
          if (consulta.length === 0) {
            setTitulos([]);
            alertar('warning', 'Nenhum título encontrado.');
          }
          if (consulta.length > 0) {
            setRecords(consulta[0].records);
            setTitulos(consulta.map(c => modelTitulo(c)));
          }
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [pesquisa, atualizar]);

  const statusColors = {
    CANCELADO: 'text-danger',
    ATIVO: 'text-primary',
  };
  const statusAssociacaoColors = {
    ATIVO: 'text-primary',
    CANCELADO: 'text-danger',
    PROTOCOLO: 'text-warning',
    SUSPENSO: 'text-secondary',
    INATIVO: 'text-secondary',
  };

  const locationNovo = {
    pathname: '/titulo-editar',
    state: { atualizarTitulos: () => setAtualizar(atualizar + 1) },
  };

  return (
    <React.Fragment>
      <Carregando show={carregando} />
      <br />
      <Row className="px-2">
        <Col xs="auto">
          <LinkContainer to={locationNovo}>
            <Button variant="success" className="mt-2">
              Novo título
            </Button>
          </LinkContainer>
        </Col>
        <Col className="justify-content-end">
          <Form noValidate onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
            <Row className="justify-content-end">
              <Form.Group as={Col} xs={4} controlId="paramCriterio" className="align-middle mb-0">
                <Form.Control
                  name="paramCriterio"
                  type="text"
                  maxLength={50}
                  placeholder="Título ou categoria"
                  ref={register({
                    minLength: { value: 1, message: 'A pesquisa deve conter no mínimo 1 caractere(s).' },
                    maxLength: { value: 50, message: 'A pesquisa deve conter no máximo 50 caracteres.' },
                  })}
                />
                <Col as={Card.Text} className="text-danger">
                  {errors.paramCriterio && errors.paramCriterio.message}
                </Col>
              </Form.Group>
              <Col xs="auto">
                <Button type="submit">Buscar</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th className="text-center">Título</th>
            <th className="text-center">Categoria</th>
            <th className="text-center">Status</th>
            <th>Sócio</th>
            <th className="text-center">Data Associação</th>
            <th className="text-center">Status Associação</th>
            <th className="text-center">Detalhes</th>
          </tr>
        </thead>
        <tbody>
          {titulos.map(c => (
            <tr key={`${c.titulo}`}>
              <td className="text-center">{c.titulo}</td>
              <td className="text-center">{c.categoria}</td>
              <td className={`${statusColors[c.statusLabel] || 'text-secondary'} text-center`}>{c.statusLabel}</td>
              <td>{c.nome}</td>
              <td className="text-center">{c.dataAssociacao}</td>
              {/* <td className="text-center">{c.statusAssociacao}</td> */}
              <td className={`${statusAssociacaoColors[c.statusAssociacaoLabel] || 'text-secondary'} text-center`}>{c.statusAssociacaoLabel}</td>
              <td className="text-center">
                <LinkContainer to={{ pathname: `/titulo-detalhe/${c.titulo}`, state: { atualizarTitulos: () => setAtualizar(atualizar + 1) } }}>
                  <Badge>Clique aqui</Badge>
                </LinkContainer>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-center">
        <Paginacao records={records} qtdePorPagina={pesquisa.qtdePagina} paginaAtual={pesquisa.pagina} paginar={paginar} />
      </div>
    </React.Fragment>
  );
});
