import React, { useEffect, useState } from 'react';
import { Funci } from '../services';
import { validarTipo } from '../utils';
import Carregando from './Carregando';

const Foto = props => {
  const { id = null, height = 80 } = props;
  const [carregando, setCarregando] = useState(true);
  const [src, setSrc] = useState(true);

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.obterFoto(id);
        if (consulta.erro) {
          setSrc(null);
        } else {
          if (validarTipo(consulta, 'string')) {
            setSrc(consulta);
          }
        }
      } catch (e) {
        setSrc(null);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id]);

  if (carregando) return <Carregando show local />;
  return <img src={src || require('../assets/imgs/semfoto.jpg')} height={height} alt="foto sócio" />;
};

export default Foto;
