import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Voltar, Alerta, Carregando } from '../../components';
import { Funci } from '../../services';
import { formatarCPF, validarCPF } from '../../utils';
import { useParams } from 'react-router-dom';
import modelReserva from '../../models/modelReserva';

export default () => {
  const { idReserva } = useParams();
  const [atualizacao, setAtualizacao] = useState(0);
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedConvite, setValidatedConvite] = useState(false);
  const [reserva, setReserva] = useState(null);
  const [convitesDispChurras, setConvitesDispChurras] = useState({ convites: 0, veiculos: 0 });
  const [convite, setConvite] = useState({ nome: '', menor: false, cpf: '', veiculoAutorizado: false, descricaoVeiculo: '', placaVeiculo: '' });

  const handleSubmit = (event, formulario) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) {
      emitirConvite(convite);
    }
    if (formulario === 'convite') setValidatedConvite(true);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const emitirConvite = async conv => {
    fecharAlerta();
    if (conv.nome.length <= 3) return alertar('danger', 'Nome muito curto.');
    if (!validarCPF(conv.cpf) && !conv.menor) return alertar('danger', 'CPF inválido.');
    if (conv.veiculoAutorizado && conv.descricaoVeiculo.length < 3) return alertar('danger', 'Descrição do veículo muito curta.');
    if (conv.veiculoAutorizado && conv.placaVeiculo.length !== 7 && conv.placaVeiculo.length !== 8) return alertar('danger', 'Placa inválida.');
    setCarregando(true);
    try {
      const dataInicial = moment(reserva.dataInicial, 'DD/MM/YYYY').format('DD/MM/YYYY');
      const dataFinal = moment(reserva.dataFinal, 'DD/MM/YYYY').format('DD/MM/YYYY');
      const objConvite = {
        dataInicial,
        dataFinal,
        idReserva: reserva.idReserva,
        titulo: reserva.titulo,
        nomeConvidado: conv.nome,
        menorAdolescente: conv.menor,
        veiculoAutorizado: conv.veiculoAutorizado,
        descricaoVeiculo: conv.veiculoAutorizado ? conv.descricaoVeiculo : null,
        placaVeiculo: conv.veiculoAutorizado ? conv.placaVeiculo : null,
        cpf: conv.cpf,
        tipo: 'C', // C - Churrasqueira
      };

      const emissao = await Funci.emitirConvite(objConvite);

      if (emissao.erro) alertar('danger', emissao.erro);
      if (emissao.idConvite) {
        alertar('success', 'Convite emitido com sucesso.');
        setValidatedConvite(false);
        await setConvite({ nome: '', menor: false, cpf: '', veiculoAutorizado: false, descricaoVeiculo: '', placaVeiculo: '' });
        setAtualizacao(atualizacao + 1);
      } else {
        setCarregando(false);
      }
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setValidatedConvite(false);
        setCarregando(true);
        const reserv = await Funci.consultarReserva(idReserva);
        if (reserv.erro) alertar('danger', reserv.erro);
        if (reserv.idReserva) {
          const cvDisp = await Funci.convitesDispChurrasPorReserva(idReserva.toString(), moment(reserv.dataInicial, 'YYYY-MM-DD').format('YYYY'));
          if (cvDisp.erro) return alertar('danger', cvDisp.erro);
          if (cvDisp.convites || cvDisp.veiculos) setConvitesDispChurras(cvDisp);
          setConvite({ nome: '', menor: false, cpf: '', veiculoAutorizado: false, descricaoVeiculo: '', placaVeiculo: '' });
          setReserva(modelReserva(reserv));
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [idReserva, atualizacao]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>

      <Card>
        <Card.Header as="h6">Novo convite churrasqueira</Card.Header>
        <Card.Body>
          {reserva ? (
            <React.Fragment>
              <Row className="border-bottom pb-2">
                <Col>
                  <Card.Title>{reserva.objeto.nome}</Card.Title>
                </Col>
                <Col xs="auto">
                  <Card.Text>{`Data reservada: ${reserva.dataInicial}`}</Card.Text>
                </Col>
              </Row>
              <br />
              <Row className="font-weight-bold">
                <Col>
                  <Card.Text>Convites disponíveis: {convitesDispChurras.convites}</Card.Text>
                  <Card.Text>Veículos disponíveis: {convitesDispChurras.veiculos}</Card.Text>
                </Col>
                <Col>
                  <Card.Text>Título: {reserva.titulo}</Card.Text>
                </Col>
                <Col xs="auto">
                  <Card.Text>Sócio: {reserva.nome}</Card.Text>
                </Col>
              </Row>
              <br />

              <Form noValidate validated={validatedConvite} onSubmit={event => handleSubmit(event, 'convite')} className="mt-2">
                <Row>
                  <Form.Group as={Col} controlId="nome" className="mb-0">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Nome do convidado"
                      value={convite.nome}
                      onChange={event => setConvite({ ...convite, nome: event.target.value.toUpperCase() })}
                    />
                    <Form.Control.Feedback type="invalid">Nome não informado</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <br />
                <Row>
                  <Form.Group as={Col} controlId="menor" className="mb-0">
                    <Form.Check
                      inline="true"
                      type="checkbox"
                      id="menorCheck"
                      label="Menor entre 9 e 17 anos"
                      checked={convite.menor}
                      onChange={event =>
                        setConvite({
                          ...convite,
                          menor: event.target.checked,
                          veiculoAutorizado: !event.target.checked && convite.veiculoAutorizado, // Menores não podem ter veiculos autorizados
                        })
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="cpf" className="mb-0">
                    <Form.Control
                      required={!convite.menor}
                      type="text"
                      placeholder="CPF do convidado"
                      value={convite.cpf}
                      onChange={event => setConvite({ ...convite, cpf: formatarCPF(event.target.value) })}
                    />
                    <Form.Control.Feedback type="invalid">CPF inválido</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <br />
                <Row>
                  <Form.Group as={Col} controlId="veiculo" className="mb-0" xs="auto">
                    <Form.Check
                      inline="true"
                      type="checkbox"
                      id="veiculoCheck"
                      label="Autorizar entrada de veículo"
                      checked={convite.veiculoAutorizado || false}
                      onChange={event =>
                        setConvite({
                          ...convite,
                          veiculoAutorizado: event.target.checked && convitesDispChurras.veiculos > 0,
                          menor: !(event.target.checked && convitesDispChurras.veiculos > 0) && convite.menor,
                        })
                      }
                    />
                  </Form.Group>
                  {convite.veiculoAutorizado ? (
                    <React.Fragment>
                      <Form.Group as={Col} controlId="veiculoDescricao" className="mb-0">
                        <Form.Control
                          required={convite.veiculoAutorizado}
                          type="text"
                          placeholder="Descrição do veículo"
                          value={convite.descricaoVeiculo}
                          onChange={event => setConvite({ ...convite, descricaoVeiculo: event.target.value.toUpperCase() })}
                        />
                        <Form.Control.Feedback type="invalid">Descrição inválida</Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="placaVeiculo" className="mb-0" xs={2}>
                        <Form.Control
                          required={convite.veiculoAutorizado}
                          type="text"
                          maxLength={8}
                          placeholder="Placa do veículo"
                          value={convite.placaVeiculo}
                          onChange={event => setConvite({ ...convite, placaVeiculo: event.target.value.toUpperCase() })}
                        />
                        <Form.Control.Feedback type="invalid">Placa inválida</Form.Control.Feedback>
                      </Form.Group>
                    </React.Fragment>
                  ) : null}
                </Row>
                <br />
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Voltar className="mx-2" title="Voltar" />
                    <Button type="submit" className="mx-2" variant="success" disabled={!convitesDispChurras.convites}>
                      Emitir convite
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          ) : (
            <Card.Text>Nenhuma informação para exibir.</Card.Text>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};
