import moment from 'moment';

/**
 * @method modelChurrasqueira
 * @description Modelo padrão de objeto Churrasqueira
 * @param {string} idChurrasqueira - id da Churrasqueira
 * @param {string} categoria - tipo de objeto de churrasqueira [N: Normal, E: Especial]
 * @param {string} categoriaLabel - Label para apresentação da categoria. Derivado do campo acima.
 * @param {string} nome - nome da churrasqueira
 * @param {number} capacidade - Capacidade da churrasqueiras.
 * @param {string} status - status atual da churrasqueira [A: Ativa, I: Inativa]
 * @param {string} statusLabel - Label para apresentação do status. Derivado de cálculo.
 * @param {string} habilitadoApp - Status se a churrasqueira está habilitada para reservas pelo App.
 * @param {string} habilitadoAppLabel - Label para apresentação do habilitadoApp. Derivado de cálculo.
 * @param {date} timestamp - data da criação do registro
 * @returns {object} Objeto de Churrasqueira formatado
 */
export default ({ idChurrasqueira, categoria = 'N', nome, capacidade = 0, status = 'A', habilitado_app = 'N', timestamp }) => {
  const statusLabels = {
    A: 'ATIVA',
    I: 'INATIVA',
  };
  const statusLabel = statusLabels[status] || '';
  const categoriaLabels = {
    N: 'NORMAL',
    E: 'ESPECIAL',
  };
  const categoriaLabel = categoriaLabels[categoria] || '';

  return {
    idChurrasqueira: parseInt(idChurrasqueira, 10),
    categoria: categoria || '',
    categoriaLabel,
    nome: nome.toString(),
    capacidade: parseInt(capacidade, 10),
    status: status.toString(),
    statusLabel,
    habilitadoApp: habilitado_app === 'S' || false,
    habilitadoAppLabel: habilitado_app === 'S' ? 'SIM' : 'NÃO',
    timestamp: timestamp ? `${moment(timestamp).format('DD/MM/YYYY HH:MM:SS')}` : null,
  };
};
