import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Voltar, Confirmacao, Carregando, ViaCep } from '../../components';
import { alertar, formatarCEP, formatarCPF, validarCEP, validarCEPNumerico, validarCPF, validarEmail } from '../../utils';
import { ufs } from '../../constants';
import { Funci } from '../../services';

export default withRouter(props => {
  const { history } = props;
  const { handleSubmit, register, setValue, errors, control, reset, watch } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [cep, setCep] = useState(null);

  const onSubmit = dados => {
    // Verificação dos campos obrigatórios
    console.log('dados', dados);
    // Montar o objeto de boleto
    confirmar('Tem certeza que deseja emitir o boleto com os dados preenchidos?', () => cadastrarPessoa(dados));
  };

  const cadastrarPessoa = async dados => {
    try {
      setCarregando(true);
      fecharConfirmacao();

      const item = new FormData();
      item.append('foto', dados.foto[0]);
      Object.keys(dados).map(d => item.append(d, dados[d]));

      const pessoa = await Funci.incluirPessoa(item);
      if (pessoa.id) {
        history.push(`/detalhecadastro/${pessoa.id}`);
      } else if (pessoa.erro) {
        alertar('danger', pessoa.erro);
      } else {
        alertar('error', 'Erro desconhecido ao cadastrar pessoa.');
      }
    } catch (error) {
      alertar('danger', error);
    } finally {
      setCarregando(false);
    }
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const pesquisarCep = () => {
    return null;
  };

  useEffect(() => {
    setCep(watch('enderecoCep', null));
  }, [watch('enderecoCep', null)]);

  useEffect(() => {
    const funci = new Funci();
    const ambiente = funci.getAmbiente();
    if (ambiente === 'LOCAL') {
      setValue('nome', 'CONRADO DE MOURA DE OLIVEIRA');
      setValue('cpf', '336.604.878-64');
      setValue('genero', 'MASCULINO');
      setValue('nascimento', '1985-10-29');
      setValue('estadoCivil', 'CASADO(A)');
      setValue('naturalidade', 'BRASILEIRA');
      setValue('email', 'conradosig@gmail.com');
      setValue('enderecoTipo', 'RESIDENCIAL');
      setValue('enderecoLogradouro', 'AV. DAS ARAUCÁRIAS');
      setValue('enderecoNumero', '4155');
      setValue('enderecoUF', 'DF');
      setValue('enderecoCep', '71936-250');
    }
  }, []);

  return (
    <Container fluid>
      <br />
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      <Card>
        <Card.Header as="h6">Novo cadastro de pessoa</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
            <Row className="justify-content-between">
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="nome">
                      <Form.Label className="mt-2 mb-0">
                        Nome Completo <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        name="nome"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar um nome para a pessoa.' })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.nome && errors.nome.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="nomeReduzido">
                      <Form.Label className="mt-2 mb-0">Nome Reduzido</Form.Label>
                      <br />
                      <Form.Control type="text" name="nomeReduzido" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.nomeReduzido && errors.nomeReduzido.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="3">
                    <Form.Group controlId="cpf" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        CPF <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Controller
                        render={props => (
                          <Form.Control
                            type="text"
                            value={props.value}
                            onChange={e => props.onChange(formatarCPF(e.target.value))}
                            ref={props.ref} // wire up the input ref
                          />
                        )}
                        name="cpf"
                        defaultValue=""
                        control={control}
                        rules={{
                          required: 'É necessário informar o CPF do sacado.',
                          validate: value => validarCPF(value) || 'CPF inválido.',
                        }}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.cpf && errors.cpf.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="genero" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Gênero <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select
                        aria-label="Gênero"
                        name="genero"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar o gênero da pessoa.' })}
                      >
                        <>
                          <option value="">{''}</option>
                          <option key="F" value="FEMININO">
                            FEMININO
                          </option>
                          <option key="M" value="MASCULINO">
                            MASCULINO
                          </option>
                          <option key="O" value="OUTROS">
                            OUTROS
                          </option>
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.genero && errors.genero.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="rg">
                      <Form.Label className="mt-2 mb-0">RG</Form.Label>
                      <br />
                      <Form.Control type="text" name="rg" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.rg && errors.rg.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="rgOrgao">
                      <Form.Label className="mt-2 mb-0">Órgão Emissor</Form.Label>
                      <br />
                      <Form.Control type="text" name="rgOrgao" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.rgOrgao && errors.rgOrgao.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="rgUF">
                      <Form.Label className="mt-2 mb-0">UF Emissor</Form.Label>
                      <br />
                      <Form.Control type="text" name="rgUF" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.rgUF && errors.rgUF.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="3">
                    <Form.Group controlId="nascimento">
                      <Form.Label className="mt-2 mb-0">
                        Data de Nascimento <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Control
                        name="nascimento"
                        type="date"
                        ref={register({
                          required: { value: true, message: 'Informe a data de nascimento da pessoa.' },
                          validate: value => moment(value) <= moment().endOf('day') || 'A data de nascimento não pode ser futura.',
                        })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.nascimento && errors.nascimento.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="estadoCivil" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Estado Civil <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select
                        aria-label="Estado Civil"
                        name="estadoCivil"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar o estado civil atual da pessoa.' })}
                      >
                        <>
                          <option value="">{''}</option>
                          <option key="S" value="SOLTEIRO(A)">
                            SOLTEIRO(A)
                          </option>
                          <option key="SJ" value="SEPARADO(A)">
                            SEPARADO(A)
                          </option>
                          <option key="U" value="UNIÃO ESTÁVEL">
                            UNIÃO ESTÁVEL
                          </option>
                          <option key="C" value="CASADO(A)">
                            CASADO(A)
                          </option>
                          <option key="D" value="DIVORCIADO(A)">
                            DIVORCIADO(A)
                          </option>
                          <option key="V" value="VIÚVO(A)">
                            VIÚVO(A)
                          </option>
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.estadoCivil && errors.estadoCivil.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="nomeMae">
                      <Form.Label className="mt-2 mb-0">Nome da Mãe</Form.Label>
                      <br />
                      <Form.Control type="text" name="nomeMae" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.nomeMae && errors.nomeMae.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="nomePai">
                      <Form.Label className="mt-2 mb-0">Nome do Pai</Form.Label>
                      <br />
                      <Form.Control type="text" name="nomePai" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.nomePai && errors.nomePai.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="naturalidade">
                      <Form.Label className="mt-2 mb-0">
                        Naturalidade <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        name="naturalidade"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar a naturalidade da pessoa.' })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.naturalidade && errors.naturalidade.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="3">
                    <Form.Group controlId="grauInstrucao" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">Grau de instrução</Form.Label>
                      <Form.Select aria-label="Grau de instrução" name="grauInstrucao" defaultValue={''} ref={register({ required: false })}>
                        <>
                          <option value="">{''}</option>
                          <option key={0} value="ANALFABETO">
                            ANALFABETO
                          </option>
                          <option key={1} value="ENSINO FUNDAMENTAL">
                            ENSINO FUNDAMENTAL
                          </option>
                          <option key={2} value="ENSINO MÉDIO">
                            ENSINO MÉDIO
                          </option>
                          <option key={3} value="ENSINO SUPERIOR">
                            ENSINO SUPERIOR
                          </option>
                          <option key={4} value="PÓS GRADUAÇÃO">
                            PÓS GRADUAÇÃO
                          </option>
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.grauInstrucao && errors.grauInstrucao.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="foto" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Foto (Formato JPEG com no máximo 500KB) <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept=".jpg,.jpeg,image/jpeg"
                        name="foto"
                        defaultValue={''}
                        ref={register({ required: 'É necessário incluir uma foto do sócio.' })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.foto && errors.foto.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Form.Group controlId="email">
                      <Form.Label className="mt-2 mb-0">
                        Email <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        name="email"
                        defaultValue={''}
                        ref={register({
                          required: 'É necessário informar o email da pessoa.',
                          validade: value => validarEmail(value) || 'Email inválido.',
                        })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.email && errors.email.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-start">
                  <Col xs="3">
                    <Form.Group controlId="enderecoTipo" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Tipo de endereço <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select
                        aria-label="Tipo de endereço"
                        name="enderecoTipo"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar o tipo de endereço da pessoa.' })}
                      >
                        <>
                          <option value="">{''}</option>
                          <option key="R" value="RESIDENCIAL">
                            RESIDENCIAL
                          </option>
                          <option key="C" value="COMERCIAL">
                            COMERCIAL
                          </option>
                          <option key="O" value="OUTROS">
                            OUTROS
                          </option>
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoTipo && errors.enderecoTipo.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="2">
                    <Form.Group controlId="enderecoCep" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        CEP <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Controller
                        render={props => (
                          <Form.Control
                            type="text"
                            placeholder="CEP"
                            value={props.value}
                            onChange={e => props.onChange(formatarCEP(e.target.value))}
                            ref={props.ref} // wire up the input ref
                          />
                        )}
                        name="enderecoCep"
                        defaultValue=""
                        control={control}
                        rules={{
                          required: 'É necessário informar o CEP do sacado.',
                          validate: value => validarCEP(value) || 'CEP inválido.',
                        }}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoCep && errors.enderecoCep.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <ViaCep cep={cep} lazy>
                    {({ data, loading, error, fetch }) => {
                      if (loading) {
                        setValue('enderecoLogradouro', '');
                        setValue('enderecoNumero', '');
                        setValue('enderecoComplemento', '');
                        setValue('enderecoBairro', '');
                        setValue('enderecoCidade', '');
                        setValue('enderecoUF', '');
                        return (
                          <Col className="d-flex align-items-end">
                            <Button disabled>Pesquisando...</Button>
                          </Col>
                        );
                      }
                      if (error || (data && data.erro)) {
                        return (
                          <>
                            <Col xs="1" className="d-flex align-items-end">
                              <Button onClick={fetch}>Pesquisar</Button>
                            </Col>
                            <Col className="d-flex align-items-end">
                              <Card.Text className="text-danger">CEP Não encontrado.</Card.Text>
                            </Col>
                          </>
                        );
                      }
                      if (data) {
                        setValue('enderecoLogradouro', data.logradouro || '');
                        setValue('enderecoComplemento', data.complemento || '');
                        setValue('enderecoBairro', data.bairro || '');
                        setValue('enderecoCidade', data.localidade || '');
                        setValue('enderecoUF', data.uf);
                      }
                      return (
                        <Col className="d-flex align-items-end">
                          <Button onClick={() => (validarCEPNumerico(watch('enderecoCep', 0)) ? fetch() : alertar('warning', 'CEP inválido'))}>
                            Pesquisar
                          </Button>
                        </Col>
                      );
                    }}
                  </ViaCep>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="enderecoLogradouro">
                      <Form.Label className="mt-2 mb-0">
                        Logradouro <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        name="enderecoLogradouro"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar o logradouro da pessoa.' })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoLogradouro && errors.enderecoLogradouro.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="2">
                    <Form.Group controlId="enderecoNumero">
                      <Form.Label className="mt-2 mb-0">
                        Número <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <br />
                      <Form.Control
                        type="text"
                        name="enderecoNumero"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar o número do endereço da pessoa.' })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoNumero && errors.enderecoNumero.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="enderecoComplemento">
                      <Form.Label className="mt-2 mb-0">Complemento</Form.Label>
                      <br />
                      <Form.Control type="text" name="enderecoComplemento" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoComplemento && errors.enderecoComplemento.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="enderecoBairro">
                      <Form.Label className="mt-2 mb-0">Bairro</Form.Label>
                      <br />
                      <Form.Control type="text" name="enderecoBairro" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoBairro && errors.enderecoBairro.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="enderecoCidade">
                      <Form.Label className="mt-2 mb-0">Cidade</Form.Label>
                      <br />
                      <Form.Control type="text" name="enderecoCidade" defaultValue={''} ref={register({ required: false })} />
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoCidade && errors.enderecoCidade.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="1">
                    <Form.Group controlId="enderecoUF" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        UF <Form.Label className="mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select
                        aria-label="UF"
                        name="enderecoUF"
                        defaultValue={''}
                        ref={register({ required: 'É necessário informar UF da pessoa.' })}
                      >
                        <>
                          <option value="">{''}</option>
                          {ufs.map(u => (
                            <option key={`${u.value}`} value={`${u.value}`}>{`${u.value}`}</option>
                          ))}
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.enderecoUF && errors.enderecoUF.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="info" onClick={() => reset()}>
                  Limpar
                </Button>
                <Button className="mx-2" variant="success" type="submit">
                  Cadastrar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
});
