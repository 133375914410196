import React from 'react';
import { Container, Card } from 'react-bootstrap';

export default () => {
  return (
    <Container fluid className="h-100">
      <br />
      <Card className="text-center">
        <Card.Body>
          <Card.Title>Minas Brasília Tênis Clube</Card.Title>
          <Card.Text>Selecione um item do menu.</Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};
