import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { Col, Container, Card, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Alerta, Carregando } from '../../components';
import { Funci } from '../../services';
import modelConvite from '../../models/modelConvite';
import modelReserva from '../../models/modelReserva';

export default () => {
  const { idConvite } = useParams();
  const [carregando, setCarregando] = useState(true);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', onConfirm: () => {}, onCancel: () => {}, show: false });
  const [convite, setConvite] = useState(null);
  const [reserva, setReserva] = useState(null);

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        setTimeout(async () => {
          const conv = await Funci.consultarConvite(idConvite);
          if (conv.erro) alertar('danger', conv.erro);
          if (conv.idConvite) {
            if (conv.tipo === 'C') {
              const reserv = await Funci.consultarReserva(conv.idReserva.toString());
              if (conv.erro) return alertar('danger', conv.erro);
              if (reserv.idReserva) setReserva(modelReserva(reserv));
            }
            setConvite(modelConvite(conv));
          }
        }, 1000);
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [idConvite]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Row className="justify-content-between">
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>
      {convite ? (
        <Card>
          <Card.Header as="h6">{`Convite ${convite.tipoLabel}`}</Card.Header>
          <Card.Body>
            <Card.Title>{convite.nomeConvidado}</Card.Title>
            <Row>
              {/* ******************************************************** */}
              {/* AVULSO */}
              {/* ******************************************************** */}
              {convite.tipo === 'A' ? (
                <React.Fragment>
                  <Col>
                    <Card.Text>
                      {`CPF: ${convite.cpf}`}
                      <br />
                      {`Tipo: ${convite.tipoLabel}`}
                    </Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>
                      {`Menor entre 9 e 17 anos? ${convite.menorAdolescente ? 'Sim' : 'Não'}`}
                      <br />
                      {`Data permitida: ${convite.dataInicial} a ${convite.dataFinal}`}
                    </Card.Text>
                  </Col>
                </React.Fragment>
              ) : null}
              {/* ******************************************************** */}
              {/* CHURRASQUEIRA */}
              {/* ******************************************************** */}
              {convite.tipo === 'C' ? (
                <React.Fragment>
                  <Col>
                    <Card.Text>
                      {`CPF: ${convite.cpf}`}
                      <br />
                      {`Menor entre 9 e 17 anos? ${convite.menorAdolescente ? 'Sim' : 'Não'}`}
                      <br />
                      {`${reserva.objeto.nome}`}
                      <br />
                      {`Data permitida: ${convite.dataInicial}`}
                    </Card.Text>
                  </Col>
                  <Col>
                    <Card.Text>
                      {`Veículo autorizado? ${convite.veiculoAutorizado ? 'Sim' : 'Não'}`}
                      <br />
                      {`Descrição veículo: ${convite.descricaoVeiculo || ''}`}
                      <br />
                      {`Placa veículo: ${convite.placaVeiculo || ''}`}
                    </Card.Text>
                  </Col>
                </React.Fragment>
              ) : null}
              {/* ******************************************************** */}
              <Col>
                <QRCode includeMargin id="svg-qrcode" renderAs="svg" value={convite.qrcode || ''} size={170} level="M" />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </Container>
  );
};
