import moment from 'moment';

import { formatarCPF } from '../utils';

/**
 * @method modelRestricao
 * @description Modelo padrão de objeto da classe Restricao
 * @param {string} dataRef - data de referência da restrição
 * @param {string} tipo - tipo da restrição
 * @param {string} tipoLabel - Label do tipo da restrição. Derivado do campo anterior caso venha em branco.
 * @param {string} identificador - identificador do alvo da restrição
 * @param {string} tipoIdentificador - tipo de identificador
 * @param {string} tipoIdentificadorLabel - Label do tipo de identificador. Derivado do campo anterior.
 * @param {string} nome - nome do alvo da restrição
 * @param {timestamp} dataFim - data fim de validade da restrição
 * @param {timestamp} timestamp - timestamp do registro
 * @param {string} status - status da restrição
 * @param {string} statusLabel - status da restrição. Derivado do campo anterior.
 * @param {string} uidResponsavel - responsavel pelo registro
 * @param {string} apelidoResponsavel - apelido responsavel pelo registro
 * @param {string} nomeResponsavel - nome responsavel pelo registro
 * @param {string} uidBaixa - responsavel pela baixa do registro
 * @param {string} apelidoBaixa - apelido responsavel pela baixa do registro
 * @param {string} nomeBaixa - nome responsavel pela baixa do registro
 * @returns {object} Objeto de Usuário formatado
 */
export default ({
  dataRef,
  tipo,
  tipoLabel = '',
  identificador,
  tipoIdentificador,
  tipoIdentificadorLabel,
  nome = '',
  dataFim,
  timestamp,
  status,
  uidResponsavel,
  apelidoResponsavel,
  nomeResponsavel,
  uidBaixa,
  apelidoBaixa,
  nomeBaixa,
}) => {
  const getTipoLabel = () => {
    const tipos = {
      F: 'FINANCEIRA',
      BRA: 'BLOQUEIO RESERVAS APP',
      BCA: 'BLOQUEIO CONVITES APP',
    };
    return tipos[tipo] || '';
  };
  const getTipoIdentificadorLabel = () => {
    const tipos = {
      idPessoa: 'idPessoa (Aclon)',
      titulo: 'Título',
      cpf: 'CPF',
    };
    return tipos[tipoIdentificador] || '';
  };
  const getStatus = () => {
    if (status === 'A') {
      if (!dataFim || moment(dataFim) < moment()) return 'ATIVA';
      return 'VENCIDA';
    }
    if (status === 'I') return 'INATIVA';
    if (status === 'C') return 'CANCELADA';
    return 'VENCIDA';
  };

  return {
    dataRef,
    tipo,
    tipoLabel,
    identificador: tipo === 'cpf' ? formatarCPF(identificador) : identificador,
    tipoIdentificador,
    tipoIdentificadorLabel,
    nome,
    dataFim: dataFim && moment(dataFim).format('DD/MM/YYYY HH:mm:ss'),
    timestamp: timestamp && moment(timestamp).format('DD/MM/YYYY HH:mm:ss'),
    status,
    statusLabel: getStatus(),
    uidResponsavel,
    nomeResponsavel: apelidoResponsavel || nomeResponsavel || 'SISTEMA',
    uidBaixa,
    nomeBaixa: uidBaixa ? apelidoBaixa || nomeBaixa || dataFim || 'SISTEMA' : '',
  };
};
