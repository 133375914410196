import React, { useState } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Voltar, Alerta, Carregando } from '../../components';
import { Funci } from '../../services';
import { formatarCPF, validarCPF } from '../../utils';

export default () => {
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedTitulo, setValidatedTitulo] = useState(false);
  const [validatedConvite, setValidatedConvite] = useState(false);
  const [titulo, setTitulo] = useState('');
  const [socio, setSocio] = useState({});
  const [convite, setConvite] = useState({ nome: '', menor: false, cpf: '' });

  const handleSubmit = (event, formulario) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    form.checkValidity();
    if (formulario === 'titulo') setValidatedTitulo(true);
    if (formulario === 'convite') setValidatedConvite(true);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const consultarSocio = async (tituloSocio, sucesso = false) => {
    if (!sucesso) {
      fecharAlerta();
    } else {
      setConvite({ nome: '', menor: false, cpf: '' });
      setValidatedConvite(false);
    }
    setSocio({});
    setCarregando(true);
    try {
      if (!tituloSocio) return null;
      const consultaSocio = await Funci.convitesDispAvulsos(tituloSocio);
      if (consultaSocio.erro) {
        alertar('danger', consultaSocio.erro);
      } else {
        setSocio(consultaSocio);
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const emitirConvite = async conv => {
    fecharAlerta();
    if (conv.nome.length <= 3) return alertar('danger', 'Nome muito curto.');
    if (!validarCPF(conv.cpf) && !conv.menor) return alertar('danger', 'CPF inválido.');
    setCarregando(true);
    try {
      const dataInicial = moment().format('DD/MM/YYYY');
      const dataFinal = moment().add(30, 'days').format('DD/MM/YYYY');
      const objConvite = {
        dataInicial,
        dataFinal,
        titulo: socio.titulo,
        nomeConvidado: conv.nome,
        menorAdolescente: conv.menor,
        cpf: conv.cpf,
        tipo: 'A',
      };

      const emissao = await Funci.emitirConvite(objConvite);

      if (emissao.erro) alertar('danger', emissao.erro);
      if (emissao.idConvite) {
        alertar('success', 'Convite emitido com sucesso.');
        await consultarSocio(socio.titulo, true);
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>

      <Card>
        <Card.Header as="h6">Novo convite avulso</Card.Header>
        <Card.Body>
          <Row className="border-bottom pb-2">
            <Col>
              <Form noValidate validated={validatedTitulo} onSubmit={event => handleSubmit(event, 'titulo')} className="mt-2">
                <Row className="justify-content-end">
                  <Form.Group as={Col} xs={4} controlId="titulo" className="align-middle mb-0">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Título do associado"
                      value={titulo}
                      onChange={event => setTitulo(event.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Título não informado</Form.Control.Feedback>
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit" onClick={() => consultarSocio(titulo)}>
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          {socio.titulo ? (
            <React.Fragment>
              <Row className="font-weight-bold">
                <Col>Convites disponíveis: {socio.convitesAvulsosDisponiveis}</Col>
                <Col>Título: {socio.titulo}</Col>
                <Col xs="auto">Sócio: {socio.nome}</Col>
              </Row>
              <br />

              <Form noValidate validated={validatedConvite} onSubmit={event => handleSubmit(event, 'convite')} className="mt-2">
                <Row>
                  <Form.Group as={Col} controlId="nome" className="mb-0">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Nome do convidado"
                      value={convite.nome}
                      onChange={event => setConvite({ ...convite, nome: event.target.value.toUpperCase() })}
                    />
                    <Form.Control.Feedback type="invalid">Nome não informado</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <br />
                <Row>
                  <Form.Group as={Col} controlId="menor" className="mb-0">
                    <Form.Check
                      inline="true"
                      type="checkbox"
                      id="menorCheck"
                      label="Menor entre 9 e 17 anos"
                      checked={convite.menor}
                      onChange={event => setConvite({ ...convite, menor: event.target.checked })}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="cpf" className="mb-0">
                    <Form.Control
                      required={!convite.menor}
                      type="text"
                      placeholder="CPF do convidado"
                      value={convite.cpf}
                      onChange={event => setConvite({ ...convite, cpf: formatarCPF(event.target.value) })}
                    />
                    <Form.Control.Feedback type="invalid">CPF inválido</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <br />
                <Row>
                  <Form.Group as={Col} controlId="validade" className="mb-0">
                    <span className="font-weight-bold ">Validade: 30 dias</span>
                  </Form.Group>
                </Row>
                <br />
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Voltar className="mx-2" title="Voltar" />
                    <Button
                      type="submit"
                      className="mx-2"
                      variant="success"
                      disabled={!socio.convitesAvulsosDisponiveis}
                      onClick={() => emitirConvite(convite)}
                    >
                      Emitir convite
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          ) : null}
        </Card.Body>
      </Card>
    </Container>
  );
};
