import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { Voltar, Alerta, Carregando } from '../../components';
import { Funci } from '../../services';

export default withRouter(props => {
  const { history, location } = props;
  const { socio } = location.state;
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedData, setValidatedData] = useState(false);
  const [data, setData] = useState('');
  const [datas, setDatas] = useState([]);

  const handleSubmit = (event, formulario) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    form.checkValidity();
    if (formulario === 'data') setValidatedData(true);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      fecharAlerta();
      setDatas([]);
      setCarregando(true);
      try {
        const consultaDatas = await Funci.listarReservasDatasDisp();

        if (consultaDatas.erro) return alertar('danger', consultaDatas.erro);
        if (!consultaDatas.datasDisponiveis) return alertar('danger', 'Não foi possível consultar as datas disponíveis para reserva.');
        if (consultaDatas.datasDisponiveis.length === 0)
          return alertar('danger', 'Não existem datas disponíveis dentro do período permitido de reserva.');
        setDatas(consultaDatas.datasDisponiveis);
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, []);

  const navigateNext = () => {
    if (!socio.titulo) return alertar('danger', 'Sócio não selecionado.');
    if (!data) return alertar('danger', 'Data não selecionada.');
    history.push('/novareservapasso3', { socio, data });
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>

      <Card>
        <Card.Header as="h6">Nova reserva</Card.Header>
        <Card.Body>
          <Row className="border-bottom pb-2">
            {socio.titulo ? (
              <React.Fragment>
                <Col className="d-flex align-items-center">
                  <Card.Text>Título: {socio.titulo}</Card.Text>
                </Col>
                <Col className="d-flex align-items-center" xs="auto">
                  <Card.Text>Sócio: {socio.nome}</Card.Text>
                </Col>
              </React.Fragment>
            ) : (
              <Card.Text>Sócio não foi carregado corretamente.</Card.Text>
            )}
          </Row>
          <br />
          <Form.Label>Selecione uma data para reserva:</Form.Label>
          <Row className="pb-2">
            <Col xs="auto">
              <Form noValidate validated={validatedData} onSubmit={event => handleSubmit(event, 'data')} className="mt-2">
                <Row className="justify-content-start">
                  <Form.Group as={Col} xs="auto" controlId="titulo" className="align-middle mb-0">
                    <Form.Control as="select" value={data} defaultValue={''} onChange={event => setData(event.target.value)}>
                      <option value={''}>Selecione...</option>
                      {datas.map(c => (
                        <option key={c.toString()} value={c}>
                          {c}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Data não selecionada</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="d-flex justify-content-center">
              <Button variant="success" onClick={() => navigateNext()} disabled={!data}>
                Avançar
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
});
