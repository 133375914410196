import { useTimer } from 'react-timer-hook';

const Timer = ({ expiryTimestamp }) => {
  const { seconds, minutes } = useTimer({ expiryTimestamp });
  return (
    <div className="text-white fw-bold">
      <span>Tempo restante de sessão: </span>
      <span>
        {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds}
      </span>
    </div>
  );
};

export default Timer;
