import React, { useState } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';

const ConfirmacaoEmail = props => {
  const { show = false, onConfirm = () => {}, onCancel = () => {} } = props;
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = (event, formulario) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity()) onConfirm(email);
    if (formulario === 'email') setValidatedEmail(true);
  };

  if (show) {
    return (
      <Modal centered animation={false} backdrop="static" show={show} keyboard={false} className="bg-invisible">
        <Modal.Header closeButton onHide={onCancel}>
          <Modal.Title>Informe um email para envio:</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validatedEmail} onSubmit={event => handleSubmit(event, 'email')} className="mt-2">
          <Modal.Body>
            <Form.Group as={Col} xs="auto" controlId="email" className="align-middle w-100 mb-0">
              <Form.Control required type="email" placeholder="email@provedor.com" value={email} onChange={event => setEmail(event.target.value)} />
              <Form.Control.Feedback type="invalid">Email inválido</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={onCancel}>
              Cancelar
            </Button>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
  return null;
};

export default ConfirmacaoEmail;
