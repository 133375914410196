/**
 * MENUS
 *
 * Incluir a lista de menus no seguinte padrão:
 *
 * Sem subitens: { titulo: 'Home', icone: 'fa-home', destino: 'home', params: {}, perfis: '' }
 * Com subitens: { titulo: 'Home', icone: 'fa-home', destino: 'home', params: {}, perfis: '', subitens: [{<Objeto de menu sem subitens>}] },
 */
const MENUS = [
  { titulo: 'Menu', icone: 'fa-bars', destino: '', params: {}, perfis: '' },
  { titulo: 'Home', icone: 'fa-home', destino: '/', params: {}, perfis: '' },
  {
    titulo: 'Gestão de Sócios',
    icone: 'fa-user',
    destino: '',
    params: {},
    perfis: '',
    subitens: [
      { titulo: 'Cadastros', destino: '/cadastros', params: {}, perfis: '' },
      { titulo: 'Títulos', destino: '/titulos', params: {}, perfis: 'SECRETARIA' },
      { titulo: 'Restrições', destino: '/restricoes', params: {}, perfis: 'SECRETARIA' },
    ],
  },
  {
    titulo: 'Clube',
    icone: 'fa-soccer-ball-o',
    destino: 'home',
    params: {},
    perfis: 'SECRETARIA',
    subitens: [
      { titulo: 'Acessos Físicos', destino: '/acessos-fisicos', params: {}, perfis: 'SECRETARIA' },
      { titulo: 'Convites', destino: '/convites/0', params: {}, perfis: 'SECRETARIA' },
      { titulo: 'Churrasqueiras', destino: '/churrasqueiras/reservas', params: {}, perfis: 'SECRETARIA' },
    ],
  },
  {
    titulo: 'Financeiro',
    icone: 'fa-money',
    destino: 'home',
    params: {},
    perfis: 'FINANCEIRO',
    subitens: [
      { titulo: 'Boletos', destino: '/boletos/avulsos', params: {}, perfis: 'FINANCEIRO' },
      { titulo: 'Produtos', destino: '/produtos', params: {}, perfis: 'FINANCEIRO' },
      { titulo: 'Lançamentos em lote', destino: '/lancamentos-lote', params: {}, perfis: 'FINANCEIRO' },
      { titulo: 'Consulta de boletos', destino: '/consultaboletosocio', params: {}, perfis: 'FINANCEIRO' },
    ],
  },
  { titulo: 'Exame Médico', icone: 'fa-heartbeat', destino: '/exames', params: {}, perfis: '' },
  { titulo: 'Relatórios', icone: 'fa-print', destino: '/relatorios', params: {}, perfis: 'FINANCEIRO' },
  {
    titulo: 'Administração do sistema',
    icone: 'fa-lock',
    destino: '',
    params: {},
    perfis: 'ADMIN',
    subitens: [{ titulo: 'Usuários', destino: '/usuarios/0', params: {}, perfis: 'ADMIN' }],
  },
  { titulo: 'Sair', icone: 'fa-sign-out', destino: '/logout', params: {}, perfis: '' },
];

export default MENUS;
