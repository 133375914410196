import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Card, Row, Form, Table } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Voltar, Confirmacao, Carregando, Campo, Foto } from '../../components';
import { alertar, formatarValor, parseValor, validarValor, zeroPad } from '../../utils';
import { modelProduto } from '../../models';
import { Funci } from '../../services';
import moment from 'moment';

export default withRouter(props => {
  const { history, location } = props;
  const { pessoa, contratacao } = location.state || {};
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [lancamentos, setLancamentos] = useState([]);
  const [valorFinal, setValorFinal] = useState(0);

  const statusColors = {
    A: 'text-primary',
    C: 'text-secondary',
  };

  const cancelarContratacao = async () => {
    try {
      setCarregando(true);
      fecharConfirmacao();

      const operacao = await Funci.cancelarContratacao(contratacao.idPessoa, contratacao.idProduto, contratacao.ordem);
      if (operacao === true) {
        history.goBack();
      } else if (operacao.erro) {
        alertar('danger', operacao.erro);
      } else {
        alertar('error', 'Erro desconhecido na operação.');
      }
    } catch (error) {
      alertar('danger', error);
    } finally {
      setCarregando(false);
    }
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  useEffect(() => {
    const perDesconto = parseValor(contratacao.desconto);
    if (perDesconto <= 100) {
      const calculo = parseFloat(contratacao.vlProduto) * (1 - parseValor(contratacao.desconto) / 100);
      console.log('parseValor(contratacao.vlProduto)', parseFloat(contratacao.vlProduto));
      if (!Number.isNaN(calculo)) setValorFinal(calculo.toFixed(2));
      else setValorFinal(contratacao.vlProduto);
    } else {
      setValorFinal(contratacao.vlProduto);
    }
  }, [contratacao.vlProduto, contratacao.desconto]);

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.listarLancamentosContratacao(pessoa.id, contratacao.idProduto, contratacao.ordem);
        if (consulta.erro) {
          setLancamentos([]);
          alertar('danger', consulta.erro);
        } else {
          setLancamentos(consulta);
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [pessoa.idPessoa, contratacao.idProduto, contratacao.ordem]);

  return (
    <Container fluid>
      <br />
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      <Card>
        <Card.Header as="h6">{'Detalhe da contratação'}</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Campo label="Nome:" valor={pessoa.nome} />
              <br />
              <Campo label="Categorias:" valor={pessoa.associacoes && pessoa.associacoes.map(a => a.categoria).join(',')} />
            </Col>
            <Col className="d-flex justify-content-end">
              <Foto id={`${pessoa.id_pessoa_lg}${pessoa.cod_dependente_lg || ''}`} height={80} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Campo label="Produto:" valor={`${contratacao.nome}${contratacao.textoComplemento ? ' - ' + contratacao.textoComplemento : ''}`} />
            </Col>
          </Row>
          <br />
          <Row className="d-flex justify-content-between">
            <Col xs="3">
              <Campo label="Periodicidade:" valor={{ 1: 'MENSAL', 2: 'ANUAL', 3: 'AVULSO' }[contratacao.periodicidade]} />
            </Col>
            <Col xs="3">
              <Campo label="Número de parcelas:" valor={contratacao.nrParcelas} />
            </Col>
            <Col xs="3">
              <Campo label="Data primeira parcela:" valor={moment(contratacao.dt1Parcela).format('DD/MM/YYYY')} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs="3">
              <Campo label="Forma de pagamento:" valor={contratacao.formaPagto} />
            </Col>
          </Row>
          <br />
          <Row className="d-flex justify-content-between">
            <Col xs="3">
              <Campo label="Valor:" valor={contratacao.vlProduto ? `R$ ${formatarValor(contratacao.vlProduto)}` : ''} />
            </Col>
            <Col xs="3">
              <Campo label="Desconto:" valor={contratacao.desconto ? `${formatarValor(contratacao.desconto)}%` : ''} />
            </Col>
            <Col xs="3">
              <Campo label="Valor Final:" valor={valorFinal ? `R$ ${formatarValor(valorFinal)}` : ''} />
            </Col>
          </Row>
          <br />
          <hr />
          <br />
          <Row>
            <Col className="d-flex justify-content-center">
              <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                Voltar
              </Button>
              <Button
                className="mx-2"
                variant="danger"
                onClick={() => confirmar('Tem certeza que deseja cancelar a contratação do produto?', () => cancelarContratacao())}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
          <br />
          <hr />
          <Row>
            {(!lancamentos || lancamentos.length === 0) && (
              <Card.Text className="text-center">{'Não foram encontrados lançamentos para o produto.'}</Card.Text>
            )}
            {lancamentos.length > 0 && (
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th className="text-center">Data Lanc.</th>
                    <th className="text-center">Referência</th>
                    <th className="text-center">Vencimento</th>
                    <th className="text-center">Valor Unit.</th>
                    <th className="text-center">Qtde.</th>
                    <th className="text-center">Valor Total</th>
                    <th className="text-center">Valor Pago</th>
                    <th className="text-center">Data Pagto</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {lancamentos.map((l, i) => (
                    <tr key={`${i}-${l.id}`}>
                      <td className="text-center">{moment(l.dtLancamento).format('DD/MM/YYYY') || ''}</td>
                      <td className="text-center">{zeroPad(l.mesReferencia, 2) ? `${l.mesReferencia}/${l.anoReferencia}` : ''}</td>
                      <td className="text-center">{moment(l.dtVencimento).format('DD/MM/YYYY') || ''}</td>
                      <td className="text-center">{l.vlUnitario ? `R$ ${formatarValor(l.vlUnitario)}` : ''}</td>
                      <td className="text-center">{l.qtde}</td>
                      <td className="text-center">{l.vlTotal ? `R$ ${formatarValor(l.vlTotal)}` : ''}</td>
                      <td className="text-center">{l.vlPago ? `R$ ${formatarValor(l.vlPago)}` : ''}</td>
                      <td className="text-center">{moment(l.dtVencimento).format('DD/MM/YYYY') || ''}</td>
                      <td className={`${statusColors[l.status] || 'text-secondary'} text-center`}>
                        {l.status === 'A' && 'ATIVO'}
                        {l.status === 'C' && 'CANCELADO'}
                      </td>
                      <td className="text-center" />
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
});
