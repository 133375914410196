import React, { useState } from 'react';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Voltar, Carregando } from '../../components';
import { Funci } from '../../services';
import { alertar, validarCPF } from '../../utils';

export default withRouter(props => {
  const { history } = props;
  const { handleSubmit, register, errors, watch, getValues } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [tipo, setTipo] = useState(null);
  const [socio, setSocio] = useState(null);
  const [socios, setSocios] = useState([]);

  const onSubmitSocio = async values => {
    const { titulo } = values;
    // Validar quaisquer campso necessários se houver

    // Comandar a navagação paginada
    consultarSocios(titulo);
  };

  const onSubmitVisitante = async values => {
    prosseguir();
  };

  const consultarSocios = async tituloSocio => {
    setSocio(null);
    setSocios([]);
    setCarregando(true);
    try {
      const consulta = await Funci.buscarSocioEDepPorTitulo(tituloSocio);
      if (consulta.erro) {
        alertar('danger', consulta.erro);
      } else {
        setSocios(consulta);
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const prosseguir = () => {
    const url = '/exames/registro';
    const cpf = getValues('cpf', '');
    if (tipo === 'socio') {
      if (socio) history.push(url, { tipo, identificador: { ...socios.filter(s => `${s.id}` === `${socio}`)[0] } });
    } else if (tipo === 'visitante') {
      if (cpf) history.push(url, { tipo, identificador: { cpf } });
    } else {
      alertar('danger', 'Tipo de pessoa não definido.');
    }
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      <Card>
        <Card.Header as="h6">Selecione uma pessoa</Card.Header>
        <Card.Body>
          <Row className="border-bottom pb-2 justify-content-center">
            <Col xs="auto">
              <Form>
                <Form.Check custom inline="true" type="radio" id="socio" label="Sócio" name="tipo" onChange={event => setTipo(event.target.id)} />
                <Form.Check
                  custom
                  inline="true"
                  type="radio"
                  id="visitante"
                  label="Visitante"
                  name="tipo"
                  onChange={event => setTipo(event.target.id)}
                />
              </Form>
            </Col>
          </Row>
          <br />
          {tipo === 'socio' && (
            <Row className="pb-2">
              <Col>
                <Form onSubmit={handleSubmit(onSubmitSocio)} className="mt-2">
                  <Row className="justify-content-start">
                    <Form.Group as={Col} xs={4} controlId="titulo" className="align-middle mb-0">
                      <Form.Control
                        required
                        name="titulo"
                        type="text"
                        maxLength={20}
                        placeholder="Título do associado"
                        ref={register({
                          minLength: { value: 3, message: 'A pesquisa deve conter no mínimo 3 caracteres.' },
                          maxLength: { value: 20, message: 'A pesquisa deve conter no máximo 20 caracteres.' },
                        })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.titulo && errors.titulo.message}
                      </Col>
                    </Form.Group>
                    <Col xs="auto">
                      <Button type="submit">Buscar</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          )}
          <br />
          {tipo === 'socio' && socios && socios.length > 0 && (
            <Form>
              <Row className="pb-2 justify-content-center">
                <Col xs="auto">
                  {socios.map(s => (
                    <Form.Check
                      key={`${s.id}`}
                      custom
                      id={s.id}
                      type="radio"
                      label={`(${s.tipo === 'T' ? 'Titular' : 'Dependente'}) ${s.titulo} ${s.codDependente || ''} - ${s.categoria} - ${s.nome}`}
                      name="socio"
                      onChange={event => setSocio(event.target.id)}
                    />
                  ))}
                </Col>
              </Row>
            </Form>
          )}
          {tipo === 'visitante' && (
            <Row className="pb-2">
              <Col>
                <Form onSubmit={handleSubmit(onSubmitVisitante)} className="mt-2">
                  <Row className="justify-content-start">
                    <Form.Group as={Col} xs={4} controlId="cpf" className="align-middle mb-0">
                      <Form.Control
                        required
                        name="cpf"
                        type="text"
                        placeholder="CPF do visitante"
                        ref={register({
                          required: true,
                          validate: value => validarCPF(value),
                        })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {!validarCPF(watch('cpf', '')) && watch('cpf', '') !== '' && 'CPF inválido.'}
                      </Col>
                    </Form.Group>
                  </Row>
                </Form>
              </Col>
            </Row>
          )}
          <br />
          <Row>
            <Col className="d-flex justify-content-center">
              <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                Cancelar
              </Button>
              <Button className="mx-2" variant="success" disabled={!tipo || (!validarCPF(watch('cpf', '')) && !socio)} onClick={() => prosseguir()}>
                Prosseguir
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
});
