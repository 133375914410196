import moment from 'moment';

/**
 * @method modelConvite
 * @description Modelo padrão de objeto Convite
 * @param {string} idConvite - id do Convite
 * @param {string} tipo - tipo do Convite [A: Avulso/Individual, C: Churrasqueira]
 * @param {string} tipoLabel - Label para apresentação do tipo. Derivado do campo acima
 * @param {string} idReserva - id da reserva de Churrasqueira, caso o convite esteja vinculado a uma
 * @param {string} nomeConvidado - nome do convidado no convite
 * @param {string} cpf - cpf do convidado (obrigatório para maiores de 18 anos)
 * @param {boolean} menorAdolescente - indicador de menor entre 9 e 17 anos
 * @param {boolean} veiculoAutorizado - indicador de veículo com entrada autorizada
 * @param {string} descricaoVeículo - descricao do veículo com entrada autorizada
 * @param {string} placaVeiculo - placa do veículo autorizado
 * @param {string} status - status atual do convite [A: Ativo, C: Cancelado]
 * @param {string} statusLabel - Label para apresentação do status. Derivado do campo acima.
 * @param {string} validade - período de validade do convite (derivado dos próximos dois campos)
 * @param {date} dataInicial - data inicial da validade do convite - Entrada YYYY-MM-DD
 * @param {date} dataFinal - data final da validade do convite - Entrada YYYY-MM-DD
 * @param {date} dataUtilizacao - data da efetiva utilização do convite - Entrada YYYY-MM-DD
 * @param {date} timestamp - data de emissão do convite
 * @returns {object} Objeto de Convite formatado
 */
export default ({
  idConvite,
  tipo,
  idReserva = null,
  titulo = '',
  nomeSocio = '',
  nomeConvidado = '',
  cpf = '',
  menorAdolescente = false,
  descricaoVeiculo = '',
  placaVeiculo = '',
  status = 'A',
  dataInicial = moment().format('YYYY-MM-DD'),
  dataFinal = moment().add(30, 'days').format('YYYY-MM-DD'),
  dataUtilizacao,
  qrcode,
  timestamp,
}) => {
  const getStatus = () => {
    if (dataUtilizacao) return 'U';
    if (status === 'C') return 'C';
    if (status === 'A' && moment(dataFinal.substring(0, 10)) >= moment().startOf('day')) return 'A';
    if (status === 'A' && moment(dataFinal.substring(0, 10)) < moment().startOf('day')) return 'N';
    if (status === 'A' && moment(dataFinal, 'DD/MM/YYYY') >= moment().startOf('day')) return 'A';
    if (status === 'A' && moment(dataFinal, 'DD/MM/YYYY') < moment().startOf('day')) return 'N';
    return '';
  };
  const statusLabels = {
    A: 'EM ABERTO',
    C: 'CANCELADO',
    U: 'UTILIZADO',
    N: 'NÃO UTILIZADO',
  };
  const tipoLabels = { A: 'AVULSO', C: 'CHURRASQUEIRA' };
  const statusLabel = statusLabels[getStatus()];
  const normalize = d => moment(d.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY');

  return {
    idConvite: parseInt(idConvite, 10),
    tipo,
    tipoLabel: tipoLabels[tipo] || '',
    idReserva: parseInt(idReserva, 10),
    titulo,
    nomeSocio,
    nomeConvidado,
    cpf,
    menorAdolescente: menorAdolescente > 0 || menorAdolescente === true || false,
    veiculoAutorizado: (placaVeiculo && placaVeiculo.length > 0) || false,
    descricaoVeiculo,
    placaVeiculo,
    status: getStatus(),
    statusLabel: statusLabel || '',
    validade: dataInicial !== dataFinal ? `${normalize(dataInicial)} a ${normalize(dataFinal)}` : `${normalize(dataInicial)}`,
    dataInicial: `${normalize(dataInicial)}`,
    dataFinal: `${normalize(dataFinal)}`,
    dataUtilizacao: dataUtilizacao ? `${normalize(dataUtilizacao)}` : null,
    qrcode,
    timestamp: timestamp ? `${moment(timestamp).format('DD/MM/YYYY HH:mm:ss')}` : null,
  };
};
