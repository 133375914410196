import moment from 'moment';
import modelChurrasqueira from './modelChurrasqueira';

/**
 * @method modelReserva
 * @description Modelo padrão de objeto Reserva
 * @param {string} idReserva - id da Reserva
 * @param {string} tipoObjeto - tipo de objeto da reserva [C: Churrasqueira]
 * @param {string} idObjeto - id do objeto da reserva
 * @param {object} objeto - Objeto da reserva com suas respectivas informações [{churrasqueira}]
 * @param {date} dataInicial - data inicial da validade da reserva
 * @param {date} dataFinal - data final da validade da reserva
 * @param {date} dataUtilizacao - data da efetiva utilização da reserva
 * @param {string} status - status atual da reserva [A: Ativo, C: Cancelado]
 * @param {string} statusLabel - Label para apresentação do status. Derivado de cálculo.
 * @param {string} turno - turno de utilização da reserva, caso existir. [D: Diurno, N: Noturno]
 * @param {string} turnoLabel - Label para apresentação do turno. Derivado do campo acima
 * @param {string} uidResponsavel - uid do responsavel pela reserva
 * @param {string} nomeResponsavel - nome do responsavel pela reserva
 * @param {date} timestamp - data de emissão do convite
 * @returns {object} Objeto de Reserva formatado
 */
export default ({
  idReserva,
  titulo,
  nome,
  tipoObjeto,
  idObjeto,
  objeto = {},
  dataInicial,
  dataFinal,
  dataUtilizacao,
  status = 'A',
  turno,
  uidResponsavel = '',
  nomeResponsavel = '',
  timestamp,
}) => {
  const getStatus = () => {
    if (dataUtilizacao) return 'E';
    if (status === 'C') return 'C';
    if (status === 'A' && moment(dataFinal) >= moment()) return 'R';
    if (status === 'A' && moment(dataFinal) < moment()) return 'E';
    if (status === 'A' && moment(dataFinal, 'DD/MM/YYYY') >= moment()) return 'R';
    if (status === 'A' && moment(dataFinal, 'DD/MM/YYYY') < moment()) return 'E';
    return '';
  };
  const statusLabels = {
    R: 'RESERVADA',
    C: 'CANCELADA',
    E: 'EFETIVADA',
    N: 'NÃO UTILIZADA',
  };
  const statusLabel = statusLabels[getStatus()];
  const turnosLabels = { D: 'DIURNO', N: 'NOTURNO' };
  const turnoLabel = turnosLabels[turno];

  return {
    idReserva: parseInt(idReserva, 10),
    titulo,
    nome,
    tipoObjeto: tipoObjeto.toString(),
    idObjeto,
    objeto: tipoObjeto === 'C' && objeto.idChurrasqueira ? modelChurrasqueira(objeto) : objeto,
    dataInicial: `${moment(dataInicial).format('DD/MM/YYYY')}`,
    dataFinal: `${moment(dataFinal).format('DD/MM/YYYY')}`,
    dataUtilizacao: dataUtilizacao ? `${moment(dataUtilizacao).format('DD/MM/YYYY')}` : null,
    status: getStatus(),
    statusLabel: statusLabel || '',
    turno: turno.toString(),
    turnoLabel: turnoLabel || '',
    uidResponsavel,
    nomeResponsavel,
    timestamp: timestamp ? `${moment(timestamp).format('DD/MM/YYYY HH:mm:ss')}` : null,
  };
};
