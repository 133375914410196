import React, { useState } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Voltar, Carregando, Confirmacao } from '../../components';
import { Funci } from '../../services';
import { alertar } from '../../utils';

export default withRouter(props => {
  const { history, location } = props;
  const { tipo, identificador } = location.state;
  const { handleSubmit, register, errors, getValues } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });

  const qtdeDiasValidadeExameSocio = 90;
  const qtdeDiasValidadeExameVisitante = 30;
  const tipoPessoa = tipo === 'visitante' ? 'Visitante' : { D: 'Dependente', T: 'Titular' }[identificador.tipo] || 'Sócio';
  const qtdeDiasValidadeExame = tipoPessoa === 'Visitante' ? qtdeDiasValidadeExameVisitante : qtdeDiasValidadeExameSocio;

  const onSubmit = async () => {
    confirmar('Confirma o registro e validação do exame médico?', registrar);
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const registrar = async () => {
    fecharConfirmacao();
    setCarregando(true);
    try {
      if (!tipo && !identificador.id && !identificador.cpf) throw new Error('Pessoa não definida.');
      const objExame = {};
      if (tipo === 'visitante') objExame.cpf = identificador.cpf;
      if (tipo === 'socio') {
        objExame.titulo = identificador.titulo;
        objExame.codDependente = identificador.codDependente;
        objExame.idPessoa = identificador.id;
      }
      objExame.tipoPessoa = tipoPessoa;
      objExame.observacoes = getValues('observacoes', '');

      const registroExame = await Funci.incluirExameMedico(objExame);
      if (registroExame.erro) {
        throw registroExame.erro;
      } else if (registroExame.id) {
        history.push(`/detalheexame/${registroExame.id}`);
        alertar('success', 'Exame registrado com sucesso.');
      } else {
        throw new Error('Ocorreu um erro inesperado. Por favor, tente novamente.');
      }
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  return (
    <Col as="div" className="rolagem">
      <Container fluid>
        <Carregando show={carregando} />
        <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />

        <br />
        <Row className="justify-content-between">
          <Voltar />
        </Row>

        <Card>
          <Card.Header as="h6">Detalhe Exame Médico</Card.Header>
          <Card.Body>
            <Card.Text>{`Tipo: ${tipoPessoa}`}</Card.Text>
            {/* TITULAR OU DEPENDENTE */}
            {(tipoPessoa === 'Titular' || tipoPessoa === 'Dependente') && (
              <Row>
                <Col>
                  <Card.Text>
                    {`Nome: ${identificador.nome} ${identificador.nomeRdzd ? '(' + identificador.nomeRdzd + ')' : ''}`}
                    <br />
                    {`Título: ${identificador.titulo}${identificador.codDependente ? ' / ' + identificador.codDependente : ''}`}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {' '}
                    <br />
                    {`Categoria: ${identificador.categoria}`}
                  </Card.Text>
                </Col>
              </Row>
            )}
            {/* VISITANTE */}
            {tipoPessoa === 'Visitante' && (
              <Row>
                <Col>
                  <Card.Text>{`CPF: ${identificador.cpf}`}</Card.Text>
                </Col>
                <Col>
                  <Card.Text> </Card.Text>
                </Col>
              </Row>
            )}
            <br />
            <Card.Text>Dados do exame</Card.Text>
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
              <Row>
                <Col>
                  <Card.Text>{`Valido até: ${moment()
                    .add(qtdeDiasValidadeExame, 'days')
                    .format('DD/MM/YYYY')} (${qtdeDiasValidadeExame} dias)`}</Card.Text>
                </Col>
              </Row>
              <br />
              <Card.Text>{`Observações:`}</Card.Text>
              <Form.Control
                as="textarea"
                rows={4}
                maxLength={1000}
                name="observacoes"
                type="text"
                ref={register({
                  required: false,
                  maxLength: { value: 1000, message: 'O campo deve conter no máximo 200 caracteres.' },
                })}
              />
              <Card.Text className="text-danger">{errors.observacoes && errors.observacoes.message}</Card.Text>
              <br />
              <Row>
                <Col className="d-flex justify-content-center">
                  <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                    Cancelar
                  </Button>
                  <Button className="mx-2" variant="success" type="submit">
                    Registrar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </Col>
  );
});
