/**
 * validarCEPNumerico
 *
 * @param {string} cep
 * @returns
 */
const validarCEPNumerico = cep => {
  const fTrim = `${cep}`.replace(/\D/g, '');
  console.log('cep', cep);
  if (fTrim !== '') {
    const validacep = /^[0-9]{8}$/;
    if (validacep.test(fTrim)) {
      return true;
    }
  }
  return false;
};

export default validarCEPNumerico;
