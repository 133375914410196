import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { Campo, Carregando } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarValor } from '../../utils';
import { Badge, Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default withRouter(props => {
  const { history, pessoa } = props;
  const { id } = useParams();
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(0);
  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        const consulta = await Funci.buscarProdutosPorIdPessoa(id, 1, 99999, '0', 'DESC');
        console.log('consulta', consulta);
        if (consulta.erro) {
          setProdutos({});
          alertar('danger', consulta.erro);
        } else {
          setProdutos(consulta);
          // setProdutos(modelPessoa(consulta));
        }
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, atualizar]);

  const statusColors = {
    A: 'text-primary',
    C: 'text-secondary',
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Card className="m-2">
        <Card.Header as="h6">{pessoa.nome}</Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Campo label="Categorias:" valor={pessoa.associacoes && pessoa.associacoes.map(a => a.categoria).join(',')} />
            </Col>
            <Col className="d-flex justify-content-end">
              <Button className="mx-2" variant="success" onClick={() => history.push(`/contratacaoproduto`, { pessoa })}>
                Contratar
              </Button>
            </Col>
          </Row>
          <Row>
            <Campo label="Qtde. Dependentes:" valor={pessoa.dependentes && pessoa.dependentes.length} />
          </Row>
          <hr />
          <Card.Title>Histórico de produtos</Card.Title>
          {(!produtos || produtos.length === 0) && <Card.Text className="text-center">{'Não foram encontrados produtos para o sócio.'}</Card.Text>}
          {produtos.length > 0 && (
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>
                  <th>Produto</th>
                  <th className="text-center">Periodicidade</th>
                  <th className="text-center">Nr Parcelas</th>
                  <th className="text-center">Parcela Atual</th>
                  <th className="text-center">Dt Ult Geração</th>
                  <th className="text-center">Valor</th>
                  <th className="text-center">Desconto</th>
                  <th className="text-center">Valor Final</th>
                  <th className="text-center">Contratação</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Detalhes</th>
                </tr>
              </thead>
              <tbody>
                {produtos.map((p, i) => (
                  <tr key={`${i}-${p.idProduto}`}>
                    <td>{`${p.nome}${p.textoComplemento ? ' - ' + p.textoComplemento : ''}`}</td>
                    <td className="text-center">{{ 1: 'MENSAL', 2: 'ANUAL', 3: 'AVULSO' }[p.periodicidade]}</td>
                    <td className="text-center">{p.nrParcelas}</td>
                    <td className="text-center">{p.nrParcelaAtual}</td>
                    <td className="text-center">{p.dtUltGeracao}</td>
                    <td className="text-center">{p.vlProduto ? `R$ ${formatarValor(p.vlProduto)}` : ''}</td>
                    <td className="text-center">{p.desconto ? `R$ ${formatarValor(p.desconto)}` : ''}</td>
                    <td className="text-center">{p.vlProduto || p.desconto ? `R$ ${formatarValor((p.vlProduto || 0) - (p.desconto || 0))}` : ''}</td>
                    <td className="text-center">{moment(p.dtLancamento).format('DD/MM/YYYY HH:mm:ss')}</td>
                    <td className={`${statusColors[p.status] || 'text-secondary'} text-center`}>
                      {p.status === 'A' && 'ATIVO'}
                      {p.status === 'C' && 'CANCELADO'}
                    </td>
                    <td className="text-center">
                      <LinkContainer to={{ pathname: `/detalheprodutosocio`, state: { pessoa, contratacao: p } }}>
                        <Badge>Clique aqui</Badge>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
});
