import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Card, Row } from 'react-bootstrap';
import { Voltar, Alerta, Carregando, Confirmacao, ConfirmacaoEmail } from '../../components';
import { useParams } from 'react-router-dom';
import { Funci } from '../../services';
import modelConvite from '../../models/modelConvite';
import modelReserva from '../../models/modelReserva';
import { validarEmail } from '../../utils';

export default () => {
  const { idConvite } = useParams();
  const [atualizacao, setAtualizacao] = useState(0);
  const [carregando, setCarregando] = useState(true);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', onConfirm: () => {}, onCancel: () => {}, show: false });
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [boxEmail, setBoxEmail] = useState({ show: false });
  const [convite, setConvite] = useState(null);
  const [reserva, setReserva] = useState(null);

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const abrirBoxEmail = (onConfirm = () => {}) => {
    setBoxEmail({ onConfirm, onCancel: fecharBoxEmail, show: true });
  };

  const fecharBoxEmail = () => {
    setBoxEmail({ onConfirm: () => {}, onCancel: fecharBoxEmail, show: false });
  };

  const confirmarEntrada = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      fecharBoxEmail();
      setCarregando(true);
      const confirm = await Funci.confirmarEntrada(idConvite);
      if (confirm.erro) alertar('danger', confirm.erro);
      if (confirm === true) {
        alertar('success', 'Entrada confirmada com sucesso.');
        setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  const cancelarConvite = async () => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      fecharBoxEmail();
      setCarregando(true);
      const cancelamento = await Funci.cancelarConvite(convite);
      if (cancelamento.erro) alertar('danger', cancelamento.erro);
      if (cancelamento === true) {
        alertar('success', 'Convite cancelado com sucesso.');
        setAtualizacao(atualizacao + 1);
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  const enviarPorEmail = async email => {
    try {
      fecharAlerta();
      fecharConfirmacao();
      fecharBoxEmail();
      setCarregando(true);
      if (!validarEmail(email)) return alertar('danger', 'Email inválido.');
      const obj = { email, idConvite: convite.idConvite };
      const envio = await Funci.enviarConvitePorEmail(obj);
      if (envio.erro) alertar('danger', envio.erro);
      if (envio === true) {
        alertar('success', 'Email encaminhado com sucesso.');
      }
      setCarregando(false);
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const conv = await Funci.consultarConvite(idConvite);
        if (conv.erro) alertar('danger', conv.erro);
        if (conv.idConvite) {
          if (conv.tipo === 'C') {
            const reserv = await Funci.consultarReserva(conv.idReserva.toString());
            if (conv.erro) return alertar('danger', conv.erro);
            if (reserv.idReserva) setReserva(modelReserva(reserv));
          }
          setConvite(modelConvite(conv));
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [idConvite, atualizacao]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <ConfirmacaoEmail show={boxEmail.show} onConfirm={boxEmail.onConfirm} onCancel={boxEmail.onCancel} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>
      {convite ? (
        <Card>
          <Card.Header as="h6">{`Convite ${convite.tipoLabel}`}</Card.Header>
          <Card.Body>
            <Card.Title>{convite.nomeConvidado}</Card.Title>
            {/* ******************************************************** */}
            {/* AVULSO */}
            {/* ******************************************************** */}
            {convite.tipo === 'A' ? (
              <Row>
                <Col>
                  <Card.Text>
                    {`CPF: ${convite.cpf}`}
                    <br />
                    {`Tipo: ${convite.tipoLabel}`}
                    <br />
                    {`Data permitida: ${convite.dataInicial} a ${convite.dataFinal}`}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {`Menor entre 9 e 17 anos? ${convite.menorAdolescente ? 'Sim' : 'Não'}`}
                    <br />
                    {`Status: ${convite.statusLabel}`}
                    {convite.dataUtilizacao ? (
                      <>
                        <br />
                        {`Data de utilização: ${convite.dataUtilizacao}`}
                      </>
                    ) : null}
                  </Card.Text>
                </Col>
              </Row>
            ) : null}
            {/* ******************************************************** */}
            {/* CHURRASQUEIRA */}
            {/* ******************************************************** */}
            {convite.tipo === 'C' ? (
              <Row>
                <Col>
                  <Card.Text>
                    {`CPF: ${convite.cpf}`}
                    <br />
                    {`Menor entre 9 e 17 anos? ${convite.menorAdolescente ? 'Sim' : 'Não'}`}
                    <br />
                    {`${reserva.objeto.nome}`}
                    <br />
                    {`Data permitida: ${convite.dataInicial}`}
                    {convite.dataUtilizacao ? (
                      <>
                        <br />
                        {`Data de utilização: ${convite.dataUtilizacao}`}
                      </>
                    ) : null}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {`Veículo autorizado? ${convite.veiculoAutorizado ? 'Sim' : 'Não'}`}
                    <br />
                    {`Descrição veículo: ${convite.descricaoVeiculo || ''}`}
                    <br />
                    {`Placa veículo: ${convite.placaVeiculo || ''}`}
                    <br />
                    {`Status: ${convite.statusLabel}`}
                  </Card.Text>
                </Col>
              </Row>
            ) : null}
            {/* ******************************************************** */}
            <br />
            <Row className="justify-content-center">
              <Col className="d-flex justify-content-center">
                <Button
                  className="mx-2"
                  variant="primary"
                  disabled={{ A: false, U: true, N: true, C: true }[convite.status]}
                  onClick={() => confirmar('Tem certeza que deseja confirmar a entrada do visitante?', confirmarEntrada)}
                >
                  Confirmar entrada
                </Button>
                <Button
                  className="mx-2"
                  variant="danger"
                  disabled={{ A: false, U: true, N: true, C: true }[convite.status]}
                  onClick={() => confirmar('Tem certeza que deseja cancelar o convite?', cancelarConvite)}
                >
                  Cancelar convite
                </Button>
                <Button
                  href={`/impressao/convite/${convite.idConvite || '0'}`}
                  target="_BLANK"
                  className="mx-2"
                  variant="info"
                  disabled={{ A: false, U: true, N: true, C: true }[convite.status]}
                >
                  Imprimir
                </Button>
                <Button
                  className="mx-2"
                  variant="info"
                  disabled={{ A: false, U: true, N: true, C: true }[convite.status]}
                  onClick={() => abrirBoxEmail(enviarPorEmail)}
                >
                  Enviar por email
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </Container>
  );
};
