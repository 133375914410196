/**
 * fomartarValor
 *
 * @param {string} valor
 * @returns
 */
const formatarValor = valor => {
  // retira os caracteres indesejados...
  let valorLimpo;
  if (typeof valor === 'number') {
    if (`${valor.toFixed(2)}`.substr(`${valor}`.length - 2, 1) === '.') {
      valorLimpo = Number(`${valor}0`.replace('.', ',').replace(/[^\d]/g, '').substring(0, 11)).toString();
    }
    if (`${valor.toFixed(2)}`.substr(`${valor}`.length - 3, 1) === '.') {
      valorLimpo = Number(`${valor}`.replace('.', ',').replace(/[^\d]/g, '').substring(0, 11)).toString();
    }
    if (`${valor.toFixed(2)}`.substr(`${valor}`.length - 3, 1) !== '.' && `${valor}`.substr(`${valor}`.length - 2, 1) !== '.') {
      valorLimpo = Number(`${valor}.00`.replace('.', ',').replace(/[^\d]/g, '').substring(0, 11)).toString();
    }
  } else {
    valorLimpo = Number(`${valor}`.replace(/[^\d]/g, '').substring(0, 11)).toString();
  }

  // realizar a formatação...

  // Primeiro bloco não há formatação
  if (!valorLimpo) return '';
  if (valorLimpo.length === 0) return '';
  if (valorLimpo.length === 1) return `0,0${valorLimpo}`;
  if (valorLimpo.length === 2) return `0,${valorLimpo}`;

  // Segundo bloco
  if (valorLimpo.length === 3) return valorLimpo.replace(/(\d{1})(\d{2})/, '$1,$2');
  if (valorLimpo.length === 4) return valorLimpo.replace(/(\d{2})(\d{2})/, '$1,$2');
  if (valorLimpo.length === 5) return valorLimpo.replace(/(\d{3})(\d{2})/, '$1,$2');

  // Terceiro bloco
  if (valorLimpo.length === 6) return valorLimpo.replace(/(\d{1})(\d{3})(\d{2})/, '$1.$2,$3');
  if (valorLimpo.length === 7) return valorLimpo.replace(/(\d{2})(\d{3})(\d{2})/, '$1.$2,$3');
  if (valorLimpo.length === 8) return valorLimpo.replace(/(\d{3})(\d{3})(\d{2})/, '$1.$2,$3');

  // Quarto bloco
  if (valorLimpo.length === 9) return valorLimpo.replace(/(\d{1})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3,$4');
  if (valorLimpo.length === 10) return valorLimpo.replace(/(\d{2})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3,$4');
  return valorLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3,$4');
};

export default formatarValor;
