/**
 * validarValor
 *
 * @param {string} valor
 * @param {boolean} permiteVazio
 * @param {boolean} permiteZero
 * @param {boolean} permiteNegativo
 * @returns
 */
const validarValor = (valor, permiteVazio = false, permiteZero = false, permiteNegativo = false) => {
  const filterFloat = value => {
    if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) return Number(value);
    return NaN;
  };

  if (permiteVazio && (valor === '' || valor === null)) return true;

  const nrValor = filterFloat(`${valor}`.replaceAll('.', '').replace(',', '.'));

  if (Number.isNaN(nrValor)) return false;
  if (!permiteZero && nrValor === 0) return false;
  if (!permiteNegativo && nrValor < 0) return false;

  return true;
};

export default validarValor;
