import moment from 'moment';
import { campoObrigatorio, campoOpcional } from '../utils';

/**
 * @method modelTitulo
 * @description Modelo padrão de objeto titulo
 * @param {object} titulo - Vide código da model. Lista omitida com o objetivo de diminuir o tamanho do arquivo.
 * @returns {object} Objeto de Convite formatado
 */
const modelTitulo = ({
  id,
  idPessoaLg,
  idCategoria,
  categoria,
  nome,
  cpfCnpj,
  titulo,
  statusAssociacao,
  dataAssociacao,
  status,
  timestamp,
  historicoAssociacoes,
}) => {
  const normalize = d => moment(d).format('DD/MM/YYYY');
  const getStatus = () => {
    if (status === 'A') return 'ATIVO';
    if (status === 'C') return 'CANCELADO';
    return 'DESCONHECIDO';
  };
  const getStatusAssociacao = () => {
    if (statusAssociacao === 'A') return 'ATIVO';
    if (statusAssociacao === 'C') return 'CANCELADO';
    if (statusAssociacao === 'P') return 'PROTOCOLO';
    if (statusAssociacao === 'S') return 'SUSPENSO';
    if (statusAssociacao === 'L') return 'INATIVO';
    return '';
  };

  return {
    id: campoOpcional(id, 'id', ['number']),
    idPessoaLg: campoOpcional(idPessoaLg, 'idPessoaLg', ['number']),
    idCategoria: campoOpcional(idCategoria, 'idCategoria', ['number']),
    categoria: campoOpcional(categoria, 'categoria', ['string']),
    nome: campoOpcional(nome, 'nome', ['string']),
    cpfCnpj: campoOpcional(cpfCnpj, 'cpfCnpj', ['string']),
    titulo: campoObrigatorio(titulo, 'titulo', ['string']),
    statusAssociacao: campoOpcional(statusAssociacao, 'statusAssociacao', ['string']),
    statusAssociacaoLabel: getStatusAssociacao(),
    dataAssociacao: dataAssociacao ? normalize(dataAssociacao) : null,
    status: campoOpcional(cpfCnpj, 'cpfCnpj', ['string']),
    statusLabel: getStatus(),
    timestamp: timestamp ? normalize(timestamp) : null,
    historicoAssociacoes: historicoAssociacoes || [],
  };
};

export default modelTitulo;
