import React from 'react';
import { Modal, Spinner, Row } from 'react-bootstrap';

const Carregando = props => {
  const { show = false, local = false } = props;

  if (show && !local) {
    return (
      <Modal centered animation={false} backdrop="static" show={show} keyboard={false} className="bg-invisible">
        <Row className="justify-content-center p-1">
          <Spinner animation="grow" role="Carregando" />
          <span className="text-center">Carregando...</span>
        </Row>
      </Modal>
    );
  }
  if (show && local) {
    return <Spinner animation="grow" role="Carregando" />;
  }
  return null;
};

export default Carregando;
