import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Voltar, Alerta, Carregando, Confirmacao } from '../../components';
import { Funci } from '../../services';
import { withRouter } from 'react-router-dom';
import { withOrdenar } from '../../utils';
import modelChurrasqueira from '../../models/modelChurrasqueira';

export default withRouter(props => {
  const { history, location } = props;
  const { socio, data } = location.state;
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedData, setValidatedData] = useState(false);
  const [idChurrasqueira, setIdChurrasqueira] = useState(0);
  const [periodo, setPeriodo] = useState('');
  const [churrasqueira, setChurrasqueira] = useState({ nome: '', capacidade: '', categoriaLabel: '' });
  const [churrasqueiras, setChurrasqueiras] = useState([]);

  const handleSubmit = (event, formulario) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    form.checkValidity();
    if (formulario === 'churrasqueira') setValidatedData(true);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const selChurrasqueira = idChurrasq => {
    if (!idChurrasq) {
      setChurrasqueira({ nome: '', capacidade: '', categoriaLabel: '' });
    } else {
      const churrasq = churrasqueiras.filter(c => c.idChurrasqueira === parseInt(idChurrasq, 10));
      if (churrasq.length > 0) setChurrasqueira(modelChurrasqueira(churrasq[0]));
    }
    setIdChurrasqueira(idChurrasq);
  };

  const reservar = async () => {
    fecharAlerta();
    fecharConfirmacao();
    if (!socio.titulo) return alertar('danger', 'Sócio não selecionado.');
    if (!idChurrasqueira) return alertar('danger', 'Data não selecionada.');
    if (!periodo) return alertar('danger', 'Período não selecionado.');
    try {
      setCarregando(true);

      const objReserva = {
        titulo: socio.titulo,
        tipoObjeto: 'C',
        idObjeto: idChurrasqueira,
        dataInicial: data,
        dataFinal: data,
        turno: periodo,
      };
      const emissao = await Funci.emitirReserva(objReserva);
      if (emissao.erro) {
        setCarregando(false);
        return alertar('danger', emissao.erro);
      }
      if (!emissao.idReserva) {
        setCarregando(false);
        return alertar('danger', 'Erro ao emitir a reserva.');
      }
      history.push(`/detalhereserva/${emissao.idReserva || '0'}`, { novaReserva: true });
    } catch (e) {
      alertar('danger', e);
      setCarregando(false);
    }
  };

  useEffect(() => {
    const inicializar = async () => {
      fecharAlerta();
      setChurrasqueiras([]);
      setCarregando(true);
      try {
        const consultaDisp = await Funci.listarChurrasqueirasDispPorData(moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD'));

        if (consultaDisp.erro) return alertar('danger', consultaDisp.erro);
        if (!consultaDisp.length || consultaDisp.length === 0) {
          return alertar('danger', 'Não existem datas disponíveis dentro do período permitido de reserva.');
        }
        setChurrasqueiras(withOrdenar(consultaDisp).ordenar('nome', 'A'));
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [data]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>

      <Card>
        <Card.Header as="h6">Nova reserva</Card.Header>
        <Card.Body>
          <Row className="border-bottom pb-2">
            {socio.titulo ? (
              <React.Fragment>
                <Col className="d-flex align-items-center">
                  <Card.Text>Título: {socio.titulo}</Card.Text>
                </Col>
                <Col className="d-flex align-items-center" xs="auto">
                  <Card.Text>Sócio: {socio.nome}</Card.Text>
                </Col>
              </React.Fragment>
            ) : (
              <Card.Text>Sócio não foi carregado corretamente.</Card.Text>
            )}
          </Row>
          <br />
          <Row className="border-bottom pb-2">
            {data ? (
              <Col className="d-flex align-items-center">
                <Card.Text>Data da reserva: {data}</Card.Text>
              </Col>
            ) : (
              <Card.Text>Data da reserva não foi carregada corretamente.</Card.Text>
            )}
          </Row>
          <br />
          <Form.Label>Selecione uma churrasqueira e o período desejado:</Form.Label>
          <Row className="pb-2">
            <Col xs="auto">
              <Form noValidate validated={validatedData} onSubmit={event => handleSubmit(event, 'churrasqueira')} className="mt-2">
                <Row className="justify-content-start">
                  <Form.Group as={Col} xs="auto" controlId="churrasqueira" className="align-middle mb-0">
                    <Form.Control as="select" value={idChurrasqueira} defaultValue={0} onChange={event => selChurrasqueira(event.target.value)}>
                      <option value={''}>Selecione...</option>
                      {churrasqueiras.map(c => (
                        <option key={c.idChurrasqueira.toString()} value={c.idChurrasqueira}>
                          {c.nome}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Churrasqueira não selecionada</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs="auto" controlId="periodo" className="align-middle mb-0">
                    <Form.Control as="select" value={periodo} defaultValue={''} onChange={event => setPeriodo(event.target.value)}>
                      <option value={''}>Selecione...</option>
                      <option value={'D'}>DIURNO</option>
                      <option value={'N'}>NOTURNO</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">Churrasqueira não selecionada</Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          {idChurrasqueira ? (
            <React.Fragment>
              <br />
              <Row className="border-bottom pb-2">
                <Col>
                  <Card.Subtitle>Resumo da reserva</Card.Subtitle>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Card.Title>{`${churrasqueira.nome || ''}`}</Card.Title>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card.Text>
                    {`Categoria: ${churrasqueira.categoriaLabel}`}
                    <br />
                    {`Capacidade: ${churrasqueira.capacidade} pessoas`}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {`Sócio: ${socio.nome}`}
                    <br />
                    {`Título: ${socio.titulo}`}
                  </Card.Text>
                </Col>
                <Col>
                  <Card.Text>
                    {`Período: ${{ D: 'DIURNO', N: 'NOTURNO' }[periodo] || ''}`}
                    <br />
                    {`Data: ${data}`}
                  </Card.Text>
                </Col>
              </Row>
              <br />
              <br />
            </React.Fragment>
          ) : null}
          <Row>
            <Col className="d-flex justify-content-center">
              <Button
                variant="success"
                onClick={() => confirmar('Confirma a efetivação da reserva conforme conforme consta no resumo?', reservar)}
                disabled={!idChurrasqueira || !periodo}
              >
                Reservar
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
});
