import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Voltar, Confirmacao, Carregando } from '../../components';
import { alertar, formatarValor, parseValor, validarValor } from '../../utils';
import { modelProduto } from '../../models';
import { Funci } from '../../services';

export default withRouter(props => {
  const { history, location } = props;
  const { id } = location.state || {};
  const { handleSubmit, register, errors, control, reset } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [produto, setProduto] = useState({});
  const [categorias, setCategorias] = useState([]);

  const tiposProduto = [
    { value: 'an', label: 'AN' },
    { value: 'av', label: 'AV' },
    { value: 'ca', label: 'CA' },
    { value: 'es', label: 'ES' },
    { value: 'ml', label: 'ML' },
    { value: 'ms', label: 'MS' },
    { value: 'na', label: 'NA' },
    { value: 'pa', label: 'PA' },
    { value: 're', label: 'RE' },
    { value: 'OT', label: 'OT' },
  ];

  const statusColors = {
    ATIVO: 'text-primary',
    INATIVO: 'text-secondary',
  };

  const onSubmit = dados => {
    const { nome, descricao, tipoProduto, publico, idCategoria, descontoAutomatico, valor } = dados;

    // Verificação dos campos obrigatórios é feita pelas regras do form e no backend

    // Montar o objeto de produto
    const obj = modelProduto({
      nome,
      descricao,
      tipoProduto,
      publico,
      idCategoria: parseInt(idCategoria),
      descontoAutomatico,
      valor: parseValor(valor),
    });
    if (id) obj.id = id; // incluir id em caso de edição

    confirmar('Tem certeza que deseja salvar o produto com os dados preenchidos?', () => cadastrarProduto(obj));
  };

  const cadastrarProduto = async produto => {
    try {
      setCarregando(true);
      fecharConfirmacao();
      let operacao;
      if (id) operacao = await Funci.alterarTitulo(id, produto);
      else operacao = await Funci.incluirProduto(produto);
      if (operacao === true || operacao.id) {
        history.goBack();
      } else if (operacao.erro) {
        alertar('danger', operacao.erro);
      } else {
        alertar('error', 'Erro desconhecido na operação.');
      }
    } catch (error) {
      alertar('danger', error);
    } finally {
      setCarregando(false);
    }
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const cat = await Funci.listarCategoriasProdutos();
        if (cat.erro) alertar('danger', cat.erro);
        if (cat && cat.length > 0) {
          setCategorias(cat);
        }

        // Consultar o produto caso venha o o ID para edição
        if (id) {
          const prod = await Funci.consultarProdutoPorId(id);
          if (prod.erro) alertar('danger', prod.erro);
          if (prod.id) {
            setProduto(modelProduto(prod));
            reset({
              tipoProduto: prod.tipoProduto,
              publico: prod.publico,
              idCategoria: prod.idCategoria,
              descontoAutomatico: prod.descontoAutomatico,
              valor: formatarValor(prod.valor),
            });
          }
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, reset]);

  return (
    <Container fluid>
      <br />
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      <Card>
        <Card.Header as="h6">{produto && produto.id ? 'Editar produto' : 'Novo produto'}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
            <Row className="justify-content-between">
              <Col>
                <Row>
                  <Col>
                    <Form.Group controlId="nome" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Nome <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="nome"
                        maxLength={40}
                        defaultValue={produto.nome || ''}
                        ref={register({
                          required: 'É necessário informar um nome para o produto.',
                          minLength: { value: 3, message: 'Nome deve conter no mínimo 3 caracteres.' },
                          maxLength: { value: 40, message: 'Nome deve conter no máximo 40 caracteres.' },
                        })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.nome && errors.nome.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="descricao" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">Descrição</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={150}
                        name="descricao"
                        defaultValue={produto.descricao || ''}
                        ref={register({
                          maxLength: { value: 150, message: 'Descrição de conter no máximo 150 caracteres.' },
                        })}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.descricao && errors.descricao.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="3">
                    <Form.Group controlId="tipoProduto" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Tipo de Produto<Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select
                        aria-label="Tipo de Produto"
                        name="tipoProduto"
                        ref={register({ required: 'É necessário informar um tipo de produto.' })}
                      >
                        <>
                          <option value="">{''}</option>
                          {tiposProduto.map(u => (
                            <option key={`${u.value}`} value={`${u.value}`}>{`${u.label}`}</option>
                          ))}
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.tipoProduto && errors.tipoProduto.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="3">
                    <Form.Group controlId="publico" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Público <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select aria-label="Público" name="publico" ref={register({ required: 'É necessário informar um público.' })}>
                        <>
                          <option value="">{''}</option>
                          <option value="A">{'TODOS'}</option>
                          <option value="N">{'NÃO SÓCIOS'}</option>
                          <option value="S">{'SÓCIOS'}</option>
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.publico && errors.publico.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="3">
                    <Form.Group controlId="idCategoria" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Categoria <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select aria-label="Categoria" name="idCategoria" ref={register({ required: 'É necessário informar uma categoria.' })}>
                        <>
                          <option value="">{''}</option>
                          {categorias.map(u => (
                            <option key={`${u.id}`} value={`${u.id}`}>{`${u.nome}`}</option>
                          ))}
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.idCategoria && errors.idCategoria.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col xs="2">
                    <Form.Group controlId="descontoAutomatico" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">
                        Desconto automático <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                      </Form.Label>
                      <Form.Select
                        aria-label="Desconto automático"
                        name="descontoAutomatico"
                        ref={register({ required: 'É necessário informar um indicador de desconto automático.' })}
                      >
                        <>
                          <option value="">{''}</option>
                          <option value="N">{'NÃO'}</option>
                          <option value="S">{'SIM'}</option>
                        </>
                      </Form.Select>
                      <Col as={Card.Text} className="text-danger">
                        {errors.descontoAutomatico && errors.descontoAutomatico.message}
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="2">
                    <Form.Group controlId="valor" className="d-flex flex-column align-items-start">
                      <Form.Label className="mt-2 mb-0">Valor (R$)</Form.Label>
                      <Controller
                        render={props => (
                          <Form.Control
                            className="d-flex justify-content-end"
                            type="text"
                            placeholder="Valor"
                            value={props.value}
                            onChange={e => props.onChange(formatarValor(e.target.value))}
                            ref={props.ref} // wire up the input ref
                          />
                        )}
                        name="valor"
                        defaultValue={formatarValor(produto.valor)}
                        control={control}
                        rules={{
                          validate: value => (value && validarValor(value, true, true, false)) || 'Valor inválido.',
                        }}
                      />
                      <Col as={Card.Text} className="text-danger">
                        {errors.valor && errors.valor.message}
                      </Col>
                    </Form.Group>
                  </Col>
                  {produto && produto.id && (
                    <Col className="d-flex flex-column align-items-end">
                      <br />
                      <Card.Text className="fw-bold">
                        {`Status: `}
                        <span className={`${statusColors[produto.statusLabel] || 'text-secondary'}`}>{`${produto.statusLabel}`}</span>
                      </Card.Text>
                    </Col>
                  )}
                </Row>
                <br />
                <hr />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="success" type="submit">
                  {id ? 'Salvar' : 'Cadastrar produto'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
});
