import validarTipo from './validarTipo';

/**
 * campoObrigatorio
 *
 * @param {*} campo
 * @param {string} nomeCampo
 * @param {array} [tipos=[]]
 * @param {number} min (apenas para strings)
 * @param {number} max (apenas para strings)
 * @param {array} [dominio=[]]
 * @returns
 */
const obrig = (campo, nomeCampo, tipos = [], min = 1, max = 2000, dominio = []) => {
  // Valida Campo
  if (!campo && campo !== 0) throw new Error(`Campo ${nomeCampo} obrigatório.`);
  if (!Array.isArray(tipos)) throw new Error('Erro na declaração do modelo. Tipo deve ser um array.');
  if (!Array.isArray(dominio)) throw new Error('Erro na declaração do modelo. Dominio deve ser um array caso seja informado.');

  // Valida tipo
  let tipoMatch = 0;
  tipos.forEach(tipo => {
    if (validarTipo(campo, tipo)) tipoMatch += 1;
  });
  if (tipoMatch === 0) throw new Error(`Campo ${nomeCampo} com formato inválido. Esperado ${tipos.join(' | ')}`);

  // Valida min max
  if (validarTipo(campo, 'string')) {
    if (campo.length < min) throw new Error(`Campo ${nomeCampo} deve ter no mínimo ${min} caracteres.`);
    if (campo.length > max) throw new Error(`Campo ${nomeCampo} deve ter no máximo ${max} caracteres.`);
  }
  // Valida dominio
  let dominioMatch = 0;
  dominio.forEach(d => {
    if (campo === d) dominioMatch += 1;
  });
  if (dominioMatch === 0 && dominio.length > 0) throw new Error(`Campo ${nomeCampo} com conteúdo inválido. Esperado ${tipos.join(' | ')}`);
  return campo;
};

export default obrig;
