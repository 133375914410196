import moment from 'moment';

/**
 * @method modelLocalAcesso
 * @description Modelo padrão de objeto Local de Acesso
 * @param {string} codigo - codigo do Local de Acesso
 * @param {string} nome - nome do Local de Acesso
 * @param {number} status - status do Local de Acesso (A: Ativo; I: Inativo).
 * @param {number} statusLabel - label do status do Local de Acesso. Derivado de cálculo.
 * @param {string} timestamp - data de criação do Local de Acesso.
 * @returns {object} Objeto de Acesso formatado
 */
export default ({ codigo, nome, status = 'A', timestamp }) => {
  const statusLabels = {
    A: 'ATIVO',
    I: 'INATIVO',
  };
  const statusLabel = statusLabels[status];
  return {
    codigo: codigo.toString(),
    nome: nome.toString(),
    status,
    statusLabel,
    timestamp: timestamp ? `${moment(timestamp).format('DD/MM/YYYY HH:mm:ss')}` : null,
  };
};
