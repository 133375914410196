import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { Voltar, Carregando, Confirmacao } from '../../components';
import { Funci } from '../../services';
import { alertar, formatarValor, gerarTextoInstrucoes } from '../../utils';
import { modelTitulo } from '../../models';
import { api } from '../../constants';

export default withRouter(props => {
  const { history, location } = props;
  const { titulo } = useParams();
  const { atualizarTitulos = () => {} } = location.state;
  const [atualizar, setAtualizar] = useState(0);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [carregando, setCarregando] = useState(true);
  const [objTitulo, setObjTitulo] = useState(null);

  const statusColors = {
    CANCELADO: 'text-danger',
    ATIVO: 'text-primary',
  };
  const statusAssociacaoColors = {
    ATIVO: 'text-primary',
    CANCELADO: 'text-danger',
    PROTOCOLO: 'text-warning',
    SUSPENSO: 'text-secondary',
    INATIVO: 'text-secondary',
  };
  const disableBotoes = {
    PENDENTE: { canc: false, imp: true, mail: true, baixa: true },
    PROCESSANDO: { canc: false, imp: true, mail: true, baixa: true },
    CANCELADO: { canc: true, imp: true, mail: true, baixa: true },
    REGISTRADO: { canc: false, imp: false, mail: false, baixa: false },
    PAGO: { canc: true, imp: true, mail: true, baixa: true },
    BAIXADO: { canc: true, imp: true, mail: true, baixa: true },
  };
  const getStatusAssociacao = status => {
    if (status === 'A') return 'ATIVO';
    if (status === 'C') return 'CANCELADO';
    if (status === 'P') return 'PROTOCOLO';
    if (status === 'S') return 'SUSPENSO';
    if (status === 'L') return 'INATIVO';
    return 'DESCONHECIDO';
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  const cancelarTitulo = async () => {
    setCarregando(true);
    fecharConfirmacao();
    try {
      const atualizacao = await Funci.cancelarTitulo(titulo);
      if (atualizacao.erro) {
        alertar('danger', atualizacao.erro);
      } else if (atualizacao === true) {
        alertar('success', 'Solicitação realizada com sucesso.');
        setAtualizar(atualizar + 1);
      } else {
        alertar('warning', 'Não foi possível executar a solicitação.');
      }
    } catch (e) {
      alertar('danger', e);
    } finally {
      setCarregando(false);
    }
  };

  const atualizarTitulo = async () => {
    setAtualizar(atualizar + 1);
    atualizarTitulos();
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const consulta = await Funci.consultarTitulo(titulo);
        console.log('consulta', consulta);
        if (consulta.erro) alertar('danger', consulta.erro);
        if (consulta.titulo) {
          const b = modelTitulo(consulta);
          setObjTitulo(b);
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [titulo, atualizar]);

  return (
    <>
      <Col as="div" className="rolagem">
        <Container fluid>
          <Carregando show={carregando} />
          <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
          <br />
          <Row className="justify-content-between">
            <Voltar />
          </Row>

          <Card>
            <Card.Header as="h6">Detalhe título</Card.Header>
            <Card.Body>
              {objTitulo && (
                <>
                  <Row>
                    <Col>
                      <Card.Text className="fw-bold">{`Número do título: ${objTitulo.titulo}`}</Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Categoria: ${objTitulo.categoria || ''}`}</Card.Text>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Card.Text className="fw-bold">
                        {`Status: `}
                        <span className={`${statusColors[objTitulo.statusLabel] || 'text-secondary'}`}>{`${objTitulo.statusLabel}`}</span>
                      </Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Título criado em: ${objTitulo.timestamp || ''}`}</Card.Text>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Card.Text className="fw-bold">{`Sócio: ${objTitulo.nome || ''}`}</Card.Text>
                    <Col>
                      <Card.Text>
                        {`Status da associação: `}
                        <span
                          className={`${statusAssociacaoColors[objTitulo.statusAssociacaoLabel] || 'text-secondary'}`}
                        >{`${objTitulo.statusAssociacaoLabel}`}</span>
                      </Card.Text>
                    </Col>
                    <Col>
                      <Card.Text>{`Data da associação: ${objTitulo.dataAssociacao || ''}`}</Card.Text>
                    </Col>
                  </Row>
                  <hr />
                  <Card.Text className="fw-bold">Histórico de associações</Card.Text>
                  {objTitulo.historicoAssociacoes &&
                    objTitulo.historicoAssociacoes.length > 0 &&
                    objTitulo.historicoAssociacoes.map((a, i) => (
                      <Row key={`${i}-${a.id}-${a.titulo}`}>
                        <Col>
                          <Card.Text>
                            {`Nome: `}
                            <strong>{a.nome || ''}</strong>
                          </Card.Text>
                        </Col>
                        <Col>
                          <Card.Text>
                            {`Data da Associação: `}
                            <strong>{modelTitulo(a).dataAssociacao || ''}</strong>
                          </Card.Text>
                        </Col>
                        <Col>
                          <Card.Text>
                            {`Status: `}
                            <strong>{modelTitulo(a).statusAssociacaoLabel || ''}</strong>
                          </Card.Text>
                        </Col>
                      </Row>
                    ))}
                  {objTitulo.historicoAssociacoes && objTitulo.historicoAssociacoes.length <= 0 && (
                    <Row>
                      <Col>
                        <Card.Text>Sem histórico de associações</Card.Text>
                      </Col>
                    </Row>
                  )}
                  <br />
                </>
              )}
              <hr />
              {objTitulo && (
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                      Voltar
                    </Button>
                    <Button
                      className="mx-2"
                      variant="danger"
                      disabled={objTitulo.status === 'C'}
                      onClick={() => confirmar('Tem certeza que deseja cancelar o título?', cancelarTitulo)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className="mx-2"
                      variant="success"
                      onClick={() => history.push('/titulo-editar', { titulo, atualizarTitulos: atualizarTitulo })}
                    >
                      Editar
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Col>
    </>
  );
});
