import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';

import { Voltar, Confirmacao, Carregando, Campo, Foto } from '../../components';
import { alertar, formatarValor, parseValor, validarValor } from '../../utils';
import { modelProduto } from '../../models';
import { Funci } from '../../services';
import moment from 'moment';

export default withRouter(props => {
  const { history, location } = props;
  const { pessoa, id } = location.state || {};
  const { handleSubmit, register, errors, control, reset, watch, getValues } = useForm();
  const [carregando, setCarregando] = useState(false);
  const [confirmacao, setConfirmacao] = useState({ mensagem: '', show: false });
  const [contratacao, setContratacao] = useState({});
  const [produtos, setProdutos] = useState([]);
  const [formasPagto, setFormasPagto] = useState([]);
  const [valorProduto, setValorProduto] = useState(0);
  const [valorFinal, setValorFinal] = useState(0);
  const watchIdProduto = watch('idProduto', 0);
  const watchDesconto = watch('desconto', 0);

  const statusColors = {
    ATIVO: 'text-primary',
    INATIVO: 'text-secondary',
  };

  const arrParcelas = [];
  for (let index = 0; index < 99; index++) {
    arrParcelas.push(index);
  }

  const onSubmit = dados => {
    const { idProduto, dt1Parcela, nrParcelas, periodicidade, idFormaPagto, desconto, textoComplemento } = dados;

    // Verificação dos campos obrigatórios é feita pelas regras do form e no backend
    const filtro = produtos.filter(p => p.id === parseInt(getValues('idProduto'), 10));
    if (!filtro.length === 1) return alertar('danger', 'Valor do produto não encontrado.');

    // Montar o objeto de produto
    const obj = {
      idPessoa: pessoa.id,
      idProduto,
      dt1Parcela,
      nrParcelas: parseInt(nrParcelas),
      vlProduto: filtro[0].valor,
      periodicidade: parseInt(periodicidade),
      idFormaPagto: parseInt(idFormaPagto),
      desconto: parseValor(desconto),
      textoComplemento,
    };
    confirmar('Tem certeza que deseja contratar o produto com os dados preenchidos?', () => cadastrarContratacao(obj));
  };

  const cadastrarContratacao = async produto => {
    try {
      setCarregando(true);
      fecharConfirmacao();

      const operacao = await Funci.incluirContratacao(produto);
      console.log('operacao', operacao);
      if (operacao.ordem || operacao.ordem === 0) {
        history.goBack();
      } else if (operacao.erro) {
        alertar('danger', operacao.erro);
      } else {
        alertar('error', 'Erro desconhecido na operação.');
      }
    } catch (error) {
      alertar('danger', error);
    } finally {
      setCarregando(false);
    }
  };

  const confirmar = (mensagem = '', onConfirm = () => {}) => {
    setConfirmacao({ mensagem, onConfirm, onCancel: fecharConfirmacao, show: true });
  };

  const fecharConfirmacao = () => {
    setConfirmacao({ mensagem: '', onConfirm: () => {}, onCancel: fecharConfirmacao, show: false });
  };

  useEffect(() => {
    const filtro = produtos.filter(p => p.id === parseInt(getValues('idProduto'), 10));
    if (filtro.length === 1) {
      setValorProduto(filtro[0].valor);
    } else if (valorProduto) setValorProduto('');
    const perDesconto = parseValor(getValues('desconto'));
    if (perDesconto <= 100) {
      const calculo = parseValor(valorProduto) * (1 - parseValor(getValues('desconto', 0)) / 100);
      if (!Number.isNaN(calculo)) setValorFinal(calculo.toFixed(2));
      else setValorFinal(valorProduto);
    } else {
      setValorFinal(valorProduto);
    }
  }, [watchIdProduto, watchDesconto, getValues, produtos, valorProduto]);

  useEffect(() => {
    const inicializar = async () => {
      try {
        setCarregando(true);
        const prod = await Funci.buscarProdutosPorCriterio('', 1, 999999, 'nome', 'ASC');
        if (prod.erro) alertar('danger', prod.erro);
        if (prod && prod.length > 0) {
          setProdutos(prod.filter(p => p.status === 'A'));
        }

        const formas = await Funci.listarFormasPagto();
        if (formas.erro) alertar('danger', formas.erro);
        if (formas && formas.length > 0) {
          setFormasPagto(formas);
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [id, reset]);

  return (
    <Container fluid>
      <br />
      <Carregando show={carregando} />
      <Confirmacao show={confirmacao.show} mensagem={confirmacao.mensagem} onConfirm={confirmacao.onConfirm} onCancel={confirmacao.onCancel} />
      <Row className="justify-content-between">
        <Voltar />
      </Row>

      <Card>
        <Card.Header as="h6">{contratacao && contratacao.idProduto ? 'Editar contratação' : 'Nova contratação'}</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-2">
            <Row>
              <Col>
                <Campo label="Nome:" valor={pessoa.nome} />
                <br />
                <Campo label="Categorias:" valor={pessoa.associacoes && pessoa.associacoes.map(a => a.categoria).join(',')} />
              </Col>
              <Col className="d-flex justify-content-end">
                <Foto id={`${pessoa.id_pessoa_lg}${pessoa.cod_dependente_lg || ''}`} height={80} />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <Form.Group controlId="idProduto" className="d-flex flex-column align-items-start">
                  <Form.Label className="mt-2 mb-0">
                    Produto <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                  </Form.Label>
                  <Form.Select aria-label="Produto" name="idProduto" ref={register({ required: 'É necessário informar um produto.' })}>
                    <>
                      <option value="">{''}</option>
                      {produtos.map(u => (
                        <option key={`${u.id}`} value={`${u.id}`}>{`${u.nome}`}</option>
                      ))}
                    </>
                  </Form.Select>
                  <Col as={Card.Text} className="text-danger">
                    {errors.idProduto && errors.idProduto.message}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col xs="3">
                <Form.Group controlId="textoComplemento" className="d-flex flex-column align-items-start">
                  <Form.Label className="mt-3 mb-0">Texto complemento</Form.Label>
                  <Form.Control
                    type="text"
                    name="textoComplemento"
                    maxLength={20}
                    ref={register({
                      maxLength: { value: 20, message: 'Texto deve conter no máximo 20 caracteres.' },
                    })}
                  />
                  <Col as={Card.Text} className="text-danger">
                    {errors.textoComplemento && errors.textoComplemento.message}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs="3">
                <Form.Group controlId="periodicidade" className="d-flex flex-column align-items-start">
                  <Form.Label className="mt-2 mb-0">
                    Periodicidade <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                  </Form.Label>
                  <Form.Select
                    aria-label="Periodicidade"
                    name="periodicidade"
                    ref={register({ required: 'É necessário informar uma periodicidade.' })}
                  >
                    <>
                      <option value="">{''}</option>
                      <option value="1">{'MENSAL'}</option>
                      <option value="2">{'ANUAL'}</option>
                      <option value="3">{'AVULSO'}</option>
                    </>
                  </Form.Select>
                  <Col as={Card.Text} className="text-danger">
                    {errors.periodicidade && errors.periodicidade.message}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col xs="3">
                <Form.Group controlId="idFormaPagto" className="d-flex flex-column align-items-start">
                  <Form.Label className="mt-2 mb-0">
                    Forma de Pagamento<Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                  </Form.Label>
                  <Form.Select
                    aria-label="Forma de Pagamento"
                    name="idFormaPagto"
                    ref={register({ required: 'É necessário informar uma forma de pagamento.' })}
                  >
                    <>
                      <option value="">{''}</option>
                      {formasPagto.map(u => (
                        <option key={`${u.id}`} value={`${u.id}`}>{`${u.descricao}`}</option>
                      ))}
                    </>
                  </Form.Select>
                  <Col as={Card.Text} className="text-danger">
                    {errors.idFormaPagto && errors.idFormaPagto.message}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs="3">
                <Form.Group controlId="nrParcelas" className="d-flex flex-column align-items-start">
                  <Form.Label className="mt-2 mb-0">
                    Número de Parcelas <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                  </Form.Label>
                  <Form.Select
                    aria-label="Número de Parcelas"
                    name="nrParcelas"
                    ref={register({ required: 'É necessário informar um número de Parcelas.' })}
                  >
                    <>
                      {arrParcelas.map(p => (
                        <option key={`${p}`} value={`${p}`}>{`${p}`}</option>
                      ))}
                    </>
                  </Form.Select>
                  <Col as={Card.Text} className="text-danger">
                    {errors.nrParcelas && errors.nrParcelas.message}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs="3">
                <Form.Group controlId="dt1Parcela" className="d-flex flex-column align-items-end">
                  <Form.Label className="mt-2 mb-0">
                    Data 1ª Parcela <Form.Label className="mt-2 mb-0 text-danger">*</Form.Label>
                  </Form.Label>
                  <Form.Control
                    name="dt1Parcela"
                    type="date"
                    ref={register({
                      required: { value: true, message: 'Informe uma data para primeira parcela.' },
                      validate: value =>
                        moment(value) >= moment().startOf('day') || 'Data primeira parcela deve ser igual ou posterior à data atual.',
                    })}
                  />
                  <Col as={Card.Text} className="text-danger">
                    {errors.dt1Parcela && errors.dt1Parcela.message}
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between">
              <Col xs="3">
                <br />
                <br />
                <Campo label="Valor Base:" valor={valorProduto ? `R$ ${formatarValor(valorProduto)}` : 'R$ 0,00'} />
              </Col>
              <Col xs="3">
                <Form.Group controlId="desconto" className="d-flex flex-column align-items-start">
                  <Form.Label className="mt-2 mb-0">Desconto (%)</Form.Label>
                  <Controller
                    render={props => (
                      <Form.Control
                        className="d-flex justify-content-end"
                        type="text"
                        placeholder="Desconto"
                        value={props.value}
                        onChange={e => props.onChange(formatarValor(e.target.value))}
                        ref={props.ref} // wire up the input ref
                      />
                    )}
                    name="desconto"
                    defaultValue={formatarValor(contratacao.valor)}
                    control={control}
                    rules={{
                      validate: value =>
                        (value && validarValor(value, true, true, false) && parseValor(value) <= 100) || 'Percentual deve ser inferior a 100%.',
                    }}
                  />
                  <Col as={Card.Text} className="text-danger">
                    {errors.desconto && errors.desconto.message}
                  </Col>
                </Form.Group>
              </Col>
              <Col xs="3">
                <br />
                <br />
                <Campo label="Valor Final:" valor={valorFinal ? `R$ ${formatarValor(valorFinal)}` : ''} />
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col className="d-flex justify-content-center">
                <Button className="mx-2" variant="outline-secondary" onClick={() => history.goBack()}>
                  Voltar
                </Button>
                <Button className="mx-2" variant="secondary" onClick={() => reset()}>
                  Limpar
                </Button>
                <Button className="mx-2" variant="success" type="submit">
                  Contratar
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
});
