import { validarEmail } from '../utils';
import modelPerfil from './modelPerfil';

/**
 * @method modelUsuario
 * @description Modelo padrão de objeto da classe Usuario
 * @param {string} uid - Id do usuário
 * @param {string} nome - Nome
 * @param {string} apelido - apelido
 * @param {string} email - Email cadastrado
 * @param {string} titulo - Número do título Minas Brasilia
 * @param {string} superUsuario - inicador de super usuário
 * @param {string} perfis - lista de perfis habilitados para o usuário
 * @param {number} status - Situação do usuário [A: Ativo; I: Inativo]
 * @param {string} timestamp - data de criação do usuario.
 * @returns {object} Objeto de Usuário formatado
 */
export default ({ uid, nome = '', apelido = '', email, titulo = '', timestamp = new Date(), status = 'A', superUsuario = 'N', perfis = [] }) => {
  const getStatusLabel = () => {
    if (status === 'A') return 'ATIVO';
    if (status === 'I') return 'INATIVO';
    return 'INVÁLIDO';
  };

  return {
    uid,
    nome,
    apelido,
    email: validarEmail(email) ? email : '',
    titulo,
    timestamp,
    status,
    statusLabel: getStatusLabel(),
    superUsuario: superUsuario === 'S' ? true : false,
    perfis: perfis.map(p => modelPerfil(p)),
  };
};
