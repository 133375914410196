/**
 * Retorna um array de objetoss agrupado pela propriedade específica
 *
 * @param {array} array - array de objetos
 * @param {string} propriedade - propriedade do objeto para o agrupamento
 * @memberof Funci
 */
export default (array, propriedade) => {
  const unique = [];
  const distinct = [];
  for (let i = 0; i < array.length; i++) {
    if (!unique[array[i][propriedade]]) {
      distinct.push(array[i]);
      unique[array[i][propriedade]] = 1;
    }
  }
  return distinct;
};
