import moment from 'moment';
import formatarCPF from '../utils/formatarCPF';

/**
 * @method modelExameMedico
 * @description Modelo padrão de objeto da classe ExameMedico
 * @param {number} id - id do exame médico
 * @param {string} tipoPessoa - tipo de pessoa [Titular, Dependente, Visitante]
 * @param {number} cpf - cpf do examinado, caso seja visitante
 * @param {string} idPessoa - id interno da pessoa
 * @param {string} idPessoaTitular - idPessoa do titular. campo legado do Aclon
 * @param {string} codDependente - código de depentente caso houver. campo legado do Aclon
 * @param {string} titulo - Número do título Minas Brasilia
 * @param {string} categoria - categoria do título
 * @param {string} nome - nome da pessoa
 * @param {string} nomeRdzd - nome reduzido ou apelido da pessoa
 * @param {string} statusAssociacao - status da associação
 * @param {string} statusExame - status do exame
 * @param {timestamp} dataEmissao - data de emissão do exame
 * @param {timestamp} dataValidade - data de validade do exame
 * @param {timestamp} dataValidacao - data de validacao do exame
 * @param {number} nrRevalidacoes - número de revalidações do exame
 * @param {string} uidResponsavel - uid do responsável pelo cadastro do exame
 * @param {string} uidValidador - uid do responsável pela validação do exame
 * @param {string} nomeResponsavel - nome do responsável pelo cadastro do exame
 * @param {string} nomeValidador - nome do responsável pela validação do exame
 * @param {string} local - tipo de local onde o exame foi realizado {I: Interno, E: Externo}
 * @param {string} observacoes - anotação de observações no exame
 * @param {timestamp} timestamp - timestamp do registro
 * @returns {object} Objeto de Usuário formatado
 */
export default ({
  id,
  tipoPessoa,
  cpf = '',
  idPessoa = '',
  idPessoaTitular = '',
  codDependente = '',
  titulo = '',
  categoria = '',
  nome = '',
  nomeRdzd = '',
  statusAssociacao = 'A',
  statusExame,
  dataEmissao,
  dataValidade,
  dataValidacao,
  nrRevalidacoes = 0,
  uidResponsavel,
  uidValidador = '',
  nomeResponsavel = '',
  nomeValidador = '',
  local,
  observacoes = '',
  timestamp = new Date(),
}) => {
  const getIdentificacao = () => {
    if (tipoPessoa === 'Titular') return `${titulo}`;
    if (tipoPessoa === 'Dependente') return `${titulo} - ${codDependente}`;
    if (tipoPessoa === 'Visitante') return `${formatarCPF(cpf)}`;
    return '';
  };
  const getStatusAssociacaoLabel = () => {
    if (statusAssociacao === 'A') return 'ATIVO';
    if (statusAssociacao === 'C') return 'CANCELADO';
    if (statusAssociacao === 'S') return 'SUSPENSO';
    if (statusAssociacao === 'P') return 'PROTOCOLO';
    if (statusAssociacao === 'L') return 'INATIVO';
    return '';
  };
  const getStatusExameLabel = () => {
    if (statusExame === 'A' && moment(dataValidade) > moment().endOf('day')) {
      if (moment(dataValidacao) <= moment().endOf('day')) return 'ATIVO';
      return 'NÃO VALIDADO';
    }
    if (statusExame === 'I') return 'INATIVO';
    if (statusExame === 'C') return 'CANCELADO';
    return 'VENCIDO';
  };
  const getLocalLabel = () => {
    if (local === 'I') return 'Interno';
    if (local === 'E') return 'Externo';
    return 'Não Definido';
  };

  return {
    id,
    tipoPessoa,
    identificacao: getIdentificacao(),
    cpf: formatarCPF(cpf),
    idPessoa,
    idPessoaTitular,
    codDependente,
    titulo,
    categoria,
    nome,
    nomeRdzd,
    statusAssociacao,
    statusAssociacaoLabel: getStatusAssociacaoLabel(),
    statusExame,
    statusExameLabel: getStatusExameLabel(),
    dataEmissao: dataEmissao && moment(dataEmissao).format('DD/MM/YYYY'),
    dataValidade: dataValidade && moment(dataValidade).format('DD/MM/YYYY'),
    dataValidacao: dataValidacao && moment(dataValidacao).format('DD/MM/YYYY HH:mm:ss'),
    nrRevalidacoes,
    uidResponsavel,
    uidValidador,
    nomeResponsavel,
    nomeValidador,
    local,
    localLabel: getLocalLabel(),
    observacoes,
    timestamp: timestamp && moment(timestamp).format('DD/MM/YYYY HH:mm:ss'),
  };
};
