import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { MemoryRouter, Switch, Route, Prompt, BrowserRouter } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { intersects } from 'semver';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import { Login, Logout } from './containers/auth';
import { HomePage } from './containers/home';
import { AcessosFisicos } from './containers/acessos';
import { Convites, DetalheConvite, NovoConviteAvulso, NovoConviteChurrasqueira } from './containers/convites';
import { Churrasqueiras, DetalheChurrasqueira } from './containers/churrasqueiras';
import { Usuarios, DetalheUsuario } from './containers/usuarios';
import { RegistroRestricao, Restricoes, SelecaoPessoaRestricao } from './containers/restricoes';
import { DetalheReserva, NovaReserva, NovaReservaPasso2, NovaReservaPasso3 } from './containers/reservas';
import { ExameMedico, RegistroExameMedico, DetalheExameMedico, SelecaoPessoaExame } from './containers/exames';
import { Boletos, ConsultaBoletoSocio, DetalheBoleto, NovoBoletoAvulso } from './containers/boletos';
import { Produtos, DetalheProduto, EditarProduto, DetalheProdutoSocio, ContratacaoProduto, LancamentosLote } from './containers/produtos';
import { ImpReservasDia, ImpConvite } from './containers/impressao';

import { PrivateRoute, Carregando, ErroBackend, ReloadApp, NoMatch, SidebarMenu, Header } from './components';
import { firebaseConfig } from './constants';
import { Auth, Funci, Requisicoes } from './services';
import { validarTipo } from './utils';
import { Cadastros, DetalhePessoa, NovaPessoa } from './containers/cadastro';
import { RelatorioBoletosPagosData, Relatorios } from './containers/relatorios';
import { Titulos, DetalheTitulo, EditarTitulo } from './containers/titulos';

const logo = require('./assets/logo/mbtc-logo.png');

initializeApp(firebaseConfig);

// Configurações do toastify
toast.configure();

const RouterImpressao = () => (
  <Switch>
    <Route exact path="/impressao/reservasdia">
      <ImpReservasDia />
    </Route>
    <Route exact path="/impressao/convite/:idConvite">
      <ImpConvite />
    </Route>
    {/* ************************************************************************************* */}
    {/* NOMATCH */}
    {/* ************************************************************************************* */}
    <Route path="*">
      <NoMatch />
    </Route>
  </Switch>
);

const RouterApp = () => {
  const [carregando, setCarregando] = useState(true);
  const [collapse, setCollapse] = useState('');
  const funci = new Funci();
  const backgroundColors = {
    PRODUCAO: 'bg-dark',
    HM: 'bg-danger',
    DESENV: 'bg-danger',
    LOCAL: 'bg-info',
  };
  const modoHomologacao = funci.getAmbiente() !== 'PRODUCAO';
  const bgColor = backgroundColors[funci.getAmbiente()] || 'bg-danger';

  // Controle de do perfil de acesso
  useEffect(() => {
    const inicializar = async () => {
      // Recupera os perfis do usuário caso ainda não tenham sido obtidos
      if (!funci.perfisCarregados) {
        await funci.atualizarPerfisUsuario();
      }
      setCarregando(false);
    };
    inicializar();
  }, [funci]);

  if (carregando) return <Carregando />;
  // *********************************************************************
  // * MONTAGEM DO MENU                                                  *
  // *********************************************************************
  return (
    <MemoryRouter
      getUserConfirmation={(message, callback) => {
        const allowTransition = window.confirm(message);
        callback(allowTransition);
      }}
    >
      <Prompt when={false} message="Are you sure you want to leave?" />
      <div className="d-flex flex-column w-100 m-0" style={{ height: '100vh', width: '100vw' }}>
        {/* HEADER */}
        <Header />

        {/* SIDEBAR MENU */}
        <SidebarMenu />

        {/* CONTAINERS */}
        <div className="app-container rolagem">
          <Container fluid={true}>
            <Switch>
              {/* ************************************************************************************* */}
              {/* CONVITES */}
              {/* ************************************************************************************* */}
              <Route exact path="/convites/:paramTitulo">
                <Convites />
              </Route>
              <Route exact path="/detalheconvite/:idConvite">
                <DetalheConvite />
              </Route>
              <Route exact path="/novoconviteavulso">
                <NovoConviteAvulso />
              </Route>
              <Route exact path="/novoconvitechurrasqueira/:idReserva">
                <NovoConviteChurrasqueira />
              </Route>

              {/* ************************************************************************************* */}
              {/* CHURRASQUEIRAS E RESERVAS */}
              {/* ************************************************************************************* */}
              <Route exact path="/churrasqueiras/:tab">
                <Churrasqueiras />
              </Route>
              <Route exact path="/detalhechurrasqueira/:idChurrasqueira">
                <DetalheChurrasqueira />
              </Route>
              <Route exact path="/detalhereserva/:idReserva">
                <DetalheReserva />
              </Route>
              <Route exact path="/novareserva/:titulo">
                <NovaReserva />
              </Route>
              <Route exact path="/novareservaPasso2">
                <NovaReservaPasso2 />
              </Route>
              <Route exact path="/novareservaPasso3">
                <NovaReservaPasso3 />
              </Route>

              {/* ************************************************************************************* */}
              {/* EXAME MÉDICO */}
              {/* ************************************************************************************* */}
              <Route exact path="/exames">
                <ExameMedico />
              </Route>
              <Route exact path="/detalheexame/:id">
                <DetalheExameMedico />
              </Route>
              <Route exact path="/exames/novo">
                <SelecaoPessoaExame />
              </Route>
              <Route exact path="/exames/registro">
                <RegistroExameMedico />
              </Route>

              {/* ************************************************************************************* */}
              {/* BOLETOS */}
              {/* ************************************************************************************* */}
              <Route exact path="/boletos/:tab">
                <Boletos />
              </Route>
              <Route exact path="/boletosnovoavulso">
                <NovoBoletoAvulso />
              </Route>
              <Route exact path="/detalheboleto/:id">
                <DetalheBoleto />
              </Route>
              <Route exact path="/consultaboletosocio">
                <ConsultaBoletoSocio />
              </Route>

              {/* ************************************************************************************* */}
              {/* PRODUTOS */}
              {/* ************************************************************************************* */}
              <Route exact path="/produtos">
                <Produtos />
              </Route>
              <Route exact path="/editarproduto">
                <EditarProduto />
              </Route>
              <Route exact path="/detalheproduto/:id">
                <DetalheProduto />
              </Route>
              <Route exact path="/detalheprodutosocio">
                <DetalheProdutoSocio />
              </Route>
              <Route exact path="/contratacaoproduto">
                <ContratacaoProduto />
              </Route>
              <Route exact path="/lancamentos-lote">
                <LancamentosLote />
              </Route>

              {/* ************************************************************************************* */}
              {/* CADASTROS */}
              {/* ************************************************************************************* */}
              <Route exact path="/cadastros">
                <Cadastros />
              </Route>
              <Route exact path="/detalhecadastro/:id">
                <DetalhePessoa />
              </Route>
              <Route exact path="/novocadastro">
                <NovaPessoa />
              </Route>

              {/* ************************************************************************************* */}
              {/* RESTRIÇÕES */}
              {/* ************************************************************************************* */}
              <Route exact path="/restricoes">
                <Restricoes />
              </Route>
              <Route exact path="/restricoes/nova">
                <SelecaoPessoaRestricao />
              </Route>
              <Route exact path="/restricoes/registro">
                <RegistroRestricao />
              </Route>

              {/* ************************************************************************************* */}
              {/* TITULOS */}
              {/* ************************************************************************************* */}
              <Route exact path="/titulos">
                <Titulos />
              </Route>
              <Route exact path="/titulo-detalhe/:titulo">
                <DetalheTitulo />
              </Route>
              <Route exact path="/titulo-editar">
                <EditarTitulo />
              </Route>

              {/* ************************************************************************************* */}
              {/* RELATÓRIOS */}
              {/* ************************************************************************************* */}
              <Route exact path="/relatorios">
                <Relatorios />
              </Route>
              <Route exact path="/relatorios/bb-boletos-pagos-data">
                <RelatorioBoletosPagosData />
              </Route>

              {/* ************************************************************************************* */}
              {/* USUÁRIOS */}
              {/* ************************************************************************************* */}
              <Route exact path="/usuarios/:paramTitulo">
                <Usuarios />
              </Route>
              <Route exact path="/detalhe-usuario/:uid">
                <DetalheUsuario />
              </Route>

              {/* ************************************************************************************* */}
              {/* USUÁRIOS */}
              {/* ************************************************************************************* */}
              <Route exact path="/acessos-fisicos">
                <AcessosFisicos />
              </Route>

              {/* ************************************************************************************* */}
              {/* LOGOUT */}
              {/* ************************************************************************************* */}
              <Route exact path="/logout" component={Logout} />

              {/* ************************************************************************************* */}
              {/* HOME  -       PODE SER TROCADO EM DESENV PARA MAIS AGILIDADE*/}
              {/* ************************************************************************************* */}
              <Route exact path="/">
                {/* <Titulos /> */}
                <HomePage />
              </Route>

              {/* ************************************************************************************* */}
              {/* NOMATCH */}
              {/* ************************************************************************************* */}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Container>
        </div>
      </div>
    </MemoryRouter>
  );
};

function App() {
  const [carregando, setCarregando] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [authed, setAuthed] = useState(false);

  // Instância da classe Auth com os métodos para controle do tempo da sessão

  // Controle de status do login
  useEffect(() => {
    const inicializar = async () => {
      await Auth.onAuth(async usuario => {
        const auth = new Auth();
        const funci = new Funci();
        const info = await funci.loadInfoApp();

        if (!info || info.erro || !info.versaoFrontend || !validarTipo(info.versaoFrontend, 'string')) {
          setRedirect('erro-backend');
        }
        if (info && !info.erro && !intersects(process.env.REACT_APP_VERSION, info.versaoFrontend)) {
          setRedirect('reload-app');
        } else {
          if (usuario) {
            auth.setTimer();
            setAuthed(true);
          } else {
            funci.limparPerfisUsuario();
            auth.clearTimer();
            setAuthed(false);
          }
        }

        setCarregando(false);
      });
    };
    inicializar();
  }, []);

  // Configuração das requisições com base no status de login
  const req = new Requisicoes();
  const { apiKey, appId } = Auth.getApiInfo();
  const { uid } = Auth.getUsuario() || { uid: null };
  Auth.getIdToken().then((token = null) => {
    req.configureAxios(apiKey, appId, token, uid);
  });

  // Atualiza o prazo da sessão conforme uso da aplicação
  document.getElementById('root').addEventListener('click', Auth.refreshTimer);

  if (carregando) {
    return <Carregando />;
  }
  if (redirect && redirect === 'erro-backend') {
    return <ErroBackend />;
  }
  if (redirect && redirect === 'reload-app') {
    return <ReloadApp />;
  }
  return (
    <BrowserRouter>
      <ToastContainer newestOnTop style={{ width: '500px', marginTop: 50 }} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact authed={authed} path="/" component={RouterApp} />
      <PrivateRoute authed={authed} path="/impressao" component={RouterImpressao} />
    </BrowserRouter>
  );
}

export default App;
