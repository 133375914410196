import moment from 'moment';

/**
 * @method modelPessoa
 * @description Modelo padrão de objeto da classe Usuario
 * @param {number} id - Id da pessoa
 * @param {number} idPessoaLg - Id da pessoa (legado aclon)
 * @param {string} codDependente - Código de dependente no sistema Minas Brasilia
 * @param {string} titulo - Número do título Minas Brasilia
 * @param {string} categoria - Categoria do título Minas Brasilia
 * @param {string} nome - Nome
 * @param {string} apelido - Apelido
 * @param {number} cpfCnpj - CPF ou CNPJ
 * @param {string} tipo - Tipo de pessoa [T Titular, D Dependente]
 * @param {string} exameMedico - Possui exame médico válido [S - Sim - N Não]
 * @param {string} status - Situação da pessoa [I - Inativo - A Ativo]
 * @returns {object} Objeto de Pessoa formatado
 */
export default ({
  id,
  id_pessoa_lg,
  cod_dependente_lg = '',
  titulo = '',
  categoria = '',
  nome = '',
  apelido = '',
  tipo,
  cpfCnpj,
  exameMedico = 'N',
  status = 'A',
}) => {
  const getStatusLabel = () => {
    if (status === 'A') return 'ATIVO';
    if (status === 'C') return 'CANCELADO';
    if (status === 'I') return 'INATIVO';
    return 'INVÁLIDO';
  };
  const getExameLabel = () => {
    if (exameMedico === 'S') return 'SIM';
    if (exameMedico === 'N') return 'NÃO';
    return 'INVÁLIDO';
  };
  const getTipoLabel = () => {
    if (tipo === 'T') return 'TITULAR';
    if (tipo === 'D') return 'DEPENDENTE';
    return 'INVÁLIDO';
  };

  return {
    id,
    idPessoa: id_pessoa_lg,
    codDependente: cod_dependente_lg,
    titulo,
    categoria,
    nome,
    apelido,
    apelido,
    tipo,
    tipoLabel: getTipoLabel(),
    cpfCnpj,
    exameMedico: exameMedico ? moment(exameMedico).format('DD/MM/YYYY') : 'VENCIDO',
    // exameMedicoLabel: getExameLabel(),
    status,
    statusLabel: getStatusLabel(),
  };
};
