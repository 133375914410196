import React, { useState, useEffect } from 'react';
import { Button, Col, Container, Card, Row, Form } from 'react-bootstrap';
import { Voltar, Alerta, Carregando } from '../../components';
import { Funci } from '../../services';
import { withRouter, useParams } from 'react-router-dom';

export default withRouter(props => {
  const { history } = props;
  const { titulo } = useParams();
  const [carregando, setCarregando] = useState(false);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [validatedTitulo, setValidatedTitulo] = useState(false);
  const [pTitulo, setPTitulo] = useState('');
  const [socio, setSocio] = useState({});

  const handleSubmit = (event, formulario) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    form.checkValidity();
    if (formulario === 'titulo') setValidatedTitulo(true);
  };

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  const consultarSocio = async tituloSocio => {
    history.push(`/novareserva/${tituloSocio || '0'}`);
  };

  useEffect(() => {
    const inicializar = async tituloSocio => {
      fecharAlerta();
      setSocio({});
      setCarregando(true);
      try {
        if (!tituloSocio) return null;
        const consultaSocio = await Funci.consultarSocioPorTitulo(tituloSocio);
        if (consultaSocio.erro) {
          alertar('danger', consultaSocio.erro);
        } else {
          setSocio(consultaSocio);
        }
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };

    if (titulo && titulo !== '0') inicializar(titulo);
  }, [titulo]);

  const navigateNext = () => {
    if (!socio.titulo) return alertar('danger', 'Sócio não selecionado.');
    history.push('/novareservapasso2', { socio });
  };

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Row className="justify-content-between">
        <Voltar />
        {alerta.show ? <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} /> : null}
      </Row>

      <Card>
        <Card.Header as="h6">Nova reserva</Card.Header>
        <Card.Body>
          <Form.Label>Selecione um sócio:</Form.Label>
          <Row className="pb-2">
            <Col>
              <Form noValidate validated={validatedTitulo} onSubmit={event => handleSubmit(event, 'titulo')} className="mt-2">
                <Row className="justify-content-start">
                  <Form.Group as={Col} xs="auto" controlId="titulo" className="align-middle mb-0">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Título do associado"
                      value={pTitulo}
                      onChange={event => setPTitulo(event.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Título não informado</Form.Control.Feedback>
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit" onClick={() => consultarSocio(pTitulo)}>
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            {socio.titulo ? (
              <React.Fragment>
                <Col className="d-flex align-items-center">
                  <Card.Text>Título: {socio.titulo}</Card.Text>
                </Col>
                <Col className="d-flex align-items-center" xs="auto">
                  <Card.Text>Sócio: {socio.nome}</Card.Text>
                </Col>
              </React.Fragment>
            ) : null}
          </Row>
          <br />
          <Row>
            <Col className="d-flex justify-content-center">
              <Button variant="success" onClick={() => navigateNext()} disabled={!socio.titulo}>
                Avançar
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
});
