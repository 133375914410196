import moment from 'moment';

/**
 * @method modelPerfil
 * @description Modelo padrão de objeto Perfil
 * @param {string} id - id do Perfil
 * @param {string} descricao - nome do Perfil
 * @param {number} superUsuario - indicador de Super Usuário (S/N).
 * @param {number} status - status do Perfil (A: Ativo; I: Inativo).
 * @param {number} statusLabel - label do status do Perfil. Derivado de cálculo.
 * @param {string} timestamp - data de criação do perfil.
 * @returns {object} Objeto de Churrasqueira formatado
 */
export default ({ id, descricao, superUsuario, status = 'A', timestamp }) => {
  const statusLabels = {
    A: 'ATIVO',
    I: 'INATIVO',
  };
  const statusLabel = statusLabels[status];
  return {
    id,
    descricao: descricao.toString(),
    superUsuario: superUsuario === 'S' ? true : false,
    status,
    statusLabel,
    timestamp: timestamp ? `${moment(timestamp).format('DD/MM/YYYY HH:mm:ss')}` : null,
  };
};
