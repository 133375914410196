import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-datetime/css/react-datetime.css';
import { Button, Col, Row, Badge, Table, Form, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Carregando } from '../../components';
import { Funci } from '../../services';
import modelReserva from '../../models/modelReserva';
import { alertar, withOrdenar } from '../../utils';

export default withRouter(props => {
  const maxDiasPesqSemSocio = 3; // Qtde máxima de dias para a pesquisa sem sócio
  const maxDiasPesqComSocio = 365; // Qtde máxima de dias para a pesquisa com sócio
  const { history, location } = props;
  const { dataInicial, dataFinal, titulo } = location.state || { dataInicial: '', dataFinal: '', titulo: '' };
  const [carregando, setCarregando] = useState(false);
  const { handleSubmit, register, errors } = useForm();
  const [check, setCheck] = useState({ reservada: true, nUtilizada: false, cancelada: true, efetivada: true });
  const [reservas, setReservas] = useState([]);
  // Estados de Pesquisa
  const [pTitulo, setPTitulo] = useState('');

  const onSubmitForm = values => {
    const { dataInicial: pDataInicial, dataFinal: pDataFinal } = values;
    history.push('/churrasqueiras/reservas', { dataInicial: pDataInicial, dataFinal: pDataFinal, titulo: pTitulo });
  };

  useEffect(() => {
    const inicializar = async () => {
      setReservas([]);
      setCarregando(true);
      try {
        if ((!dataInicial || !dataFinal) && !titulo) return null;
        if (!titulo && moment(dataFinal, 'YYYY-MM-DD').diff(moment(dataInicial, 'YYYY-MM-DD'), 'days') > maxDiasPesqSemSocio) {
          return alertar('danger', `Intervalo máximo de ${maxDiasPesqSemSocio} dias para pesquisa de reservas gerais.`);
        }
        if (titulo && moment(dataFinal, 'YYYY-MM-DD').diff(moment(dataInicial, 'YYYY-MM-DD'), 'days') > maxDiasPesqComSocio) {
          return alertar('danger', `Intervalo máximo de ${maxDiasPesqComSocio} dias para pesquisa de reservas de um sócio.`);
        }
        const fDataIni = moment(dataInicial, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const fDataFin = moment(dataFinal, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const consulta = await Funci.listarReservas(`${fDataIni}`, `${fDataFin}`, `${titulo || '0'}`); // Garantia que os termos serão passados por string

        if (consulta.erro) return alertar('danger', consulta.erro);
        if (consulta.length === 0) alertar('warning', 'Não foram encontradas reservas com os parametros informados.');
        if (consulta.length > 0)
          setReservas(
            withOrdenar(consulta.map(c => modelReserva(c)))
              .ordenar('status', 'D')
              .ordenar('dataInicial', 'D', 'DD/MM/YYYY'),
          );
      } catch (e) {
        console.log('e', e);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [dataInicial, dataFinal, titulo]);

  const filtrar = reserv => {
    if (reserv.length > 0) {
      return reserv.filter(
        c =>
          (c.status === 'R' && check.reservada) ||
          (c.status === 'C' && check.cancelada) ||
          (c.status === 'E' && check.efetivada) ||
          (c.status === 'N' && check.nUtilizada),
      );
    } else {
      return [];
    }
  };

  return (
    <React.Fragment>
      <Carregando show={carregando} />
      <br />
      <Row className="px-2">
        <Col xs="auto">
          <LinkContainer to="/novareserva/0">
            <Button variant="success" className="mt-2">
              Nova reserva
            </Button>
          </LinkContainer>
        </Col>
        <Col className="justify-content-end">
          <Form onSubmit={handleSubmit(onSubmitForm)} className="mt-2">
            <Row className="justify-content-end">
              <Form.Group as={Col} xs={2} controlId="paramDataInicial" className="align-middle mb-0">
                <Form.Control
                  name="dataInicial"
                  type="date"
                  defaultValue={dataInicial || moment().subtract(maxDiasPesqComSocio, 'days').format('YYYY-MM-DD')}
                  ref={register({
                    required: { value: true, message: 'Informe um data inicial para a consulta.' },
                    validate: value => moment(value).isValid() || 'Data inválida.',
                  })}
                />
                <Col as={Card.Text} className="text-danger">
                  {errors.dataInicial && errors.dataInicial.message}
                </Col>
              </Form.Group>
              <Form.Group as={Col} xs={2} controlId="paramDataFinal" className="align-middle mb-0">
                <Form.Control
                  name="dataFinal"
                  type="date"
                  defaultValue={dataFinal || moment().format('YYYY-MM-DD')}
                  ref={register({
                    required: { value: true, message: 'Informe um data final para a consulta.' },
                    validate: value => moment(value).isValid() || 'Data inválida.',
                  })}
                />
                <Col as={Card.Text} className="text-danger">
                  {errors.dataFinal && errors.dataFinal.message}
                </Col>
              </Form.Group>

              <Form.Group as={Col} xs={4} controlId="titulo" className="align-middle mb-0">
                <Form.Control type="text" placeholder="Título do associado" value={pTitulo} onChange={event => setPTitulo(event.target.value)} />
                <Form.Control.Feedback type="invalid">{'Título não informado'}</Form.Control.Feedback>
              </Form.Group>
              <Col xs="auto">
                <Button type="submit">Buscar</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <br />
      <Form inline="true" className="justify-content-center">
        <Form.Group controlId="status">
          <Form.Check
            inline="true"
            type="checkbox"
            id="R"
            label="Reservada"
            checked={check.reservada}
            onChange={event => setCheck({ ...check, reservada: event.target.checked })}
          />
          <Form.Check
            inline="true"
            type="checkbox"
            id="E"
            label="Efetivada/Utilizada"
            checked={check.efetivada}
            onChange={event => setCheck({ ...check, efetivada: event.target.checked })}
          />
          <Form.Check
            inline="true"
            type="checkbox"
            id="N"
            label="Não utilizada"
            checked={check.nUtilizada}
            onChange={event => setCheck({ ...check, nUtilizada: event.target.checked })}
          />
          <Form.Check
            inline="true"
            type="checkbox"
            id="C"
            label="Cancelada"
            checked={check.cancelada}
            onChange={event => setCheck({ ...check, cancelada: event.target.checked })}
          />
        </Form.Group>
      </Form>
      <br />
      <Table striped bordered hover responsive size="sm">
        <thead>
          <tr>
            <th>Data</th>
            <th>Churrasqueira</th>
            <th>Título</th>
            <th>Sócio</th>
            <th className="text-center">Período</th>
            <th className="text-center">Status</th>
            <th className="text-center">Detalhes</th>
          </tr>
        </thead>
        <tbody>
          {filtrar(reservas).map(c => (
            <tr key={`${c.idReserva}`}>
              <td>{c.dataInicial}</td>
              <td>{c.objeto.nome}</td>
              <td>{c.titulo}</td>
              <td>{c.nome}</td>
              <td className="text-center">{c.turnoLabel}</td>
              <td className={`${{ R: 'text-primary', E: 'text-success', N: 'text-warning', C: 'text-danger' }[c.status]} text-center`}>
                {c.statusLabel}
              </td>
              <td className="text-center">
                <LinkContainer to={`/detalhereserva/${c.idReserva}`}>
                  <Badge>Clique aqui</Badge>
                </LinkContainer>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
});
