import moment from 'moment';

/**
 * @method modelTurno
 * @description Modelo padrão de objeto Turno
 * @param {string} id - id do turno
 * @param {string} nome - nome do turno
 * @param {number} inicio - Horário de início do turno.
 * @param {number} fim - Horário de fim do turno.
 * @param {string} inicioLabel - Label para apresentação do inicio. Derivado de cálculo.
 * @param {string} fimLabel - Label para apresentação do fim. Derivado de cálculo.
 * @returns {object} Objeto de Churrasqueira formatado
 */
export default ({ id, nome, inicio, fim }) => {
  return {
    id,
    nome: nome.toString(),
    inicio: parseInt(inicio, 10),
    fim: parseInt(fim, 10),
    inicioLabel: moment().startOf('day').add(inicio, 'hours').format('HH:mm'),
    fimLabel: moment().startOf('day').add(fim, 'hours').format('HH:mm'),
  };
};
