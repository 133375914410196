import React, { useState, useEffect } from 'react';
import { Table, Badge, Row, Button, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { Funci } from '../../services';
import { Carregando, Alerta } from '../../components';
import { modelChurrasqueira } from '../../models';
import { withOrdenar } from '../../utils';

export default () => {
  const [carregando, setCarregando] = useState(true);
  const [alerta, setAlerta] = useState({ tipo: 'warning', mensagem: '', show: false });
  const [churrasqueiras, setChurrasqueiras] = useState([]);

  const alertar = (tipo = 'warning', mensagem = '') => {
    setAlerta({ tipo, mensagem, show: true });
  };

  const fecharAlerta = () => {
    setAlerta({ tipo: 'warning', mensagem: '', show: false });
  };

  useEffect(() => {
    const inicializar = async () => {
      try {
        const churras = await Funci.listarChurrasqueiras();

        if (churras.erro) alertar('danger', churras.erro);
        if (churras.length >= 1) setChurrasqueiras(withOrdenar(churras.map(c => modelChurrasqueira(c))).ordenar('nome', 'A'));
      } catch (e) {
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, []);

  return (
    <React.Fragment>
      <Carregando show={carregando} />
      {alerta.show ? (
        <Row className="justify-content-between">
          <Alerta tipo={alerta.tipo} mensagem={alerta.mensagem} show={alerta.show} fecharAlerta={fecharAlerta} />
        </Row>
      ) : null}
      <Row>
        <Col className="d-flex justify-content-center">
          <Button href="/impressao/reservasdia" target="_BLANK">
            Gerar relatório do dia
          </Button>
        </Col>
      </Row>
      <br />
      <Row className="px-2">
        <Table striped bordered hover responsive size="sm">
          <thead>
            <tr>
              <th className="align-middle">Churrasqueira</th>
              <th className="align-middle">Tipo</th>
              <th className="align-middle text-center">Capacidade</th>
              <th className="align-middle text-center">Status</th>
              <th className="align-middle text-center">
                Disponível
                <br />
                no App?
              </th>
              <th className="align-middle text-center">Detalhes</th>
            </tr>
          </thead>
          <tbody>
            {churrasqueiras.map(c => (
              <tr key={`${c.idChurrasqueira}`}>
                <td>{c.nome}</td>
                <td className={{ N: 'text-secondary', E: 'text-success' }[c.categoria]}>{{ N: 'NORMAL', E: 'ESPECIAL' }[c.categoria]}</td>
                <td className="text-center">{c.capacidade}</td>
                <td className={`${{ A: 'text-primary', I: 'text-danger' }[c.status]} text-center`}>{{ A: 'ATIVA', I: 'INATIVA' }[c.status]}</td>
                <td className={`${c.habilitadoApp ? 'text-primary' : 'text-danger'} text-center`}>{c.habilitadoAppLabel}</td>
                <td className="text-center">
                  <LinkContainer to={`/detalhechurrasqueira/${c.idChurrasqueira}`}>
                    <Badge variant="secondary">Clique aqui</Badge>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </React.Fragment>
  );
};
