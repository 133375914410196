import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { Button, Col, Row, Table, Form, Container, Tabs, Tab, Card, Badge } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';

import { Carregando } from '../../components';
import { Funci } from '../../services';
import { alertar } from '../../utils';
import modelExameMedico from '../../models/modelExameMedico';

export default withRouter(() => {
  const [carregando, setCarregando] = useState(false);
  const [atualizar] = useState(0);
  const { handleSubmit, register, errors } = useForm();
  const [exames, setExames] = useState([]);
  const [records, setRecords] = useState('');

  const [pesquisa, setPesquisa] = useState({
    tipo: '',
    criterio: '',
    dataInicial: moment().format('DD/MM/YYYY'),
    dataFinal: moment().format('DD/MM/YYYY'),
  });
  const [pagina] = useState(1);
  const [qtdePagina] = useState(99999);
  const [campoOrdenacao] = useState('0');
  const [sentidoOrdenacao] = useState('0');

  const funci = new Funci();

  const statusColors = {
    ATIVO: 'text-primary',
    VENCIDO: 'text-danger',
    'NÃO VALIDADO': 'text-warning',
    INATIVO: 'text-warning',
    CANCELADO: 'text-danger',
  };

  // Itens de query da requisição
  // const criterio = query.get('criterio') || '0';
  // const pagina = parseInt(query.get('pagina'), 10) || 1;
  // const qtdePagina = parseInt(query.get('qtdePagina'), 10) || 99999;
  // const campoOrdenacao = query.get('campoOrdenacao') || '0';
  // const sentidoOrdenacao = query.get('sentidoOrdenacao') || '0';

  const onSubmitCriterio = values => {
    const { paramCriterio } = values;
    // Validar quaisquer campso necessários se houver

    // Comandar a navagação paginada
    setPesquisa({ ...pesquisa, tipo: 'criterio', criterio: paramCriterio });
  };

  const onSubmitData = values => {
    // Validar quaisquer campos necessários se houver
    const { dataInicial, dataFinal } = values;
    const dIni = moment(dataInicial, 'YYYY-MM-DD');
    const dFim = moment(dataFinal, 'YYYY-MM-DD');
    if (dIni > dFim) {
      alertar('danger', 'Data final deve ser igual ou superior à data inicial.');
      return null;
    }

    // Comandar a navagação paginada
    setPesquisa({ ...pesquisa, tipo: 'data', criterio: '', dataInicial, dataFinal });
  };

  // const navegar = (crit, pag, qtde, campo, sentido) => {
  //   setCarregando(true);
  //   try {
  //     let url = '/exames';
  //     url = `${url}?criterio=${crit.toUpperCase() || '0'}`;
  //     url = `${url}&pagina=${pag}`;
  //     url = `${url}&qtdePagina=${qtde}`;
  //     url = `${url}&campoOrdenacao=${campo}`;
  //     url = `${url}&sentidoOrdenacao=${sentido}`;
  //     history.push(url);
  //   } catch (e) {
  //     alertar('error', e.msg || e);
  //   } finally {
  //     setCarregando(false);
  //   }
  // };

  // const ordenar = (campo, sentido) => {
  //   navegar(criterio, pagina, qtdePagina, campo, sentido);
  // };

  // const paginar = pag => {
  //   navegar(criterio, pag, qtdePagina, campoOrdenacao, sentidoOrdenacao);
  // };

  useEffect(() => {
    const inicializar = async () => {
      setCarregando(true);
      try {
        if (pesquisa.tipo) {
          let consulta;

          if (pesquisa.tipo === 'criterio') {
            consulta = await Funci.buscarExamesMedicos(pesquisa.criterio || '0', pagina, qtdePagina, campoOrdenacao || '0', sentidoOrdenacao || '0');
          }
          if (pesquisa.tipo === 'data') {
            consulta = await Funci.buscarExamesMedicosPorData(
              pesquisa.dataInicial,
              pesquisa.dataFinal,
              pagina,
              qtdePagina,
              campoOrdenacao || '0',
              sentidoOrdenacao || '0',
            );
          }

          if (consulta.erro) {
            setExames([]);
            alertar('danger', consulta.erro);
          } else {
            if (consulta.length === 0) {
              setExames([]);
              alertar('warning', 'Nenhum exame encontrado.');
            }
            if (consulta.length > 0) {
              setRecords(consulta[0].records);
              setExames(consulta.map(c => modelExameMedico(c)));
            }
          }
        }
      } catch (e) {
        setExames([]);
        alertar('danger', e);
      } finally {
        setCarregando(false);
      }
    };
    inicializar();
  }, [pesquisa, pagina, qtdePagina, campoOrdenacao, sentidoOrdenacao, atualizar]);

  return (
    <Container fluid>
      <Carregando show={carregando} />
      <br />
      <Tabs defaultActiveKey="exames" id="uncontrolled-tab-example">
        <Tab mountOnEnter unmountOnExit={true} variant="pills" eventKey="exames" title="Exames médicos">
          <Row className="px-2">
            {funci.hasPerfil(['ADMIN', 'POSTO-MEDICO']) && (
              <Col xs={3}>
                <LinkContainer to="/exames/novo">
                  <Button variant="success" className="mt-2">
                    Registrar Exame
                  </Button>
                </LinkContainer>
              </Col>
            )}
            {exames.length > 0 ? (
              <>
                <Col sm="auto" className="pb-0 mt-2 mx-2 d-flex justify-content-center align-items-center">
                  <Row className="justify-content-center">
                    <Card.Text className="pb-0 ml-1">{`Registros encontrados: ${records}`}</Card.Text>
                  </Row>
                </Col>
              </>
            ) : null}
            <Col className="justify-content-end">
              <Form onSubmit={handleSubmit(onSubmitCriterio)} className="mt-2">
                <Row className="justify-content-end">
                  <Col sm="auto" className="pb-0 mt-2 mx-2 d-flex justify-content-end align-items-end">
                    <Row className="justify-content-end align-items-center">
                      <Card.Text className={`pb-0 mr-2 ${pesquisa.tipo === 'criterio' && 'font-weight-bold'}`}>Pesquisa por critério:</Card.Text>
                    </Row>
                  </Col>
                  <Form.Group as={Col} xs={4} controlId="paramCriterio" className="align-middle mb-0">
                    <Form.Control
                      name="paramCriterio"
                      type="text"
                      maxLength={200}
                      placeholder="Digite um título, nome ou cpf"
                      ref={register({
                        minLength: { value: 3, message: 'A pesquisa deve conter no mínimo 3 caracteres.' },
                        maxLength: { value: 200, message: 'A pesquisa deve conter no máximo 200 caracteres.' },
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.paramCriterio && errors.paramCriterio.message}
                    </Col>
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit">Buscar</Button>
                  </Col>
                </Row>
              </Form>
              <Form onSubmit={handleSubmit(onSubmitData)} className="mt-2">
                <Row className="d-flex justify-content-end align-items-center">
                  <Col sm="auto" className="pb-0 mt-2 mx-2 d-flex justify-content-end align-items-end">
                    <Row className="justify-content-end align-items-center">
                      <Card.Text className={`pb-0 mr-2 ${pesquisa.tipo === 'data' && 'font-weight-bold'}`}>
                        Pesquisa por intervalo de data: <br />
                        <small className="text-muted">(emissão, validação e revalidação)</small>
                      </Card.Text>
                    </Row>
                  </Col>
                  <Form.Group as={Col} xs={2} controlId="paramDataInicial" className="align-middle mb-0">
                    <Form.Control
                      name="dataInicial"
                      type="date"
                      defaultValue={moment().format('YYYY-MM-DD')}
                      ref={register({
                        required: { value: true, message: 'Informe um data inicial para a consulta.' },
                        validate: value => moment(value).isValid() || 'Data inválida.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.dataInicial && errors.dataInicial.message}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Col} xs={2} controlId="paramDataFinal" className="align-middle mb-0">
                    <Form.Control
                      name="dataFinal"
                      type="date"
                      defaultValue={moment().format('YYYY-MM-DD')}
                      ref={register({
                        required: { value: true, message: 'Informe um data final para a consulta.' },
                        validate: value => moment(value).isValid() || 'Data inválida.',
                      })}
                    />
                    <Col as={Card.Text} className="text-danger">
                      {errors.dataFinal && errors.dataFinal.message}
                    </Col>
                  </Form.Group>
                  <Col xs="auto">
                    <Button type="submit">Buscar</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <Table striped bordered hover responsive size="sm">
            <thead>
              <tr>
                <th className="text-center align-middle">#</th>
                <th className="align-middle">Tipo</th>
                <th className="text-center align-middle">
                  Identificação
                  <br />
                  (Título/Dependente/CPF)
                </th>
                <th className="align-middle">Nome</th>
                <th className="align-middle text-center">Nr. Revalidações</th>
                <th className="align-middle text-center">Validade</th>
                <th className="align-middle text-center">Status</th>
                <th className="text-center align-middle">
                  Responsável
                  <br />
                  pelo registro
                </th>
                <th className="align-middle text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              {exames.length > 0
                ? exames.map((e, index) => (
                    <tr className="" key={`${e.id}`}>
                      <td className="align-middle text-center">{`${index}`}</td>
                      <td className="align-middle">{`${e.tipoPessoa}`}</td>
                      <td className="align-middle text-center">{e.identificacao}</td>
                      <td className="align-middle">{e.nomeRdzd || e.nome}</td>
                      <td className="align-middle text-center">{e.nrRevalidacoes}</td>
                      <td className="align-middle text-center">{e.dataValidade}</td>
                      <td className={`${statusColors[e.statusExameLabel]} align-middle text-center`}>{e.statusExameLabel}</td>
                      <td className="align-middle text-center">{e.nomeResponsavel}</td>
                      <td className="align-middle text-center">
                        <LinkContainer to={`/detalheexame/${e.id}`}>
                          <Badge variant="secondary">Detalhes</Badge>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Container>
  );
});
