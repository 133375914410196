import React, { useState, useEffect } from 'react';

function ViaCep(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getCep = () => {
    if (props.cep) {
      console.log('props.cep', props.cep);
      setLoading(true);
      fetch(`https://viacep.com.br/ws/${props.cep}/json/`)
        .then(response => response.json())
        .then(data => {
          setData(data);
          setError(false);
          if (props.onSuccess) props.onSuccess(data);
        })
        .catch(err => {
          setError(true);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (props.lazy) return;
    getCep();
  }, [props.lazy]);

  return (
    props.children({
      loading,
      data,
      error,
      fetch: getCep,
    }) || null
  );
}

export default ViaCep;
