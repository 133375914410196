import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const Confirmacao = props => {
  const { show = false, mensagem = '', onConfirm = () => {}, payloadOnConfirm = [], onCancel = () => {}, payloadOnCancel = [] } = props;

  if (show) {
    return (
      <Modal centered animation={false} backdrop="static" show={show} keyboard={false} className="bg-invisible">
        <Modal.Header closeButton onHide={onCancel}>
          <Modal.Title>Confirmação</Modal.Title>
        </Modal.Header>
        <Modal.Body>{`${mensagem}`}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => onCancel(...payloadOnCancel)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={() => onConfirm(...payloadOnConfirm)}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return null;
};

export default Confirmacao;
