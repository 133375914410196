/**
 * fomartar CPF
 *
 * @param {string} cpf
 * @param {boolean} zeroaEsquerda - indicador para evitar que o CPF tenha os "zeros" iniciais truncados
 * @returns
 */
const formatarCPF = (cpf, zeroaEsquerda) => {
  // retira os caracteres indesejados...
  const cpfString = `${cpf}`.replace(/[^\d]/g, '').substring(0, 11);
  const cpfLimpo = zeroaEsquerda ? ('00000000000' + cpfString).slice(-11) : cpfString;

  // realizar a formatação...

  // Primeiro bloco não há formatação
  if (cpfLimpo.length <= 3) return cpfLimpo;

  // Segundo bloco
  if (cpfLimpo.length === 4) return cpfLimpo.replace(/(\d{3})(\d{1})/, '$1.$2');
  if (cpfLimpo.length === 5) return cpfLimpo.replace(/(\d{3})(\d{2})/, '$1.$2');
  if (cpfLimpo.length === 6) return cpfLimpo.replace(/(\d{3})(\d{3})/, '$1.$2');

  // Terceiro bloco
  if (cpfLimpo.length === 7) return cpfLimpo.replace(/(\d{3})(\d{3})(\d{1})/, '$1.$2.$3');
  if (cpfLimpo.length === 8) return cpfLimpo.replace(/(\d{3})(\d{3})(\d{2})/, '$1.$2.$3');
  if (cpfLimpo.length === 9) return cpfLimpo.replace(/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3');

  // Quarto bloco
  if (cpfLimpo.length === 10) return cpfLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
  return cpfLimpo.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export default formatarCPF;
