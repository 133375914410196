/**
 * parseValor
 *
 * @param {string} valor
 * @returns
 */
const parseValor = valor => {
  const filterFloat = value => {
    if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) return Number(value);
    return NaN;
  };
  if (!valor) return 0;
  return filterFloat(`${valor}`.replaceAll('.', '').replace(',', '.'));
};

export default parseValor;
